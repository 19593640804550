import { TFunction } from '@uniqkey-frontend/shared-app';

interface IGenerateTooltipTitleParams {
  selectedDataLength: number;
  t: TFunction;
  isDisabled: boolean;
  key: string;
  isTrustedPortalDisabled?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const generateTooltipTitle = (params: IGenerateTooltipTitleParams) => {
  const {
    selectedDataLength, t, isDisabled, key, isTrustedPortalDisabled,
  } = params;
  if (!selectedDataLength) return t('common.tickRow');
  if (isTrustedPortalDisabled) return t(`${key}.disabledTrustedPortal`);
  if (isDisabled) return t(`${key}.disabled`);
  return t(`${key}.active`);
};
