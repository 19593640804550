import { createContext, useContext } from 'react';
import { type IKeyPair } from '@uniqkey-frontend/shared-app';
import { type ICompanionApplicationData } from '../../services/companionApplicationService';

export interface ICompanionApplicationContext {
  guid: string;
  symmetricKey: string;
  asymmetricKeys: IKeyPair;
  saveCompanionApplicationData: (
    updatedSymmetricKey?: ICompanionApplicationData['symmetricKey']
  ) => void;
  deleteCompanionApplicationData: () => void;
}

export const CompanionApplicationDefaultContext: ICompanionApplicationContext = {
  guid: '',
  symmetricKey: '',
  asymmetricKeys: {
    publicKey: '',
    privateKey: '',
  },
  saveCompanionApplicationData: () => {
  },
  deleteCompanionApplicationData: () => {
  },
};

const CompanionApplicationContext = createContext<
  ICompanionApplicationContext
>(CompanionApplicationDefaultContext);

CompanionApplicationContext.displayName = 'CompanionApplicationContext';

export default CompanionApplicationContext;

export const useCompanionApplicationContext = (): ICompanionApplicationContext => useContext<
  ICompanionApplicationContext
>(CompanionApplicationContext);
