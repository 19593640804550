import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTranslations,
  Grid,
  Button,
  DialogActions,
  Typography,
  Box,
  ReactHookFormTextField,
  emptyStringValidator,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { GetIpGroupRestrictionByIdResponse } from '@uniqkey-backend-organization-web/api-client';
import { useUser } from '../../../../contexts/UserContext';

interface IIpAddressRestrictionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (params: ICreateIpAddressRestrictionModalReturnValue) => Promise<void>;
  onEdit: (params: IEditIpAddressRestrictionModalReturnValue) => Promise<void>;
  isLoading: boolean;
  restrictionToUpdate: GetIpGroupRestrictionByIdResponse | null;
}

interface IFormValue {
  ipAddress: string;
  description: string;
}

export interface ICreateIpAddressRestrictionModalReturnValue extends IFormValue {}

export interface IEditIpAddressRestrictionModalReturnValue extends IFormValue {
  groupRestrictionId: string;
}

const IpAddressRestrictionModal = (props: IIpAddressRestrictionModalProps) => {
  const {
    isOpen, onClose, onCreate, onEdit, isLoading, restrictionToUpdate,
  } = props;
  const { t } = useTranslations();
  const { currentUser } = useUser();

  const isEditMode = !!restrictionToUpdate;

  const defaultValues = useMemo(() => (isEditMode
    ? { ipAddress: restrictionToUpdate?.ipAddress, description: restrictionToUpdate?.description }
    : { ipAddress: '', description: '' }), [isEditMode, restrictionToUpdate]);

  const {
    handleSubmit, formState: { errors, isDirty }, setValue, control, setFocus,
  } = useForm<IFormValue>({ defaultValues, mode: 'all' });

  const currentIpAddress = currentUser?.currentIpAddress ?? '';
  const handleIpAddressChange = useCallback(() => {
    setValue('ipAddress', currentIpAddress, { shouldDirty: true });
    setFocus('ipAddress');
  }, [setValue, setFocus, currentIpAddress]);

  const handleCreateOrEdit = useCallback((value: IFormValue) => {
    if (isEditMode) {
      const { groupRestrictionId } = restrictionToUpdate;
      return onEdit({ ...value, groupRestrictionId });
    }
    return onCreate(value);
  }, [isEditMode, restrictionToUpdate, onEdit, onCreate]);

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleCreateOrEdit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {isEditMode
            ? t('ipAddressRestrictionsModal.title.edit')
            : t('ipAddressRestrictionsModal.title.create')}
        </DialogTitle>
        <DialogContent>
          <ReactHookFormTextField
            autoFocus
            name="ipAddress"
            control={control}
            rules={{
              required: t('validation.required'),
              validate: (value) => (
                emptyStringValidator(value)
                  ? buildInvalidFieldTranslation('ipAddressRestrictionsModal.ipAddress.label')
                  : true
              ),
            }}
            fullWidth
            error={!!errors.ipAddress}
            helperText={errors.ipAddress?.message}
            label={`${t('ipAddressRestrictionsModal.ipAddress.label')}*`}
            placeholder={t('ipAddressRestrictionsModal.ipAddress.placeholder')}
          />
          <Box mt={1.5} />
          <Typography variant="subtitle1" onClick={handleIpAddressChange} asLink>
            {t('ipAddressRestrictionsModal.clickHere', { currentIpAddress })}
          </Typography>
          <Box mt={6} />
          <ReactHookFormTextField
            name="description"
            control={control}
            rules={{
              required: t('validation.required'),
              validate: (value) => (
                emptyStringValidator(value)
                  ? buildInvalidFieldTranslation('ipAddressRestrictionsModal.description.label')
                  : true
              ),
            }}
            fullWidth
            multiline
            error={!!errors.description}
            helperText={errors.description?.message}
            label={`${t('ipAddressRestrictionsModal.description.label')}*`}
            placeholder={t('ipAddressRestrictionsModal.description.placeholder')}
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {isEditMode ? t('common.save') : t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(IpAddressRestrictionModal);
