import PubSub from 'pubsub-js';
import { type AxiosInstance } from 'axios';
import { type ITrustedPortalActionPayload } from '../interfaces';
import TrustedPortalPubSubEventEnum from '../enums/PubSubEventEnum';
import { buildPubSubTopic } from '../../../helpers/PubSub';
import { logException } from '../../../services/sentryService';
import API from '../api';
import Helpers from '../helpers';
import EventHandlers from '../eventHandlers';

const LOG_COLOR = 'red';

interface ITrustedPortalEventExecutor {
  isExecuting: boolean;
  destroy(): void;
}

// eslint-disable-next-line import/prefer-default-export
export class TrustedPortalEventExecutor implements ITrustedPortalEventExecutor {
  private readonly unsubscribeEventProvided: string;

  private isDestroyed = false;

  isExecuting = false;

  constructor(
    private readonly organizationId: string,
    private readonly axiosInstance: AxiosInstance,
    private readonly organizationPrivateKey: ITrustedPortalActionPayload['organizationPrivateKey'],
    private readonly organizationPublicKey: ITrustedPortalActionPayload['organizationPublicKey'],
  ) {
    this.unsubscribeEventProvided = PubSub.subscribe(
      this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_EVENT_PROVIDED),
      async (_, data) => {
        Helpers.tpLogMessage({
          message: '[EXECUTOR] receive TRUSTED_PORTAL_EVENT_PROVIDED',
          color: LOG_COLOR,
        });
        const { event } = data;
        if (this.isExecuting) {
          // eslint-disable-next-line no-console
          console.warn('TrustedPortalModule/EventExecutor/EVENT_PROVIDED while isExecuting=true');
          return;
        }
        try {
          Helpers.tpLogMessage({
            messages: [
              '[EXECUTOR] start execution',
              `\n\tevent name : ${event.queueEvent}`,
              `\n\tevent id   : ${event.queueMessageId}`,
            ],
            color: LOG_COLOR,
          });
          this.isExecuting = true;

          await EventHandlers.handleEvent(
            event,
            this.axiosInstance,
            this.organizationPrivateKey,
            this.organizationPublicKey,
          );
        } catch (e: any) {
          logException(e, {
            message: 'TrustedPortalModule/EventExecutor/TRUSTED_PORTAL_EVENT_PROVIDED exception',
            data: {
              queueEvent: event.queueEvent,
            },
          });
          Helpers.tpLogMessage({
            messages: [
              '[EXECUTOR] execution error',
              `\n\tevent name : ${event.queueEvent}`,
              `\n\tevent id   : ${event.queueMessageId}`,
              `\n\n${e}`,
            ],
            color: LOG_COLOR,
          });
          await API.sendQueueCommonFail(
            this.axiosInstance,
            {
              queueMessageId: event.queueMessageId,
              failReason: e.message ?? 'No error message',
            },
          ).catch((commonFailError) => {
            logException(commonFailError, {
              message: 'TrustedPortalModule/EventExecutor/sendQueueCommonFail exception',
              data: {
                queueEvent: event.queueEvent,
              },
            });
            Helpers.tpLogMessage({
              messages: [
                '[EXECUTOR] common fail error',
                `\n\tevent name : ${event.queueEvent}`,
                `\n\tevent id   : ${event.queueMessageId}`,
                `\n\n${commonFailError}`,
              ],
              color: LOG_COLOR,
            });
          });
        } finally {
          this.isExecuting = false;
          Helpers.tpLogMessage({
            messages: [
              '[EXECUTOR] end execution',
              `\n\tevent name : ${event.queueEvent}`,
              `\n\tevent id   : ${event.queueMessageId}`,
            ],
            color: LOG_COLOR,
          });
        }
        if (this.isDestroyed) {
          Helpers.tpLogMessage({
            message: `[EXECUTOR] isDestroyed: ${this.isDestroyed}`,
            color: LOG_COLOR,
          });
          return;
        }
        Helpers.tpLogMessage({
          message: '[EXECUTOR] publish TRUSTED_PORTAL_PROVIDE_EVENT',
          color: LOG_COLOR,
        });
        PubSub.publish(
          this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT),
        );
      },
    );
  }

  private getPubSubTopic(topic: string): string {
    return buildPubSubTopic(topic, this.organizationId);
  }

  destroy(): void {
    PubSub.unsubscribe(this.unsubscribeEventProvided);
    this.isDestroyed = true;
  }
}
