import { memo, useCallback } from 'react';
import { usePubSub } from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  useGetEmployeeAverageSecurityScore,
} from '../../../../hooks/reactQuery';
import SecurityScoreWidget from '../../../../components/SecurityScoreWidget';

interface IEmployeeDetailsSecurityScoreWidgetProps {
  employeeAccountId: string;
}

const EmployeeDetailsSecurityScoreWidget = (props: IEmployeeDetailsSecurityScoreWidgetProps) => {
  const { employeeAccountId } = props;
  const {
    data, isLoading, isError, refetch,
  } = useGetEmployeeAverageSecurityScore({ employeeAccountId });
  const { averageSecurityScore } = data ?? {};

  const handleRefetch = useCallback(() => refetch(), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_EMPLOYEE, handleRefetch);

  if (isError) {
    return null;
  }

  return <SecurityScoreWidget isLoading={isLoading} percentage={averageSecurityScore} />;
};

export default memo(EmployeeDetailsSecurityScoreWidget);
