import { memo, useCallback } from 'react';
import {
  ActionButton,
  AR2,
  Box,
  DeleteIcon,
  Divider,
  Grid,
  IconButton,
  S5,
  SearchableTextField,
  Tooltip,
  Typography,
  useTable,
  useTranslations,
  WarningIcon,
  Panel,
  CloseOutlinedIcon,
  ISearchableTextFieldProps,
  useMapKeyValueExtractor,
} from '@uniqkey-frontend/shared-app';
import EmployeesToInviteTable from '../../../../../../components/tables/EmployeesToInviteTable';
import { IInviteEmployee } from '../../interfaces';

interface IInviteEmployeesTableProps {
  onSearchChange: ISearchableTextFieldProps['onChange'];
  users: IInviteEmployee[];
  onUserDelete: (usersIds: string[]) => void;
  fileNames: string[];
  invalidEmailsCount: number;
  onFileDelete: (fileName: string) => void;
}

const InviteEmployeesTable = (props: IInviteEmployeesTableProps) => {
  const {
    onSearchChange, users, onUserDelete, fileNames, invalidEmailsCount, onFileDelete,
  } = props;

  const { t } = useTranslations();
  const {
    selectedRows: selectedEmployees,
    onRowSelectionChange,
    createColumns,
  } = useTable<IInviteEmployee>();
  const { keys: employeeAccountsIds } = useMapKeyValueExtractor<IInviteEmployee>(selectedEmployees);
  const handleDeleteUser = useCallback(() => {
    onUserDelete(employeeAccountsIds);
    onRowSelectionChange(new Map());
  }, [employeeAccountsIds, onUserDelete, onRowSelectionChange]);
  const handleDeleteFile = useCallback((fileName: string) => {
    onFileDelete(fileName);
    onRowSelectionChange(new Map());
  }, [onFileDelete, onRowSelectionChange]);

  return (
    <>
      {fileNames.map((fileName) => (
        <Grid key={fileName} container alignItems="center" justifyContent="space-between" pb={1}>
          <Grid item>
            <Typography variant="subtitle1" color={S5}>{fileName}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => handleDeleteFile(fileName)}
            >
              <CloseOutlinedIcon color={AR2} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Divider />
      <Box mt={2} />
      <Typography variant="subtitle1" color={S5}>{t('inviteEmployeesModal.usersToAdd')}</Typography>
      <Box mt={2} />
      <Panel>
        <Grid container justifyContent="space-between" alignItems="center" p={1}>
          <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
            {!!selectedEmployees.size && (
              <>
                <Grid item alignSelf="center">
                  <Tooltip title={t('common.removeFromTable')}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleDeleteUser}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
            <Grid item>
              <SearchableTextField
                autoFocus={false}
                onChange={onSearchChange}
                placeholder={t('common.search')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <EmployeesToInviteTable
          selectedUsers={selectedEmployees}
          users={users}
          createColumns={createColumns}
          onRowSelectionChange={onRowSelectionChange}
        />
      </Panel>
      {!!invalidEmailsCount && (
        <Grid container alignItems="center" spacing={1} pt={1}>
          <Grid item display="inline-flex">
            <WarningIcon width={20} height={20} color={AR2} />
          </Grid>
          <Grid item>
            <Typography variant="caption" color={AR2}>
              {t('inviteEmployeesModal.rowsWithInvalidEmail', { count: invalidEmailsCount })}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(InviteEmployeesTable);
