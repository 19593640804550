import { useCallback } from 'react';
import {
  Grid,
  TypographyWithHelp,
  WidgetContainer,
  S6,
  useTranslations,
  useSnackbar,
  Typography,
  S4,
  List,
} from '@uniqkey-frontend/shared-app';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  ApplicationOrderPropertyName,
} from '@uniqkey-backend-organization-web/api-client';
import { lowerFirst } from 'lodash';
import { useGetServices } from '../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import DashboardMostUnsecuredServicesWidgetListItem from
  './components/DashboardMostUnsecuredServicesWidgetListItem';
import DashboardServicesNoDataWidget from '../DashboardServicesNoDataWidget';

const DashboardMostUnsecuredServicesWidget = () => {
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const navigate = useNavigate();

  const { data, isLoading } = useGetServices({
    page: 1,
    pageLength: 5,
    minStrength: 0,
    maxStrength: 80,
    isDescending: false,
    orderPropertyName: ApplicationOrderPropertyName.SecurityScore,
  }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const handleNavigation = useCallback(() => {
    const search = createSearchParams({
      columnOrderBy: lowerFirst(ApplicationOrderPropertyName.SecurityScore),
      columnOrderDirection: 'asc',
    }).toString();
    navigate({
      pathname: PageRouteEnum.Services,
      search,
    });
  }, [navigate]);

  const { data: services } = data ?? {};

  if (isLoading) {
    return (
      <WidgetContainer container withShadow isLoading={isLoading} minHeight={270} />
    );
  }

  if (!services?.length) {
    return (
      <DashboardServicesNoDataWidget
        tooltipTranslationKey="dashboardMostUnsecuredServicesWidget.tooltip"
        titleTranslationKey="dashboardMostUnsecuredServicesWidget.title"
        noDataTranslationKey="dashboardMostUnsecuredServicesWidget.noData"
      />
    );
  }

  return (
    <WidgetContainer
      container
      withShadow
      p={0}
    >
      <Grid container py={2} rowGap={1}>
        <Grid item xs={12} px={3}>
          <Grid item>
            <TypographyWithHelp
              helperText={t('dashboardMostUnsecuredServicesWidget.tooltip')}
              color={S6}
            >
              {t('dashboardMostUnsecuredServicesWidget.title')}
            </TypographyWithHelp>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="caption" color={S4}>
                {t('dashboardMostUnsecuredServicesWidget.services')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color={S4}>
                {t('dashboardMostUnsecuredServicesWidget.secureScore')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            {services.map((service, index) => (
              <DashboardMostUnsecuredServicesWidgetListItem
                service={service}
                index={index}
                key={service.applicationId}
              />
            ))}
          </List>
        </Grid>
        <Grid item my={2} container justifyContent="center">
          <Typography asLink onClick={handleNavigation}>
            {t('dashboardMostUnsecuredServicesWidget.button')}
          </Typography>
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default DashboardMostUnsecuredServicesWidget;
