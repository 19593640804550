import { ServiceModuleTabEnum } from '../../pages/ServicePage/components/ServiceModule';

enum PageRouteEnum {
  Login = '/login',
  Dashboard = '/dashboard',
  Onboarding = '/onboarding',
  Employees = '/employees',
  Employee = '/employees/:employeeAccountId/*',
  Groups = '/groups',
  Group = '/groups/:groupId/*',
  Reports = '/reports',
  Emails = '/emails',
  Services = '/services',
  Service = '/services/:applicationId/*',
  ServiceLoginsTab = `/services/:applicationId/${ServiceModuleTabEnum.Logins}`,
  SecureData = '/secure-data',
  Settings = '/settings',
  Profile = '/profile',
  AllSecuredData = '/all-vaults',
  OrganizationLogin = '/all-vaults/logins/:vaultId/*',
  Audit = '/audit',
}

export default PageRouteEnum;
