import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTranslations,
  Grid,
  Button,
  DialogActions,
  Box,
  ReactHookFormAutocomplete,
  ReactHookFormTextField,
  useSnackbar,
  TFunction,
  IAutocompleteProps,
  TTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetGeolocationGroupRestrictionByIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import useGoogleMapsAPI, {
  TGoogleMapsCoordinates,
  IGoogleMapsPrediction,
} from '../../../../hooks/useGoogleMapsAPI';
import GoogleMapsMap from '../../../../components/GoogleMaps/GoogleMapsMap';
import GoogleMapsCircle from '../../../../components/GoogleMaps/GoogleMapsCircle';
import GoogleMapsMarker from '../../../../components/GoogleMaps/GoogleMapsMarker';
import { logException } from '../../../../services/sentryService';

interface ILocationRestrictionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (params: ICreateLocationRestrictionModalReturnValue) => Promise<void>;
  onEdit: (params: IEditLocationRestrictionModalReturnValue) => Promise<void>;
  isLoading: boolean;
  restrictionToUpdate: GetGeolocationGroupRestrictionByIdResponse | null;
}

interface IFormPredictionValue {
  label: string;
  value: string;
}

interface IFormValue {
  prediction: IFormPredictionValue | null;
  coords: TGoogleMapsCoordinates;
  radius: number;
}

interface IReturnValue {
  locationAddress: string;
  locationId: string;
  latitude: number;
  longitude: number;
  radius: number;
}

export interface ICreateLocationRestrictionModalReturnValue extends IReturnValue {}

export interface IEditLocationRestrictionModalReturnValue extends IReturnValue {
  groupRestrictionId: string;
}

const getErrorMessage = (t: TFunction, error: any): string => {
  let message = t('common.somethingWentWrong');
  if (error.code) {
    message += `: ${error.code}`;
  }
  return message;
};

const LocationRestrictionModal = (props: ILocationRestrictionModalProps) => {
  const {
    isOpen, onClose, onCreate, onEdit, isLoading, restrictionToUpdate,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const { showError } = useSnackbar();
  const { getPlacePredictions, getCoordinates } = useGoogleMapsAPI();

  const isEditMode = !!restrictionToUpdate;

  const defaultValues = useMemo(() => {
    if (!isEditMode) {
      return { prediction: null, coords: null, radius: 100 };
    }
    const {
      locationAddress, locationId, latitude, longitude, radius,
    } = restrictionToUpdate;
    return {
      prediction: { label: locationAddress, value: locationId },
      coords: { lat: latitude, lng: longitude },
      radius,
    };
  }, [isEditMode, restrictionToUpdate]);

  const {
    handleSubmit, formState: { errors }, control, setValue, watch,
  } = useForm<IFormValue>({ defaultValues, mode: 'all' });

  const handlePredictionChange = useCallback<
    NonNullable<IAutocompleteProps['onChange']>
  >(async (event, prediction: IFormPredictionValue | null) => {
    try {
      if (!prediction) {
        setValue('prediction', null);
        setValue('coords', null);
        return;
      }
      const { value: placeId } = prediction;
      const newCoords = await getCoordinates({ placeId });
      setValue('prediction', prediction);
      setValue('coords', newCoords);
    } catch (e) {
      setValue('prediction', null);
      setValue('coords', null);
      showError({
        text: getErrorMessage(t, e),
      });
      logException(e, {
        message: 'LocationRestrictionModal/handlePredictionChange exception',
      });
    }
  }, [setValue, getCoordinates, showError, t]);

  const handleRequest = useCallback(async (searchQuery: string) => {
    try {
      return await getPlacePredictions({ input: searchQuery, language: currentLanguage });
    } catch (e) {
      showError({
        text: getErrorMessage(t, e),
      });
      logException(e, {
        message: 'LocationRestrictionModal/handleRequest exception',
      });
      return [];
    }
  }, [getPlacePredictions, currentLanguage, showError, t]);

  const handleResponseParser = useCallback(
    (predictionsToParse: IGoogleMapsPrediction[]) => predictionsToParse.map(
      (prediction: IGoogleMapsPrediction) => ({
        value: prediction.placeId,
        label: prediction.address,
      }),
    ),
    [],
  );

  const handleRadiusChange = useCallback<
    NonNullable<TTextFieldProps['onChange']>
  >((event) => {
    const parsedValue = parseInt(event.target.value, 10);
    if (Number.isNaN(parsedValue) || parsedValue < 1) {
      setValue('radius', 1, { shouldDirty: true });
      return;
    }
    setValue('radius', parsedValue, { shouldDirty: true });
  }, [setValue]);

  const handleCreateOrEdit = useCallback((value: IFormValue) => {
    const { prediction, coords, radius } = value;
    const { label: locationAddress, value: locationId } = prediction!;
    const { lat: latitude, lng: longitude } = coords!;
    const processedValue = {
      locationAddress,
      locationId,
      latitude,
      longitude,
      radius,
    };
    if (isEditMode) {
      const { groupRestrictionId } = restrictionToUpdate;
      return onEdit({ ...processedValue, groupRestrictionId });
    }
    return onCreate(processedValue);
  }, [isEditMode, restrictionToUpdate, onEdit, onCreate]);

  const [currentCoords, currentRadius] = watch(['coords', 'radius']);

  const { lat: currLat, lng: currLng } = currentCoords ?? {};
  const memoizedCoords = useMemo<TGoogleMapsCoordinates>(() => {
    if (!currLat || !currLng) {
      return null;
    }
    return { lat: currLat, lng: currLng };
  }, [currLat, currLng]);

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleCreateOrEdit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {isEditMode
            ? t('locationRestrictionsModal.title.edit')
            : t('locationRestrictionsModal.title.create')}
        </DialogTitle>
        <DialogContent>
          <ReactHookFormAutocomplete
            autoFocus
            t={t}
            name="prediction"
            label={`${t('locationRestrictionsModal.location.label')}*`}
            placeholder={t('locationRestrictionsModal.location.placeholder')}
            control={control}
            dataSourceRequest={handleRequest}
            dataSourceResponseParser={handleResponseParser}
            onChange={handlePredictionChange}
            rules={{ required: t('validation.required') }}
            debounceDelay={500}
          />
          <Box mt={6} />
          <ReactHookFormTextField
            name="radius"
            onChange={handleRadiusChange}
            type="number"
            control={control}
            inputProps={{ min: 1 }}
            error={!!errors.radius}
            helperText={errors.radius?.message}
            label={t('locationRestrictionsModal.radius.label')}
            placeholder={t('locationRestrictionsModal.radius.placeholder')}
          />
          {memoizedCoords && (
            <Box mt={4}>
              <GoogleMapsMap center={memoizedCoords} height={340}>
                <GoogleMapsCircle center={memoizedCoords} radius={currentRadius} />
                <GoogleMapsMarker position={memoizedCoords} />
              </GoogleMapsMap>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!memoizedCoords} type="submit">
                {isEditMode ? t('common.save') : t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(LocationRestrictionModal);
