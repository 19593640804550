import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreateQueueMessageResponse,
  QueueEvent,
  NoContentResult,
  GetUnprocessedInfoResponse,
  RedirectEventsToTrustedPortalRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { getCompanionApplicationId } from '../../services/companionApplicationService';
import { getActiveOrganizationId } from '../../services/organizationService';
import { getEmployeesTokens } from '../../services/authService';
import TrustedPortalModuleConstants from '../../modules/TrustedPortalModule/constants';

const useQueueAPI = () => {
  const { queueAPIClient } = useApiClients();

  const activateTrustedPortal = useCallback((
    options?: AxiosRequestConfig,
  ): Promise<CreateQueueMessageResponse> => {
    const companionApplicationId = getCompanionApplicationId();
    const organizationId = getActiveOrganizationId();
    if (!organizationId) {
      throw new Error('No organization id found');
    }
    const employeesTokens = getEmployeesTokens();
    if (!employeesTokens) {
      throw new Error('No employees tokens found');
    }
    const { employeeAccountId } = employeesTokens[organizationId];
    return queueAPIClient
      .apiV1QueueCreatePost({
        eventType: QueueEvent.TrustedPortalRequested,
        payload: JSON.stringify({
          companionApplicationId, organizationId, sessionId: employeeAccountId,
        }),
      }, options)
      .then(dataExtractor<CreateQueueMessageResponse>());
  }, [queueAPIClient]);

  const cancelQueueMessage = useCallback((
    queueMessageId: string,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => queueAPIClient
    .apiV1QueueDeleteQueueMessageQueueMessageIdDelete(queueMessageId, options)
    .then(dataExtractor<NoContentResult>()), [queueAPIClient]);

  const getUnprocessedInfo = useCallback((options?: AxiosRequestConfig)
    : Promise<GetUnprocessedInfoResponse> => {
    const organizationId = getActiveOrganizationId();
    if (!organizationId) {
      throw new Error('No organization id found');
    }
    const employeesTokens = getEmployeesTokens();
    if (!employeesTokens) {
      throw new Error('No employees tokens found');
    }
    const { employeeAccountId } = employeesTokens[organizationId];
    return queueAPIClient
      .apiV1QueueGetUnprocessedInfoSessionIdGet(employeeAccountId, options)
      .then(dataExtractor<GetUnprocessedInfoResponse>());
  }, [queueAPIClient]);

  const redirectEventsToTrustedPortal = useCallback((
    redirectEventsToTrustedPortalRequest: RedirectEventsToTrustedPortalRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => queueAPIClient
    .apiV1QueueRedirectEventsToTrustedPortalPost(redirectEventsToTrustedPortalRequest, options)
    .then(dataExtractor<NoContentResult>()), [queueAPIClient]);

  const restartTrustedPortalEvents = useCallback(
    (options?: AxiosRequestConfig): Promise<NoContentResult> => {
      const organizationId = getActiveOrganizationId();
      if (!organizationId) {
        throw new Error('No organization id found');
      }
      const employeesTokens = getEmployeesTokens();
      if (!employeesTokens) {
        throw new Error('No employees tokens found');
      }
      const { employeeAccountId } = employeesTokens[organizationId];
      const processedOptions = {
        ...options,
        headers: {
          ...options?.headers,
          [TrustedPortalModuleConstants.TRUSTED_PORTAL_SESSION_HEADER]: employeeAccountId,
        },
      };
      return queueAPIClient
        .apiV1QueueRestartTrustedPortalEventsPost(processedOptions)
        .then(dataExtractor<NoContentResult>());
    },
    [queueAPIClient],
  );

  return {
    activateTrustedPortal,
    cancelQueueMessage,
    getUnprocessedInfo,
    redirectEventsToTrustedPortal,
    restartTrustedPortalEvents,
  };
};

export default useQueueAPI;
