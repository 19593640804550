import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  getTimeFromNow,
} from '@uniqkey-frontend/shared-app';
import {
  GetVaultSecureNotesResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import {
  IEmployeeGroupSecureNotesTableRow,
} from '../../../hooks/tables/useEmployeeGroupSecureNotesTable';

interface IGroupSecureNotesTableProps extends
  Omit<IUseTableReturn<IEmployeeGroupSecureNotesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetVaultSecureNotesResponseModel>, 'data' | 'refetch'>
{
  secureNotes: IUseTableFetchReturn<GetVaultSecureNotesResponseModel>['data'];
  selectedSecureNotes: IUseTableReturn<IEmployeeGroupSecureNotesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IEmployeeGroupSecureNotesTableRow> = {
  selection: true,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const GroupSecureNotesTable = (props: IGroupSecureNotesTableProps) => {
  const {
    secureNotes: preSecureNotes,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedSecureNotes,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();

  const secureNotes = useMemo(() => preSecureNotes.map((secureNote) => ({
    ...secureNote,
    lastActivity: getTimeFromNow({
      date: secureNote.activityAt,
      locale: currentLanguage,
    }),
  })), [preSecureNotes, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('secureNotesTab.table.columns.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '80%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('secureNotesTab.table.columns.lastActivity.columnTooltip')}
        >
          {t('secureNotesTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={secureNotes}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedSecureNotes}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(GroupSecureNotesTable);
