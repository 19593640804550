import { memo } from 'react';
import { useTranslations } from '@uniqkey-frontend/shared-app';
import { RestrictionType } from '@uniqkey-backend-organization-web/api-client';
import SelectionModal from '../../../../components/SelectionModal';
import SelectionModalOption, {
  TSelectionOptionType,
} from '../../../../components/SelectionModalOption';

export interface IGroupRestrictionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (type: TSelectionOptionType) => void;
}

const GroupRestrictionsModal = (props: IGroupRestrictionsModalProps) => {
  const { isOpen, onClose, onSelect } = props;
  const { t } = useTranslations();

  return (
    <SelectionModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('groupRestrictionsModal.dialogTitle')}
    >
      <SelectionModalOption
        title={t('groupRestrictionsModal.geolocation.title')}
        description={t('groupRestrictionsModal.geolocation.description')}
        type={RestrictionType.Location}
        onClick={onSelect}
      />
      <SelectionModalOption
        title={t('groupRestrictionsModal.time.title')}
        description={t('groupRestrictionsModal.time.description')}
        type={RestrictionType.Time}
        onClick={onSelect}
      />
      <SelectionModalOption
        title={t('groupRestrictionsModal.ipAddress.title')}
        description={t('groupRestrictionsModal.ipAddress.description')}
        type={RestrictionType.IpAddress}
        onClick={onSelect}
      />
    </SelectionModal>
  );
};

export default memo(GroupRestrictionsModal);
