import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  useSnackbar,
  usePubSub,
  buildFilterOptionsAsObject,
  IAutocompleteProps,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { GetEmployeeAccountsResponseModel } from '@uniqkey-backend-organization-web/api-client';
import { IGetGroupsParams } from '../../../../hooks/useEmployeeGroupsAPI/interfaces';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import useEmployeeAccountsAPI from '../../../../hooks/useEmployeeAccountsAPI';
import { logException } from '../../../../services/sentryService';

export interface IGroupsListFilterSubmitResult {
  canManageVaults: IGetGroupsParams['canManageVaults'];
  employeeAccount: IEmployeeAccountOption | undefined;
}

interface IGroupsListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IGroupsListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IGroupsListFilterSubmitResult;
}

type TCanManageVaultsOption = {
  label: string;
  value: IGroupsListFilterSubmitResult['canManageVaults'];
} | null;

interface IEmployeeAccountOption {
  value: GetEmployeeAccountsResponseModel['employeeAccountId'];
  label: GetEmployeeAccountsResponseModel['email'];
}

interface IFormValues {
  canManageVaults: TCanManageVaultsOption;
  employeeAccount: IEmployeeAccountOption | null;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const GroupsListFilter = (props: IGroupsListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getEmployeeAccounts } = useEmployeeAccountsAPI();

  const [canManageVaultsOptions, canManageVaultsOptionsAsObject] = useMemo(() => {
    const options = [
      { label: t('groupsFilter.loginsManagement.values.enabled'), value: true },
      { label: t('groupsFilter.loginsManagement.values.disabled'), value: false },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      canManageVaults: canManageVaultsOptionsAsObject[
        initialValues.canManageVaults?.toString() as keyof typeof canManageVaultsOptionsAsObject
      ] ?? null,
      employeeAccount: initialValues.employeeAccount ?? null,
    },
  });

  const handleGetEmployeeAccountsRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getEmployeeAccounts({ page: 1, pageLength: 100, searchQuery });
      return data!; // TODO: remove '!' when data in interface will be not optional
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupsListFilter/handleGetEmployeeAccountsRequest exception',
      });
      return [];
    }
  }, [getEmployeeAccounts, showError, t]);

  const handleGetEmployeeAccountsResponseParser = useCallback(
    (employeeAccountsToParse: GetEmployeeAccountsResponseModel[]) => employeeAccountsToParse.map(
      (employeeAccount: GetEmployeeAccountsResponseModel) => ({
        value: employeeAccount.employeeAccountId,
        label: employeeAccount.email,
      }),
    ),
    [],
  );

  const getOptionKey = useCallback<
    NonNullable<IAutocompleteProps['getOptionKey']>
  >((option) => option.value.toString(), []);

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { canManageVaults, employeeAccount } = value;
    onSubmit({
      canManageVaults: canManageVaults?.value,
      employeeAccount: employeeAccount ?? undefined,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('canManageVaults', null, { shouldDirty: true });
    setValue('employeeAccount', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      canManageVaults: null,
      employeeAccount: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center" columnSpacing={4}>
              <Grid item xs={3}>
                <Typography>
                  {t('groupsFilter.loginsManagement.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="canManageVaults"
                  options={canManageVaultsOptions}
                  getOptionKey={getOptionKey}
                  placeholder={t('groupsFilter.loginsManagement.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" columnSpacing={4}>
              <Grid item xs={3}>
                <Typography>
                  {t('groupsFilter.employees.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="employeeAccount"
                  placeholder={t('groupsFilter.employees.placeholder')}
                  dataSourceRequest={handleGetEmployeeAccountsRequest}
                  dataSourceResponseParser={handleGetEmployeeAccountsResponseParser}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(GroupsListFilter);
