import { ReactElement } from 'react';
import {
  Grid, DialogTitle, DialogContent, Dialog,
} from '@uniqkey-frontend/shared-app';
import { ISelectionModalOptionProps } from '../SelectionModalOption';

interface ISelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactElement<ISelectionModalOptionProps>[];
}

const SelectionModal = (props: ISelectionModalProps) => {
  const {
    isOpen, onClose, title, children,
  } = props;

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" rowSpacing={1}>
          {children.map((el) => (
            <Grid item key={el.props.type}>
              {el}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectionModal;
