import {
  type ApproveMasterPasswordResetRequestPayload,
} from '@uniqkey-backend-organization-web/api-client';
import {
  type GetMasterPasswordBackupResponse,
  type GetMasterPasswordResetTokenResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import {
  encryptAsymmetric,
  decryptAsymmetric,
} from '@uniqkey-frontend/shared-app';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import { dataExtractor } from '../../../../../helpers/apiClients';

const ERROR_PREFIX = 'TrustedPortal/handleApproveMasterPasswordResetRequestEvent';

const handleApproveMasterPasswordResetRequestEvent = async (
  params: ITrustedPortalHandlerParams<ApproveMasterPasswordResetRequestPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { employeeAccountId, ephemeralPublic } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!ephemeralPublic) {
    throw new Error(`${ERROR_PREFIX} no ephemeralPublic in event`);
  }

  const { masterPasswordKekMap } = await APIClientsProvider.Mobile
    .getEmployeeAccountsAPIClient(axiosInstance)
    .apiV1EmployeeAccountsGetMasterPasswordBackupEmployeeAccountIdGet(employeeAccountId)
    .then(dataExtractor<GetMasterPasswordBackupResponse>());

  const decryptedMapOfHashes = await decryptAsymmetric({
    publicKey: organizationPublicKey,
    privateKey: organizationPrivateKey,
    cipher: masterPasswordKekMap,
  });

  const { token } = await APIClientsProvider.Mobile
    .getMasterPasswordResetRequestAPIClient(axiosInstance)
    .apiV1MasterPasswordResetRequestGetResetMasterPasswordTokenEmployeeAccountIdGet(
      employeeAccountId,
    )
    .then(dataExtractor<GetMasterPasswordResetTokenResponse>());

  if (!token) {
    throw new Error(`${ERROR_PREFIX} token not found`);
  }

  const encryptedPayload = await encryptAsymmetric({
    publicKey: ephemeralPublic,
    string: JSON.stringify({ masterPasswordHashes: decryptedMapOfHashes, token }),
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueMasterPasswordResetRequestApprovePost({
      queueMessageId,
      payload: encryptedPayload,
    });
};

export default handleApproveMasterPasswordResetRequestEvent;
