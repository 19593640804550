import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import {
  NoContentResult, GetOrganizationsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery } from 'react-query';
import useMigrationAPI from '../../useMigrationAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { getCompanionApplicationId } from '../../../services/companionApplicationService';

export const useGetOrganizations = (
  options: TUseQueryOptions<GetOrganizationsResponse> = {},
) => {
  const { getOrganizations } = useMigrationAPI();
  return useQuery<GetOrganizationsResponse>(
    ReactQueryKeyEnum.Organizations,
    ({ signal }) => getOrganizations({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetEmployeesParams {
  organizationId: string,
  masterPasswordHash: string,
}

export const useGetEmployees = (
  params: IUseGetEmployeesParams,
  options: TUseQueryOptions<NoContentResult> = {},
) => {
  const { organizationId, masterPasswordHash } = params;
  const { check } = useMigrationAPI();
  const companionApplicationId = getCompanionApplicationId();
  return useQuery<NoContentResult>(
    [ReactQueryKeyEnum.Employees, organizationId],
    ({ signal }) => check(
      organizationId,
      companionApplicationId!,
      masterPasswordHash,
      { signal },
    ),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
