import { ReactNode } from 'react';
import { Grid } from '@uniqkey-frontend/shared-app';

interface IMigrationPanelContentProps {
  component?: string;
  onSubmit?: (form: any) => void | Promise<void>;
  children: ReactNode;
}

const MigrationPanelContent = (props: IMigrationPanelContentProps) => {
  const { children, component = 'div', onSubmit } = props;

  return (
    <Grid flex={1} display="flex" overflow="auto">
      <Grid
        // @ts-ignore
        component={component}
        flex={1}
        p={4}
        container
        onSubmit={onSubmit}
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        wrap="nowrap"
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default MigrationPanelContent;
