import { IKeyPair } from '@uniqkey-frontend/shared-app';
import {
  type ICompanionApplicationMigrationContext,
} from '../../contexts/CompanionApplicationContext';
import SessionStorageKeyEnum from '../../enums/SessionStorageKeyEnum';

export interface ICompanionApplicationData {
  symmetricKey: string;
  asymmetricKeys: IKeyPair;
  guid: string;
}

type TCompanionApplicationId = ICompanionApplicationMigrationContext['guid'] | null;

export const getCompanionApplicationId = (): TCompanionApplicationId => sessionStorage.getItem(
  SessionStorageKeyEnum.Guid,
);
export const setCompanionApplicationId = (guid: TCompanionApplicationId) => {
  if (!guid) {
    sessionStorage.removeItem(SessionStorageKeyEnum.Guid);
    return;
  }
  sessionStorage.setItem(SessionStorageKeyEnum.Guid, guid);
};
