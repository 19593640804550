import { useCallback, useMemo } from 'react';
import { FALLBACK_LANGUAGE } from '@uniqkey-frontend/shared-app';

export interface IGoogleMapsPrediction {
  address: google.maps.places.AutocompletePrediction['description'];
  placeId: google.maps.places.AutocompletePrediction['place_id'];
}

export type TGoogleMapsCoordinates = google.maps.LatLngLiteral | null;

interface IUseGoogleMapsAPIReturn {
  autocompleteService: google.maps.places.AutocompleteService;
  geocoder: google.maps.Geocoder;
  getPlacePredictions: (
    props: google.maps.places.AutocompletionRequest
  ) => Promise<IGoogleMapsPrediction[]>;
  getCoordinates: (props: google.maps.GeocoderRequest) => Promise<TGoogleMapsCoordinates>;
}

const useGoogleMapsAPI = (): IUseGoogleMapsAPIReturn => {
  const [autocompleteService, geocoder] = useMemo(() => [
    new window.google.maps.places.AutocompleteService(),
    new window.google.maps.Geocoder(),
  ], []);

  const getPlacePredictions = useCallback<IUseGoogleMapsAPIReturn['getPlacePredictions']>(async (
    props,
  ) => {
    const {
      input,
      types = [], // retrieve all types of predictions
      language = FALLBACK_LANGUAGE,
    } = props;
    const { predictions } = await autocompleteService.getPlacePredictions({
      input,
      types,
      language,
    });
    return predictions.map((prediction) => ({
      address: prediction.description,
      placeId: prediction.place_id,
    }));
  }, [autocompleteService]);

  const getCoordinates = useCallback<IUseGoogleMapsAPIReturn['getCoordinates']>(async (props) => {
    const { placeId } = props;
    const { results } = await geocoder.geocode({ placeId });
    const { location } = results[0].geometry;
    return { lat: location.lat(), lng: location.lng() };
  }, [geocoder]);

  return {
    autocompleteService,
    geocoder,
    getPlacePredictions,
    getCoordinates,
  };
};

export default useGoogleMapsAPI;
