import { decryptAsymmetric, encryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type ShareGroupSecretWithEmployeeAccountPayload,
} from '@uniqkey-backend-organization-web/api-client';
import { type GetPublicKeyResponse } from '@uniqkey-backend-organization-mobile/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import { dataExtractor } from '../../../../../helpers/apiClients';

const ERROR_PREFIX = 'TrustedPortal/handleShareGroupSecretWithEmployeeAccountEvent';

const getEmployeeAccountPublicKey = async (
  axiosInstance: ITrustedPortalHandlerParams['axiosInstance'],
  employeeAccountId: NonNullable<ShareGroupSecretWithEmployeeAccountPayload['employeeAccountId']>,
): Promise<string | null> => {
  try {
    const {
      employeeAccountKeyPairPublic,
    } = await APIClientsProvider.Mobile.getEmployeeAccountsAPIClient(axiosInstance)
      .apiV1EmployeeAccountsGetPublicKeyEmployeeAccountIdGet(employeeAccountId)
      .then(dataExtractor<GetPublicKeyResponse>());
    return employeeAccountKeyPairPublic;
  } catch (e) {
    return null;
  }
};

const handleShareGroupSecretWithEmployeeAccountEvent = async (
  params: ITrustedPortalHandlerParams<ShareGroupSecretWithEmployeeAccountPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    groupSecret,
    employeeAccountId,
  } = parsedPayload;

  if (!groupSecret) {
    throw new Error(`${ERROR_PREFIX} no groupSecret in event`);
  }

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  let { employeeAccountKeyPairPublic } = parsedPayload;
  if (!employeeAccountKeyPairPublic) {
    employeeAccountKeyPairPublic = await getEmployeeAccountPublicKey(
      axiosInstance,
      employeeAccountId,
    );
    if (!employeeAccountKeyPairPublic) {
      throw new Error(`${ERROR_PREFIX} no employeeAccountKeyPairPublic either in event or request`);
    }
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: groupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const encryptedGroupSecret = await encryptAsymmetric({
    string: decryptedGroupSecret,
    publicKey: employeeAccountKeyPairPublic,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueShareGroupSecretWithEmployeeAccountApprovePost({
      queueMessageId,
      groupSecret: encryptedGroupSecret,
    });
};

export default handleShareGroupSecretWithEmployeeAccountEvent;
