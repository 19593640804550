enum RealtimeAPIEventTypeEnum {
  OrganizationPortalAuthenticated = 'OrganizationPortalAuthenticated',
  OrganizationPortalAuthenticatedV2 = 'OrganizationPortalAuthenticatedV2',
  OrganizationPortalLogout = 'OrganizationPortalLogout',
  OrganizationPortalSynchronization = 'OrganizationPortalSynchronization',
  OrganizationPortalAuthenticationDenied = 'OrganizationPortalAuthenticationDenied',
  OrganizationEntitiesChanged = 'OrganizationEntitiesChanged',
  TrustedPortalRequestRejected = 'TrustedPortalRequestRejected',
  QueueMessageResultNotification = 'QueueMessageResultNotification',
  MigrationCheckResultNotification = 'MigrationCheckResultNotification'
  // TODO remove MigrationCheckResultNotification with migration modules
}

export default RealtimeAPIEventTypeEnum;
