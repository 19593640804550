/* eslint-disable max-len */
const config = {
  requestTimeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT as string, 10) ?? 10000,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  useLocalTranslations: process.env.REACT_APP_USE_LOCAL_TRANSLATIONS === 'true',
  environment: process.env.REACT_APP_ENVIRONMENT,
  GMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  getWebApiUrl: () => `https://organization-api-web.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu`,
  getMobileApiUrl: () => `https://organization-api-mobile.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu`,
  getScimIntegrationUrl: () => `https://scim-integration.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu/scim`,
};

export default config;
