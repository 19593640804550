import { memo, useMemo } from 'react';
import {
  Grid,
  useTranslations,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  AB3,
} from '@uniqkey-frontend/shared-app';
import { QueueEvent } from '@uniqkey-backend-organization-web/api-client';

interface IUnprocessedEventsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  unprocessedEvents: QueueEvent[];
}

const UnprocessedEventsModal = (props: IUnprocessedEventsModalProps) => {
  const {
    isOpen, onClose, onSubmit, unprocessedEvents,
  } = props;
  const { t } = useTranslations();
  const unprocessedEventsList = useMemo(() => (
    unprocessedEvents.map((item) => (
      <Typography noWrap color={AB3} key={item}>
        {t(`trustedPortalQueueEvents.${item}`)}
      </Typography>
    ))), [unprocessedEvents, t]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {t('unprocessedEventsModal.title')}
      </DialogTitle>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid item>
            <Typography>
              {t('unprocessedEventsModal.weHaveFoundUnprocessedEvents')}
            </Typography>
          </Grid>
          <Grid item>
            {unprocessedEventsList}
          </Grid>
          <Typography>
            {t('unprocessedEventsModal.requestTrustPortalToRestart')}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button onClick={onSubmit} fullWidth>
              {t('unprocessedEventsModal.requestTrustPortal')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={onClose} fullWidth variant="outlined">
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UnprocessedEventsModal);
