import {
  decryptSymmetric,
  encryptAsymmetric,
  encryptSymmetric,
  generateSymmetricKey,
  getSecurityScore,
} from '@uniqkey-frontend/shared-app';
import {
  type VaultPasswordCreationRequestedForEmployeePayload,
  type VaultPasswordCreationRequestedForGroupPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../../interfaces';
import { getSymmetricKey } from '../../../../../../services/companionApplicationService';

type TPassword = VaultPasswordCreationRequestedForEmployeePayload['password']
  | VaultPasswordCreationRequestedForGroupPayload['password'];

interface IHandleAdminVaultPasswordCreationParams extends Pick<
  ITrustedPortalHandlerParams, 'organizationPublicKey'
> {
  password: TPassword;
  errorPrefix: string;
}

const handleAdminVaultPasswordCreation = async (
  params: IHandleAdminVaultPasswordCreationParams,
) => {
  const { organizationPublicKey, password, errorPrefix } = params;

  const companionApplicationSymmetricKey = getSymmetricKey();
  if (!companionApplicationSymmetricKey) {
    throw new Error(`${errorPrefix} companionApplicationSymmetricKey not found`);
  }

  const symmetricKey = generateSymmetricKey();

  let encryptedPassword: null | string = null;
  let strength = 0;

  if (password) {
    const decryptedPassword = decryptSymmetric({
      cipher: password,
      key: companionApplicationSymmetricKey,
    });

    encryptedPassword = encryptSymmetric({
      string: decryptedPassword,
      key: symmetricKey,
    });

    strength = getSecurityScore(decryptedPassword);
  }

  const encryptedSymmetricKeyBackup = await encryptAsymmetric({
    string: symmetricKey,
    publicKey: organizationPublicKey,
  });

  return {
    encryptedPassword,
    strength,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  };
};

export default handleAdminVaultPasswordCreation;
