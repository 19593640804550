import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  AttachEmployeeAccountRequestModel,
  OkResult,
  DetachBulkEmployeeAccountsToGroupRequest,
  DetachBulkEmployeeAccountsToGroupResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { IInviteMeToGroup } from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useEmployeeAccountsToGroupsAPI = () => {
  const { employeeAccountsToGroupsAPIClient } = useApiClients();

  const inviteBulkToGroup = useCallback(async (
    params: IInviteMeToGroup,
    options?: AxiosRequestConfig,
  ): Promise<OkResult> => {
    const { groupsIds, employeeAccountsIds, ...rest } = params;
    const attachEmployeeAccounts: AttachEmployeeAccountRequestModel[] = employeeAccountsIds
      .reduce((acc: AttachEmployeeAccountRequestModel[], employeeAccountId) => {
        groupsIds.forEach((groupId) => acc.push({ employeeAccountId, groupId, ...rest }));
        return acc;
      }, []);
    return employeeAccountsToGroupsAPIClient
      .apiV1EmployeeAccountsToGroupsAttachEmployeeAccountBulkPost(
        { attachEmployeeAccounts },
        options,
      )
      .then(dataExtractor<OkResult>());
  }, [employeeAccountsToGroupsAPIClient]);

  const detachBulkEmployeeAccountsToGroup = useCallback(
    (
      detachBulkEmployeeAccountsToGroupRequest: DetachBulkEmployeeAccountsToGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<DetachBulkEmployeeAccountsToGroupResponse> => employeeAccountsToGroupsAPIClient
      .apiV1EmployeeAccountsToGroupsDetachBulkPost(
        detachBulkEmployeeAccountsToGroupRequest,
        options,
      )
      .then(dataExtractor<DetachBulkEmployeeAccountsToGroupResponse>()),
    [employeeAccountsToGroupsAPIClient],
  );

  return ({ inviteBulkToGroup, detachBulkEmployeeAccountsToGroup });
};

export default useEmployeeAccountsToGroupsAPI;
