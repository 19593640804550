import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
  Typography,
} from '@uniqkey-frontend/shared-app';
import { GetGroupsResponseModel } from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { SCIM_COLORS, SCIM_TRANSLATION_KEYS } from '../../../constants';

export interface IGroupsTableRow extends GetGroupsResponseModel {}

interface IGroupsTableProps extends
  Omit<IUseTableReturn<IGroupsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetGroupsResponseModel>, 'data' | 'refetch'>
{
  groups: IUseTableFetchReturn<GetGroupsResponseModel>['data'];
  selectedGroups: IUseTableReturn<IGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  isSecretTokenEmpty: boolean;
}

const TABLE_OPTIONS: IMaterialTableOptions<IGroupsTableRow> = {
  selection: true,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const GroupsTable = (props: IGroupsTableProps) => {
  const {
    groups,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedGroups,
    onRowSelectionChange,
    createColumns,
    isSecretTokenEmpty,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('groupsPage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '30%',
    },
    {
      title: t('groupsPage.members'),
      field: 'membersCount',
      width: '15%',
      render: (rowData) => (
        <Typography variant="body2">
          {rowData.membersCount}
        </Typography>
      ),
    },
    {
      title: t('groupsPage.logins'),
      field: 'vaultPasswordsCount',
      width: '15%',
      render: (rowData) => (
        <Typography variant="body2">
          {rowData.vaultPasswordsCount}
        </Typography>
      ),
    },
    {
      title: t('groupsPage.creditCards'),
      field: 'vaultCreditCardsCount',
      width: '15%',
      render: (rowData) => (
        <Typography variant="body2">
          {rowData.vaultCreditCardsCount}
        </Typography>
      ),
    },
    {
      title: t('groupsPage.secureNotes'),
      field: 'vaultSecureNotesCount',
      width: '15%',
      render: (rowData) => (
        <Typography variant="body2">
          {rowData.vaultSecureNotesCount}
        </Typography>
      ),
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('groupsPage.scim.columnTooltip')}
        >
          {t(SCIM_TRANSLATION_KEYS.true)}
        </TypographyWithTooltip>
      ),
      field: 'isByScim',
      render: (rowData) => (
        <Typography
          variant="body2"
          color={isSecretTokenEmpty
            ? SCIM_COLORS.false
            : SCIM_COLORS[rowData.isByScim.toString() as keyof typeof SCIM_COLORS]}
        >
          {isSecretTokenEmpty ? t('scim.no') : t(SCIM_TRANSLATION_KEYS[
            rowData.isByScim.toString() as keyof typeof SCIM_TRANSLATION_KEYS
          ])}
        </Typography>
      ),
      width: '10%',
    },
  ]), [createColumns, isSecretTokenEmpty, t]);

  const handleGroupRowClick = useCallback<
    NonNullable<IMaterialTableProps<IGroupsTableRow>['onRowClick']>
  >((event, group) => {
    navigate(`${PageRouteEnum.Groups}/${group!.groupId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={groups}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedGroups}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleGroupRowClick}
      rowSelectByKey="groupId"
      rowHeight={51}
    />
  );
};

export default memo(GroupsTable);
