import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
  Typography,
  S6,
  S5,
  AR1,
  Divider,
  Button,
  useTranslations,
  useSnackbar,
  PanelContent,
  CircularProgress,
  AB3,
} from '@uniqkey-frontend/shared-app';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GetOrganizationsResponseModel, CheckQueryModel, MigrationCheckResultNotification,
} from '@uniqkey-backend-organization-web/api-client';
import { getMasterPasswordHash, logout } from '../../services/authService';
import MigrationPanelHeader from '../../components/MigrationPanel/components/MigrationPanelHeader';
import MigrationPanel from '../../components/MigrationPanel';
import MigrationPanelContent
  from '../../components/MigrationPanel/components/MigrationPanelContent';
import { useGetEmployees } from '../../hooks/reactQuery/useMigrationAPI';
import ViewEmployeesModal from './components/ViewEmployeesModal';
import MainPageRouteEnum from '../../../enums/PageRouteEnum';
import useMigrationAPI from '../../hooks/useMigrationAPI';
import { logException } from '../../../services/sentryService';
import MigrationProcessModal from './components/MigrationProcessModal';
import MigrationPageRouteEnum from '../../enums/MigrationPageRouteEnum';
import { subscribeToRealtimeAPIEvent } from '../../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../../enums/RealtimeAPIEventTypeEnum';
import { getCompanionApplicationId } from '../../services/companionApplicationService';

const ReportPage = () => {
  const [isViewEmployeesModalOpen, setIsViewEmployeesModalOpen] = useState<boolean>(false);
  const [isMigrationProcessModalOpen, setIsMigrationProcessModalOpen] = useState<boolean>(false);
  const [employees, setEmployees] = useState<CheckQueryModel[]>([]);
  const [totalEmployeesCount, setTotalEmployeesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { migrate, checkStatus } = useMigrationAPI();
  const masterPasswordHash = getMasterPasswordHash();
  const { state: locationState } = useLocation();
  const { name, id } = locationState as GetOrganizationsResponseModel;
  useGetEmployees(
    { organizationId: id, masterPasswordHash: masterPasswordHash! },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
    },
  );

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<MigrationCheckResultNotification>(
      RealtimeAPIEventTypeEnum.MigrationCheckResultNotification,
      async (event) => {
        try {
          if (event.organizationId !== id) return;
          if (event.errorMessage) {
            showError({ text: t('common.somethingWentWrong') });
            setIsLoading(false);
            return;
          }
          setEmployees(event.employees!);
          setTotalEmployeesCount(event.totalEmployeesCount!);
          setIsLoading(false);
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message:
              'ReportPage/RealtimeAPIEventTypeEnum.MigrationCheckResultNotification exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [showError, t, id]);

  const employeesWithoutBackupCount = employees.length;
  const employeesWithBackupCount = useMemo(
    () => (employeesWithoutBackupCount
      ? totalEmployeesCount - employeesWithoutBackupCount : totalEmployeesCount),
    [employeesWithoutBackupCount, totalEmployeesCount],
  );
  const toggleViewEmployeesModalOpen = useCallback(
    () => setIsViewEmployeesModalOpen((isOpen) => !isOpen),
    [],
  );
  const handleClose = useCallback(() => {
    navigate(MainPageRouteEnum.Login);
    logout();
    window.location.reload();
  }, [navigate]);
  const runMigration = useCallback(async () => {
    try {
      const companionApplicationId = getCompanionApplicationId();
      await migrate({
        organizationId: id,
        masterPasswordHash: masterPasswordHash!,
        companionApplicationId: companionApplicationId!,
      });
      setIsMigrationProcessModalOpen(true);
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'ReportPage/runMigration exception',
      });
    }
  }, [migrate, masterPasswordHash, id, showError, t]);
  const checkMigrationStatus = useCallback(async () => {
    try {
      const { isFailed, isCompleted } = await checkStatus(id);
      if (isCompleted) {
        setIsMigrationProcessModalOpen(false);
        navigate(MigrationPageRouteEnum.Congrats, { state: locationState });
      }
      if (isFailed) {
        setIsMigrationProcessModalOpen(false);
        showError({
          text: t('common.somethingWentWrong'),
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      logException(e, {
        message: 'ReportPage/run exception',
      });
    }
  }, [checkStatus, id, navigate, locationState, showError, t]);

  useEffect(() => {
    if (!isMigrationProcessModalOpen) return;
    const run = setInterval(checkMigrationStatus, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(run);
  }, [isMigrationProcessModalOpen, checkMigrationStatus]);

  if (isLoading) {
    return (
      <PanelContent p={3} className="height-100-percent">
        <Grid container alignItems="center" justifyContent="center" height="inherit">
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  return (
    <MigrationPanel>
      <MigrationPanelHeader title={t('migration.reportPage.header', { name })} />
      <MigrationPanelContent>
        {!employeesWithoutBackupCount && (
          <Typography color={S5}>
            {t('migration.reportPage.employeesWillBeMigrated', { count: totalEmployeesCount })}
          </Typography>
        )}
        {!!employeesWithoutBackupCount && (
          <>
            <Typography color={S5}>
              {t(
                'migration.reportPage.employeesOutOfWillBeMigrated',
                { migrationCount: employeesWithBackupCount, totalCount: totalEmployeesCount },
              )}
            </Typography>
            <Grid container rowGap={4}>
              <Grid
                container
                alignContent="center"
                flexDirection="column"
                textAlign="center"
                rowGap={2}
              >
                <Typography color={AR1}>
                  {t(
                    'migration.reportPage.employeesCannotBeMigrated',
                    { count: employeesWithoutBackupCount },
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={AB3}
                  onClick={toggleViewEmployeesModalOpen}
                  asLink
                >
                  {t('migration.reportPage.viewEmployees')}
                </Typography>
              </Grid>
              <Grid
                container
                item
                alignContent="center"
                flexDirection="column"
                textAlign="center"
                rowGap={4}
              >
                <Grid item px={18}>
                  <Divider />
                </Grid>
                <Typography color={S5}>
                  {t('migration.reportPage.youCanAskThemToFollowTheSteps')}
                </Typography>
                <Grid item container textAlign="center">
                  <Grid item xs={4}>
                    <Typography color={S6} variant="h5">
                      {t('migration.reportPage.step1')}
                    </Typography>
                    <Typography color={S5} variant="caption">
                      {t('migration.reportPage.step1Description')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color={S6} variant="h5">
                      {t('migration.reportPage.step2')}
                    </Typography>
                    <Typography color={S5} variant="caption">
                      {t('migration.reportPage.step2Description')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color={S6} variant="h5">
                      {t('migration.reportPage.step3')}
                    </Typography>
                    <Typography color={S5} variant="caption">
                      {t('migration.reportPage.step3Description')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item px={18}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          item
          alignContent="center"
          flexDirection="column"
          textAlign="center"
          rowGap={4}
          mt={2}
        >
          <Typography color={S5}>
            {t(
              'migration.reportPage.doYouWantToRunTheMigration',
              { count: employeesWithBackupCount },
            )}
          </Typography>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item xs={4}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                {t('migration.reportPage.close')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth onClick={runMigration}>
                {t('migration.reportPage.runMigration')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MigrationPanelContent>
      <ViewEmployeesModal
        handleToggle={toggleViewEmployeesModalOpen}
        isModalOpen={isViewEmployeesModalOpen}
        employees={employees}
      />
      <MigrationProcessModal isModalOpen={isMigrationProcessModalOpen} name={name} />
    </MigrationPanel>
  );
};

export default ReportPage;
