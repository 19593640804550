import type { ChartArea } from 'chart.js';
import { AB4 } from '@uniqkey-frontend/shared-app';

// eslint-disable-next-line import/prefer-default-export
export const createLineChartGradient = (ctx : CanvasRenderingContext2D, area: ChartArea) => {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  gradient.addColorStop(0, `${AB4}00`);
  gradient.addColorStop(1, AB4);
  return gradient;
};
