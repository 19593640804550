enum PubSubEventEnum {
  LOGIN = 'login',
  LOGOUT = 'logout',
  DATA_SYNCHRONIZATION_VAULT = 'DATA_SYNCHRONIZATION_VAULT',
  DATA_SYNCHRONIZATION_EMPLOYEE = 'DATA_SYNCHRONIZATION_EMPLOYEE',
  DATA_SYNCHRONIZATION_GROUP = 'DATA_SYNCHRONIZATION_GROUP',
  DATA_SYNCHRONIZATION_VERIFIED_DOMAINS = 'DATA_SYNCHRONIZATION_VERIFIED_DOMAINS',
  DATA_SYNCHRONIZATION_SECURITY_SETTINGS = 'DATA_SYNCHRONIZATION_SECURITY_SETTINGS',
  DATA_SYNCHRONIZATION_ORGANIZATION_DETAILS = 'DATA_SYNCHRONIZATION_ORGANIZATION_DETAILS',
  DATA_SYNCHRONIZATION_GROUP_RESTRICTIONS = 'DATA_SYNCHRONIZATION_GROUP_RESTRICTIONS',
  DATA_SYNCHRONIZATION_CURRENT_USER = 'DATA_SYNCHRONIZATION_CURRENT_USER',
  RESET_TABLE = 'RESET_TABLE',
  RESET_FILTER = 'RESET_FILTER',
  TRIGGER_TOKENS_REFRESH = 'TRIGGER_TOKENS_REFRESH',
  TOKENS_REFRESHED = 'TOKENS_REFRESHED',
  WS_CHANGE_URL = 'WS_CHANGE_URL',
  ACTIVE_ORGANIZATION_CHANGED = 'ACTIVE_ORGANIZATION_CHANGED',
}

export default PubSubEventEnum;
