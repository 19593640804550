import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetUserProfileResponse,
  OkResult,
  UpdateUserLanguageRequest,
  UpdateUserNameRequest,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useUserAPI from '../../useUserAPI';

export const REACT_QUERY_CURRENT_USER_KEY = [ReactQueryKeyEnum.CurrentUser];

export const useGetCurrentUser = (
  options: TUseQueryOptions<GetUserProfileResponse> = {},
) => {
  const { getCurrentUser } = useUserAPI();
  return useQuery<GetUserProfileResponse>(
    REACT_QUERY_CURRENT_USER_KEY,
    ({ signal }) => getCurrentUser({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateCurrentUserProfileParams {
  useOptimisticUpdates?: boolean;
}
export const useUpdateCurrentUserProfile = (
  params: IUseUpdateCurrentUserProfileParams = {},
  options: TUseMutationOptions<
    OkResult,
    unknown,
    UpdateUserNameRequest,
    TUseMutationContext<GetUserProfileResponse>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateCurrentUserProfile } = useUserAPI();
  return useMutation(
    REACT_QUERY_CURRENT_USER_KEY,
    (currentUserProfile) => updateCurrentUserProfile(currentUserProfile),
    {
      onMutate: async (newCurrentUserProfile) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(REACT_QUERY_CURRENT_USER_KEY);
        const previousValue = queryClient.getQueryData<
          GetUserProfileResponse
      >(REACT_QUERY_CURRENT_USER_KEY);
        queryClient.setQueryData<GetUserProfileResponse>(
          REACT_QUERY_CURRENT_USER_KEY,
          (oldCurrentUser) => ({
            ...oldCurrentUser,
            ...newCurrentUserProfile,
          } as GetUserProfileResponse),
        );
        return { previousValue: previousValue as GetUserProfileResponse };
      },
      onError: (err, currentUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetUserProfileResponse>(
            REACT_QUERY_CURRENT_USER_KEY,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(REACT_QUERY_CURRENT_USER_KEY);
      },
      ...options,
    },
  );
};

interface IUseUpdateCurrentUserLanguageParams {
  useOptimisticUpdates?: boolean;
}
export const useUpdateCurrentUserLanguage = (
  params: IUseUpdateCurrentUserLanguageParams = {},
  options: TUseMutationOptions<
    OkResult,
    unknown,
    UpdateUserLanguageRequest,
    TUseMutationContext<GetUserProfileResponse>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateCurrentUserLanguage } = useUserAPI();
  return useMutation(
    REACT_QUERY_CURRENT_USER_KEY,
    (currentUserLanguage) => updateCurrentUserLanguage(currentUserLanguage),
    {
      onMutate: async (newCurrentUserLanguage) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(REACT_QUERY_CURRENT_USER_KEY);
        const previousValue = queryClient.getQueryData<
          GetUserProfileResponse
        >(REACT_QUERY_CURRENT_USER_KEY);
        queryClient.setQueryData<GetUserProfileResponse>(
          REACT_QUERY_CURRENT_USER_KEY,
          (oldCurrentUser) => ({
            ...oldCurrentUser,
            ...newCurrentUserLanguage,
          } as GetUserProfileResponse),
        );
        return { previousValue: previousValue as GetUserProfileResponse };
      },
      onError: (err, currentUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetUserProfileResponse>(
            REACT_QUERY_CURRENT_USER_KEY,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(REACT_QUERY_CURRENT_USER_KEY);
      },
      ...options,
    },
  );
};
