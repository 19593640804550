import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GetManagedByEmployeesByVaultIdOrderProperty,
  GetManagedByEmployeesByVaultIdResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetEmployeeAccountsManagedByEmployeesByVaultIdParams,
  TGetEmployeeAccountsManagedByEmployeesByVaultIdMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import {
  IOrganizationLoginEmployeesToEmployeesTableRow,
} from '../../../components/tables/OrganizationLoginEmployeesToEmployeesTable';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';

interface IPersistentFilters extends Pick<
  IGetEmployeeAccountsManagedByEmployeesByVaultIdParams, 'vaultId'
> {}

export interface IUseOrganizationLoginEmployeesToEmployeesTableProps {
  persistentFilters: IPersistentFilters;
}

export interface IUseOrganizationLoginEmployeesToEmployeesTableReturn extends
  Omit<
    IUseTableReturn<IOrganizationLoginEmployeesToEmployeesTableRow>,
    'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseTableFetchReturn<GetManagedByEmployeesByVaultIdResponseModel>, 'data'>
{
  employees: IUseTableFetchReturn<GetManagedByEmployeesByVaultIdResponseModel>['data'];
  selectedEmployees: IUseTableReturn<
    IOrganizationLoginEmployeesToEmployeesTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetEmployeeAccountsManagedByEmployeesByVaultIdParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<
    IGetEmployeeAccountsManagedByEmployeesByVaultIdParams['searchQuery']>
  >;
}

interface ITablePreservationConfigs extends
  Omit<IGetEmployeeAccountsManagedByEmployeesByVaultIdParams,
    'page' | 'pageLength' | 'vaultId' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
  >,
  Pick<
    IUseTableReturn<IOrganizationLoginEmployeesToEmployeesTableRow>,
    'columnOrderBy' | 'columnOrderDirection'
  >
{
  activePage: IGetEmployeeAccountsManagedByEmployeesByVaultIdParams['page'];
  perPage: IGetEmployeeAccountsManagedByEmployeesByVaultIdParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GetManagedByEmployeesByVaultIdOrderProperty },
  ),
  columnOrderDirection: true,
};

const useOrganizationLoginEmployeesToEmployeesTable = (
  props: IUseOrganizationLoginEmployeesToEmployeesTableProps,
): IUseOrganizationLoginEmployeesToEmployeesTableReturn => {
  const {
    persistentFilters,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getEmployeeAccountsManagedByEmployeesByVaultId } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetEmployeeAccountsManagedByEmployeesByVaultIdParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedEmployees,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationLoginEmployeesToEmployeesTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: employees, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetEmployeeAccountsManagedByEmployeesByVaultIdParams,
    TGetEmployeeAccountsManagedByEmployeesByVaultIdMethod,
    GetManagedByEmployeesByVaultIdResponseModel
  >({
    request: getEmployeeAccountsManagedByEmployeesByVaultId,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: GetManagedByEmployeesByVaultIdOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GetManagedByEmployeesByVaultIdOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    employees,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedEmployees,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationLoginEmployeesToEmployeesTable;
