import { Grid } from '@uniqkey-frontend/shared-app';
import DashboardEmployeeActivationRateWidget
  from './components/DashboardEmployeeActivationRateWidget';
import DashboardActiveEmployeesRateWidget from './components/DashboardActiveEmployeesRateWidget';

const DashboardEmployeeRateWidgets = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} lg={6}>
      <DashboardEmployeeActivationRateWidget />
    </Grid>
    <Grid item xs={12} lg={6}>
      <DashboardActiveEmployeesRateWidget />
    </Grid>
  </Grid>
);

export default DashboardEmployeeRateWidgets;
