import { createContext, useContext } from 'react';

export interface ICompanionApplicationMigrationContext {
  guid: string;
  saveCompanionApplicationData: () => void;
}

export const CompanionApplicationMigrationDefaultContext: ICompanionApplicationMigrationContext = {
  guid: '',
  saveCompanionApplicationData: () => {},
};

const CompanionApplicationMigrationContext = createContext<
  ICompanionApplicationMigrationContext
>(CompanionApplicationMigrationDefaultContext);

CompanionApplicationMigrationContext.displayName = 'CompanionApplicationMigrationContext';

export default CompanionApplicationMigrationContext;

export const useCompanionApplicationContext = ()
  : ICompanionApplicationMigrationContext => useContext<ICompanionApplicationMigrationContext>(
    CompanionApplicationMigrationContext,
  );
