import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import { getEmployeesTokens } from '../authService';

export const getActiveOrganizationId = (): string | null => {
  const storedOrganizationId = localStorage.getItem(LocalStorageKeyEnum.ActiveOrganizationId);
  const employeeTokens = getEmployeesTokens();

  if (!employeeTokens || !Object.entries(employeeTokens).length) return null;

  const firstEmployeeTokenInfo = Object.values(employeeTokens)[0];
  const { organizationId } = firstEmployeeTokenInfo;

  // if no stored org id or stored id is not relevant anymore
  if (!storedOrganizationId || !employeeTokens[storedOrganizationId]) {
    setActiveOrganizationId(organizationId);
    return organizationId;
  }

  return storedOrganizationId;
};

export const setActiveOrganizationId = (
  id: string,
) => localStorage.setItem(LocalStorageKeyEnum.ActiveOrganizationId, id);
