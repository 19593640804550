import { decryptAsymmetric, encryptSymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultCreditCardCreationRequestedForGroupPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultCreditCardCreation from '../common/handleAdminVaultCreditCardCreation';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultCreditCardCreationForGroupRequestedEvent';

const handleAdminVaultCreditCardCreationForGroupRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultCreditCardCreationRequestedForGroupPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    expiryDate,
    cardNumber,
    cvv,
    cardHolderName,
    groupSecret,
  } = parsedPayload;

  if (!groupSecret) {
    throw new Error(`${ERROR_PREFIX} no groupSecret in event`);
  }

  if (!cardNumber) {
    throw new Error(`${ERROR_PREFIX} no cardNumber in event`);
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: groupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const {
    encryptedCardNumber,
    encryptedCardHolderName,
    encryptedExpiryDate,
    encryptedCVV,
    creditCardInfo,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultCreditCardCreation({
    organizationPublicKey,
    cardNumber,
    cardHolderName,
    expiryDate,
    cvv,
    errorPrefix: ERROR_PREFIX,
  });

  const encryptedSymmetricKey = encryptSymmetric({
    string: symmetricKey,
    key: decryptedGroupSecret,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminVaultCreditCardCreationRequestedForGroupApprovePost({
      queueMessageId,
      cardNumber: encryptedCardNumber,
      cardHolderName: encryptedCardHolderName,
      expiryDate: encryptedExpiryDate,
      cvv: encryptedCVV,
      lastDigits: creditCardInfo.lastDigits,
      paymentSystem: creditCardInfo.paymentSystem,
      vaultSymmetricKey: encryptedSymmetricKey,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleAdminVaultCreditCardCreationForGroupRequestedEvent;
