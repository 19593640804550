import { Suspense, useMemo } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { loadCustomScrollbarPlugins, loadStyles } from '@uniqkey-frontend/shared-app';
import './helpers/ChartJSLib/config';
import UserProviderContext from './contexts/UserContext/provider';
import Router from './Router';
import APIClientsProviderContext from './contexts/APIClientsContext/provider';
import CompanionApplicationProviderContext from './contexts/CompanionApplicationContext/provider';
import InitializationContainer from './components/InitializationContainer';
import GoogleMapsScriptLoader from './components/GoogleMaps/GoogleMapsScriptLoader';
import { listenRefreshTokensEvents } from './services/tokensManager';
import { initWebSockets } from './services/webSocketsManager';
import TrustedPortalModule from './modules/TrustedPortalModule';
import MigrationPageRouteEnum from './migrationModules/enums/MigrationPageRouteEnum';
import MigrationApp from './migrationModules/App';

// @ts-ignore
import('./index.css');

loadStyles({
  themeColors: true,
  dateRangePicker: true,
  snackbar: true,
  reactCircularProgressbar: true,
  customScrollbar: true,
});

loadCustomScrollbarPlugins();

listenRefreshTokensEvents();

initWebSockets();

TrustedPortalModule.initModule();

const ReactQueryClient = new QueryClient();

const App = () => {
  const isMigration = useMemo(
    () => (window.location.pathname.startsWith(MigrationPageRouteEnum.Migration)),
    [],
  );
  if (isMigration) {
    return <MigrationApp />;
  }

  return (
    <QueryClientProvider client={ReactQueryClient}>
      <UserProviderContext>
        <APIClientsProviderContext>
          <CompanionApplicationProviderContext>
            <InitializationContainer>
              <DndProvider backend={HTML5Backend}>
                <GoogleMapsScriptLoader>
                  <Suspense fallback={null}>
                    <Router />
                  </Suspense>
                  <ReactQueryDevtools />
                </GoogleMapsScriptLoader>
              </DndProvider>
            </InitializationContainer>
          </CompanionApplicationProviderContext>
        </APIClientsProviderContext>
      </UserProviderContext>
    </QueryClientProvider>
  );
};

export default App;
