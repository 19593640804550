import { memo, useCallback, useState } from 'react';
import {
  Grid,
  useTranslations,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  WarningIcon,
  Checkbox,
  AO4,
} from '@uniqkey-frontend/shared-app';

interface IUnprocessedEmployeesModalProps {
  isOpen: boolean;
  onClose: (isDoNotShowCheckboxChecked: boolean) => void;
}

const UnprocessedEmployeesModal = (props: IUnprocessedEmployeesModalProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslations();
  const [isDoNotShowCheckboxChecked, setIsDoNotShowCheckboxChecked] = useState(false);

  const handleClose = useCallback(() => {
    onClose(isDoNotShowCheckboxChecked);
  }, [onClose, isDoNotShowCheckboxChecked]);

  const handleDoNotShowCheckboxChange = useCallback(() => {
    setIsDoNotShowCheckboxChecked((checked) => !checked);
  }, []);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        {t('unprocessedEmployeesModal.title')}
      </DialogTitle>
      <DialogContent>
        <Grid container rowGap={4}>
          <Grid item>
            <Typography>
              {t('unprocessedEmployeesModal.description.paragraph1')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t('unprocessedEmployeesModal.description.paragraph2')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t('unprocessedEmployeesModal.description.paragraph3')}
            </Typography>
          </Grid>
          <Grid item container alignItems="center" justifyContent="center" columnGap={1}>
            <WarningIcon color={AO4} width={24} height={24} />
            <Typography color={AO4}>
              {t('unprocessedEmployeesModal.makeSureTrustPortalIsOn')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={2}>
          <Grid item>
            <Checkbox
              label={t('unprocessedEmployeesModal.doNotShowThisMessage')}
              checked={isDoNotShowCheckboxChecked}
              onChange={handleDoNotShowCheckboxChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Button onClick={handleClose} fullWidth>
              {t('common.ok')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UnprocessedEmployeesModal);
