import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreatePairingCodeRequest, CreatePairingCodeResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const usePairingCodesAPI = () => {
  const { pairingCodesAPIClient } = useApiClients();

  const generatePairingCode = useCallback(
    (
      createPairingCodeRequest: CreatePairingCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<CreatePairingCodeResponse> => pairingCodesAPIClient
      .apiV1PairingCodesCreatePairingCodePost(createPairingCodeRequest, options)
      .then(dataExtractor<CreatePairingCodeResponse>()),
    [pairingCodesAPIClient],
  );

  return ({ generatePairingCode });
};

export default usePairingCodesAPI;
