import { decryptAsymmetric, encryptSymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultSecureNoteCreationRequestedForGroupPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultSecureNoteCreation from '../common/handleAdminVaultSecureNoteCreation';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultSecureNoteCreationForGroupRequestedEvent';

const handleAdminVaultSecureNoteCreationForGroupRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultSecureNoteCreationRequestedForGroupPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { note, groupSecret } = parsedPayload;

  if (!groupSecret) {
    throw new Error(`${ERROR_PREFIX} no groupSecret in event`);
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: groupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const {
    encryptedNote,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultSecureNoteCreation({
    organizationPublicKey,
    note,
    errorPrefix: ERROR_PREFIX,
  });

  const encryptedSymmetricKey = encryptSymmetric({
    string: symmetricKey,
    key: decryptedGroupSecret,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminVaultSecureNoteCreationRequestedForGroupApprovePost({
      queueMessageId,
      note: encryptedNote,
      vaultSymmetricKey: encryptedSymmetricKey,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleAdminVaultSecureNoteCreationForGroupRequestedEvent;
