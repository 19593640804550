import PubSub from 'pubsub-js';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import TrustedPortalRealtimeAPIEventTypeEnum
  from '../../modules/TrustedPortalModule/enums/RealtimeAPIEventTypeEnum';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import { type ICompanionApplicationContext } from '../../contexts/CompanionApplicationContext';
import config from '../../config';

type TSetTimeoutReturn = ReturnType<typeof setTimeout>;

const RECONNECT_TIMEOUT = 3 * 1000;

const getURL = (guid: string) => `${config.getWebApiUrl()}/notifications?clientId=${guid}`;

const connection = new HubConnectionBuilder()
  .withUrl(
    getURL(''),
    {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    },
  )
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: () => RECONNECT_TIMEOUT,
  })
  .build();

let timeoutID: TSetTimeoutReturn | null = null;

async function start(guid: ICompanionApplicationContext['guid']) {
  try {
    await connection.stop();
    connection.baseUrl = getURL(guid);
    await connection.start();
  } catch (err) {
    timeoutID = setTimeout(() => start(guid), RECONNECT_TIMEOUT);
  }
}

export const initWebSockets = () => {
  PubSub.subscribe(PubSubEventEnum.WS_CHANGE_URL, async (_, data) => {
    const { guid } = data;

    clearTimeout(timeoutID as TSetTimeoutReturn);
    timeoutID = null;

    await start(guid);
  });
};

type TSubscribeToRealtimeAPIEvent = <TEvent = {}>(
  event: RealtimeAPIEventTypeEnum | TrustedPortalRealtimeAPIEventTypeEnum,
  cb: (event: TEvent) => void | Promise<void>
) => () => void;
export const subscribeToRealtimeAPIEvent: TSubscribeToRealtimeAPIEvent = (event, cb) => {
  connection.on(event, cb);
  return () => {
    connection.off(event, cb);
  };
};
