import { useMemo, ReactNode } from 'react';
import { MigrationApi } from '@uniqkey-backend-organization-web/api-client';
import APIClientsContext from '.';
import config from '../../../config';
import axiosInstance from '../../axios';

const WEB_API_BASE_URL = config.getWebApiUrl();

const APIClientsProviderContext = ({ children }: { children: ReactNode }): JSX.Element => {
  const value = useMemo(() => ({
    axiosInstance,
    migrationLoginAPIClient: new MigrationApi(
      undefined,
      WEB_API_BASE_URL,
    ),
    migrationAPIClient: new MigrationApi(
      undefined,
      WEB_API_BASE_URL,
      axiosInstance,
    ),
  }), []);

  return (
    <APIClientsContext.Provider value={value}>
      {children}
    </APIClientsContext.Provider>
  );
};

export default APIClientsProviderContext;
