import { memo, useMemo } from 'react';
import type { ChartOptions } from 'chart.js';
import {
  Grid,
  WidgetContainer,
  DateRangePickerWithTextField,
  Typography,
  S4,
  S6,
  useTranslations,
  formatDate,
  DateTimeFormatEnum,
  IDateRangePickerWithTextFieldProps,
  DateRange,
  TypographyWithHelp,
} from '@uniqkey-frontend/shared-app';
import {
  GetActiveEmployeesRateItem,
  GetEmployeeActivationRateItem,
} from '@uniqkey-backend-organization-web/api-client';
import TimeToPercentLineChart from '../../../../../../components/charts/TimeToPercentLineChart';
import { TLineChartType } from '../../../../../../helpers/ChartJSLib/interfaces';

interface IDashboardEmployeeRateWidgetContainerProps extends DateRange, Pick<
  IDateRangePickerWithTextFieldProps, 'onSubmit' | 'onClear'
> {
  headerTranslationKey: string;
  tooltipTranslationKey: string;
  updatedAtTranslationKey: string;
  noDataTranslationKey: string;
  updatedAt: string;
  isLoading: boolean;
  data: GetEmployeeActivationRateItem[] | GetActiveEmployeesRateItem[];
  options?: ChartOptions<TLineChartType>;
}

const CONTAINER_SX = { height: '100%', minHeight: 270 };

const DashboardEmployeeRateWidgetContainer = (
  props: IDashboardEmployeeRateWidgetContainerProps,
) => {
  const { t, currentLanguage } = useTranslations();
  const {
    headerTranslationKey,
    tooltipTranslationKey,
    updatedAtTranslationKey,
    noDataTranslationKey,
    updatedAt: preUpdatedAt,
    from,
    to,
    onSubmit,
    onClear,
    isLoading,
    data,
    options,
  } = props;

  const updatedAt = useMemo(
    () => formatDate({
      date: preUpdatedAt,
      locale: currentLanguage,
    }, DateTimeFormatEnum.FULL),
    [currentLanguage, preUpdatedAt],
  );

  return (
    <WidgetContainer withShadow isLoading={isLoading} sx={CONTAINER_SX}>
      <Grid container flexDirection="column" sx={CONTAINER_SX}>
        <Grid container justifyContent="space-between" rowGap={1}>
          <Grid item flexDirection="column">
            <Grid item>
              <TypographyWithHelp
                color={S6}
                helperText={t(tooltipTranslationKey)}
              >
                {t(headerTranslationKey)}
              </TypographyWithHelp>
            </Grid>
            <Grid item mt={0.5}>
              {updatedAt && (
                <Typography variant="caption" color={S4}>
                  {t(updatedAtTranslationKey, { updatedAt })}
                </Typography>
              )}
            </Grid>
          </Grid>
          <DateRangePickerWithTextField
            from={from}
            to={to}
            onSubmit={onSubmit}
            onClear={onClear}
            t={t}
          />
        </Grid>
        <Grid container flex={1}>
          <TimeToPercentLineChart
            data={data}
            options={options}
            noDataTranslationKey={noDataTranslationKey}
          />
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(DashboardEmployeeRateWidgetContainer);
