import { memo, useCallback } from 'react';
import { usePubSub } from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { useGetVaultPasswordById } from '../../../../hooks/reactQuery';
import SecurityScoreWidget from '../../../../components/SecurityScoreWidget';

interface IOrganizationLoginDetailsSecurityScoreWidgetProps {
  vaultId: string;
}

const OrganizationLoginDetailsSecurityScoreWidget = (
  props: IOrganizationLoginDetailsSecurityScoreWidgetProps,
) => {
  const { vaultId } = props;
  const {
    data: vault, isLoading, isError, refetch,
  } = useGetVaultPasswordById({ vaultId });
  const { strength } = vault ?? {};

  const handleRefetch = useCallback(() => refetch(), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT, handleRefetch);

  if (isError) {
    return null;
  }

  return <SecurityScoreWidget isLoading={isLoading} percentage={strength} />;
};

export default memo(OrganizationLoginDetailsSecurityScoreWidget);
