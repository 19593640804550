import {
  type RevokeAdminPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const handleRevokeAdminEvent = async (
  params: ITrustedPortalHandlerParams<RevokeAdminPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
  } = params;

  const { queueMessageId } = parsedEvent;

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueRevokeAdminApprovePost({
      queueMessageId,
    });
};

export default handleRevokeAdminEvent;
