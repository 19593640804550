import {
  type PromoteAdminPayload,
} from '@uniqkey-backend-organization-web/api-client';
import {
  encryptAsymmetric,
  generateSymmetricKey,
  encryptSymmetric,
} from '@uniqkey-frontend/shared-app';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handlePromoteAdminEvent';

const handlePromoteAdminEvent = async (
  params: ITrustedPortalHandlerParams<PromoteAdminPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    employeeAccountId,
    employeeAccountKeyPairPublic,
  } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!employeeAccountKeyPairPublic) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountKeyPairPublic in event`);
  }

  const organizationSecretPrivateSymmetricKey = generateSymmetricKey();

  const encryptedOrganizationSecretPrivateSymmetricKey = await encryptAsymmetric({
    string: organizationSecretPrivateSymmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  const encryptedOrganizationPrivateKey = encryptSymmetric({
    string: organizationPrivateKey,
    key: organizationSecretPrivateSymmetricKey,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueuePromoteAdminApprovePost({
      queueMessageId,
      organizationPrivateKey: encryptedOrganizationPrivateKey,
      organizationSecretPrivateSymmetricKey: encryptedOrganizationSecretPrivateSymmetricKey,
    });
};

export default handlePromoteAdminEvent;
