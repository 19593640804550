import { useMemo } from 'react';
import { GoogleMap, type GoogleMapProps } from '@react-google-maps/api';
import { type TGoogleMapsCoordinates } from '../../../hooks/useGoogleMapsAPI';

interface IGoogleMapProps extends Omit<GoogleMapProps, 'center'> {
  center: TGoogleMapsCoordinates;
  width?: string | number;
  height?: string | number;
  disableDefaultUI?: boolean;
}

const GoogleMapsMap = (props: IGoogleMapProps) => {
  const {
    center,
    width = '100%',
    height = '100%',
    mapContainerStyle: preMapContainerStyle,
    zoom = 16,
    clickableIcons = false,
    disableDefaultUI = true,
    options: preOptions,
    ...rest
  } = props;

  const mapContainerStyle = useMemo(() => ({
    width, height, ...(preMapContainerStyle ?? {}),
  }), [width, height, preMapContainerStyle]);

  const options = useMemo(() => ({
    disableDefaultUI,
    styles: [{
      featureType: 'all',
      stylers: [{ saturation: -100 }],
    }],
    ...(preOptions ?? {}),
  }), [disableDefaultUI, preOptions]);

  if (!center) {
    return null;
  }

  return (
    <GoogleMap
      center={center}
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      clickableIcons={clickableIcons}
      options={options}
      {...rest}
    />
  );
};

export default GoogleMapsMap;
