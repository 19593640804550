import { TUseMutationOptions } from '@uniqkey-frontend/shared-app';
import {
  DeleteBulkVaultToEmployeeAccountRequest, DeleteBulkVaultToEmployeeAccountResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useMutation, useQueryClient } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useVaultsToEmployeeAccountsAPI from '../../useVaultsToEmployeeAccountsAPI';

// TODO: remove this when more methods will be added
// eslint-disable-next-line import/prefer-default-export
export const useDeleteVaultsToEmployeeAccounts = (
  options: TUseMutationOptions<
    DeleteBulkVaultToEmployeeAccountResponse,
    unknown,
    DeleteBulkVaultToEmployeeAccountRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { deleteVaultsToEmployeeAccounts } = useVaultsToEmployeeAccountsAPI();
  return useMutation((
    deleteBulkVaultToEmployeeAccountRequest,
  ) => deleteVaultsToEmployeeAccounts(deleteBulkVaultToEmployeeAccountRequest), {
    onSettled: (data, error, variables) => {
      variables.vaultIds.forEach((vaultId) => {
        queryClient.removeQueries([ReactQueryKeyEnum.Login, vaultId], { exact: true });
      });
    },
    ...options,
  });
};
