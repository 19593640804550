enum DataSynchronizationChangeTypeEnum {
  Vault = 1,
  Employee = 2,
  Group = 4,
  VerifiedDomains = 8,
  SecuritySettings = 16, // 0x00000010
  OrganizationDetails = 32, // 0x00000020
  GroupRestrictions = 64, // 0x00000040
  TrustedAction = 128, // 0x00000080
  CurrentUserChanged = 1048576, // 0x00100000
}

export default DataSynchronizationChangeTypeEnum;
