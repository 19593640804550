import { decryptAsymmetric, encryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type MoveVaultToPrivateKeychainPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handleMoveVaultToPrivateKeychainEvent';

const handleMoveVaultToPrivateKeychainEvent = async (
  params: ITrustedPortalHandlerParams<MoveVaultToPrivateKeychainPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    vaultSymmetricKeyBackup,
    privateEmployeeAccountId,
    employeeAccountKeyPairPublic,
  } = parsedPayload;

  if (!vaultSymmetricKeyBackup) {
    throw new Error(`${ERROR_PREFIX} no vaultSymmetricKeyBackup in event`);
  }

  if (!privateEmployeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no privateEmployeeAccountId in event`);
  }

  if (!employeeAccountKeyPairPublic) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountKeyPairPublic in event`);
  }

  const decryptedSymmetricKey = await decryptAsymmetric({
    cipher: vaultSymmetricKeyBackup,
    publicKey: organizationPublicKey,
    privateKey: organizationPrivateKey,
  });

  const encryptedSymmetricKey = await encryptAsymmetric({
    string: decryptedSymmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  const privateKeyBackupSymmetricKey = await encryptAsymmetric({
    string: decryptedSymmetricKey,
    publicKey: organizationPublicKey,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueMoveVaultToPrivateKeychainApprovePost({
      queueMessageId,
      vaultSymmetricKey: encryptedSymmetricKey,
      vaultSymmetricKeyByOrganizationId: privateKeyBackupSymmetricKey,
    });
};

export default handleMoveVaultToPrivateKeychainEvent;
