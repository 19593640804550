import { memo, useMemo } from 'react';
import { MarkerF, type MarkerProps } from '@react-google-maps/api';
import { type TGoogleMapsCoordinates } from '../../../hooks/useGoogleMapsAPI';
import markerSVG from './marker.svg';

interface IMarkerProps extends Omit<MarkerProps, 'position'> {
  position: TGoogleMapsCoordinates;
}

const GoogleMapsMarker = (props: IMarkerProps) => {
  const { position, clickable = false, ...rest } = props;

  const icon = useMemo(() => ({
    url: markerSVG,
    // center the icon, its width and height are set to 40px
    anchor: new window.google.maps.Point(20, 20),
  }), []);

  if (!position) {
    return null;
  }

  return (
    <MarkerF
      position={position}
      clickable={clickable}
      icon={icon}
      {...rest}
    />
  );
};

export default memo(GoogleMapsMarker);
