/* eslint-disable max-len */
import { throttle } from 'lodash';
import PubSub from 'pubsub-js';
import DataSynchronizationChangeTypeEnum from '../enums/DataSynchronizationChangeTypeEnum';
import PubSubEventEnum from '../enums/PubSubEventEnum';

export interface IOrganizationPortalSynchronizationPayload {
  changeType: DataSynchronizationChangeTypeEnum;
}

export const hasDataSynchronizationChangeType = (
  currChangeType: DataSynchronizationChangeTypeEnum,
  changeTypeToCompare: DataSynchronizationChangeTypeEnum,
// eslint-disable-next-line no-bitwise
): boolean => (currChangeType & changeTypeToCompare) > 0;

const [
  throttledPublishEmployeeUpdate,
  throttledPublishGroupUpdate,
  throttledPublishVaultUpdate,
  throttledPublishVerifiedDomains,
  throttledPublishSecuritySettings,
  throttledPublishOrganizationDetails,
  throttledPublishGroupRestrictions,
  throttledPublishCurrentUserUpdate,
] = [
  PubSubEventEnum.DATA_SYNCHRONIZATION_EMPLOYEE,
  PubSubEventEnum.DATA_SYNCHRONIZATION_GROUP,
  PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT,
  PubSubEventEnum.DATA_SYNCHRONIZATION_VERIFIED_DOMAINS,
  PubSubEventEnum.DATA_SYNCHRONIZATION_SECURITY_SETTINGS,
  PubSubEventEnum.DATA_SYNCHRONIZATION_ORGANIZATION_DETAILS,
  PubSubEventEnum.DATA_SYNCHRONIZATION_GROUP_RESTRICTIONS,
  PubSubEventEnum.DATA_SYNCHRONIZATION_CURRENT_USER,
].map((event) => throttle(() => {
  PubSub.publish(event);
}, 1000));

export const handleDataSynchronization = (payload: IOrganizationPortalSynchronizationPayload) => {
  const { changeType } = payload;
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.Employee)) {
    throttledPublishEmployeeUpdate();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.Group)) {
    throttledPublishGroupUpdate();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.Vault)) {
    throttledPublishVaultUpdate();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.VerifiedDomains)) {
    throttledPublishVerifiedDomains();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.SecuritySettings)) {
    throttledPublishSecuritySettings();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.OrganizationDetails)) {
    throttledPublishOrganizationDetails();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.GroupRestrictions)) {
    throttledPublishGroupRestrictions();
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.CurrentUserChanged)) {
    throttledPublishCurrentUserUpdate();
  }
};
