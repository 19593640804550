import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  ApplicationGetResponse,
  ApplicationGetForGroupResponse,
  ApplicationGetByIdResponse,
  ApplicationGetListResponse,
  GetDomainRulesAndDesktopApplicationsByApplicationIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import {
  TGetServicesListMethod,
  TGetServicesListForGroupMethod,
  TGetApplicationsMethod,
} from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useApplicationsAPI = () => {
  const { applicationsAPIClient } = useApiClients();

  const getServicesList = useCallback<TGetServicesListMethod>(
    (params) => {
      const {
        page,
        pageLength,
        minStrength = undefined,
        maxStrength = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        signal = undefined,
        cancelToken = undefined,
      } = params;
      return applicationsAPIClient.apiV1ApplicationsGetServiceListGet(
        page,
        pageLength,
        minStrength,
        maxStrength,
        searchQuery,
        orderPropertyName,
        isDescending,
        { cancelToken, signal },
      ).then(dataExtractor<ApplicationGetResponse>());
    },
    [applicationsAPIClient],
  );

  const getServicesListForGroup = useCallback<TGetServicesListForGroupMethod>(
    (params) => {
      const {
        page,
        pageLength,
        groupId,
        minStrength = undefined,
        maxStrength = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return applicationsAPIClient.apiV1ApplicationsGetServiceListForGroupGet(
        page,
        pageLength,
        groupId,
        minStrength,
        maxStrength,
        searchQuery,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<ApplicationGetForGroupResponse>());
    },
    [applicationsAPIClient],
  );

  const getApplicationById = useCallback((applicationId: string, options?: AxiosRequestConfig)
    :Promise<ApplicationGetByIdResponse> => applicationsAPIClient
    .apiV1ApplicationsApplicationIdGet(applicationId, options)
    .then(dataExtractor<ApplicationGetByIdResponse>()), [applicationsAPIClient]);

  const getApplications = useCallback<TGetApplicationsMethod>((params) => {
    const {
      page,
      pageLength,
      searchQuery = undefined,
      cancelToken = undefined,
    } = params;
    return applicationsAPIClient.apiV1ApplicationsGet(
      page,
      pageLength,
      searchQuery,
      { cancelToken },
    ).then(dataExtractor<ApplicationGetListResponse>());
  }, [applicationsAPIClient]);

  const getServicesByApplicationId = useCallback(
    (applicationId: string, options?: AxiosRequestConfig)
    :Promise<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse> => applicationsAPIClient
      .apiV1ApplicationsGetDomainRulesAndDesktopApplicationsByApplicationIdApplicationIdGet(
        applicationId,
        options,
      )
      .then(dataExtractor<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse>()),
    [applicationsAPIClient],
  );

  return ({
    getServicesList,
    getServicesListForGroup,
    getApplicationById,
    getApplications,
    getServicesByApplicationId,
  });
};

export default useApplicationsAPI;
