import { useCallback, useState, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  PanelContent,
  Select,
  Tooltip,
  useSnackbar,
  useTranslations,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetOrganizations } from '../../hooks/reactQuery/useMigrationAPI';
import MigrationPanelHeader from '../../components/MigrationPanel/components/MigrationPanelHeader';
import MigrationPanel from '../../components/MigrationPanel';
import MigrationPanelContent
  from '../../components/MigrationPanel/components/MigrationPanelContent';
import MigrationPageRouteEnum from '../../enums/MigrationPageRouteEnum';

const TOOLTIP_SX = { width: '100%' };

const OrganizationsPage = () => {
  const [organizationID, setOrganizationID] = useState<string>('');
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { data, isLoading } = useGetOrganizations({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const { organizations = [] } = data ?? {};
  const organizationsCount = useMemo(() => organizations.length, [organizations]);
  const isMultipleOrganizations = useMemo(() => organizationsCount > 1, [organizationsCount]);
  const isButtonDisabled = useMemo(
    () => (isMultipleOrganizations ? !organizationID : organizations[0]?.isAlreadyMigrated),
    [isMultipleOrganizations, organizationID, organizations],
  );
  const areAllOrganizationsMigrated = useMemo(() => (
    isMultipleOrganizations
      ? organizations?.every((org) => org.isAlreadyMigrated)
      : organizations[0]?.isAlreadyMigrated
  ), [isMultipleOrganizations, organizations]);
  const menuItems = useMemo(() => organizations.map((org) => (
    <MenuItem key={org.id} value={org.id} disabled={org.isAlreadyMigrated}>
      {
        org.isAlreadyMigrated
          ? `${org.name} (${t('migration.organizationsPage.migrated')})`
          : org.name
      }
    </MenuItem>
  )), [organizations, t]);

  const handleOrganizationChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => setOrganizationID(event.target.value as string), []);
  const handleSubmit = useCallback(() => {
    let locationState;
    if (isMultipleOrganizations) {
      locationState = organizations.find((org) => org.id === organizationID);
    } else {
      // eslint-disable-next-line prefer-destructuring
      locationState = organizations[0];
    }
    navigate(MigrationPageRouteEnum.Report, { state: locationState });
  }, [organizationID, organizations, isMultipleOrganizations, navigate]);

  if (isLoading) {
    return (
      <PanelContent p={3} className="height-100-percent">
        <Grid container alignItems="center" justifyContent="center" height="inherit">
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  return (
    <MigrationPanel>
      <MigrationPanelHeader
        title={t('migration.organizationsPage.header', { length: organizationsCount })}
      />
      <MigrationPanelContent>
        <Grid item container justifyContent="center" textAlign="center" rowSpacing={2} mt={3}>
          {isMultipleOrganizations ? (
            <>
              <Grid item xs={8}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {t('migration.organizationsPage.selectOrganizationForMigration')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  fullWidth
                  value={organizationID}
                  label={t('migration.organizationsPage.selectOrganization')}
                  onChange={handleOrganizationChange}
                >
                  {menuItems}
                </Select>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {t('migration.organizationsPage.youAreTheAdmin')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {organizations[0].name}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={8}>
            <Typography variant="body1">
              {t('migration.organizationsPage.longerDescription')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              {t('migration.organizationsPage.clickNext')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center" mt={3}>
          <Grid item xs={4}>
            <Tooltip
              boxSX={TOOLTIP_SX}
              title={areAllOrganizationsMigrated
                ? t('migration.organizationsPage.allOrganizationsMigrated')
                : ''}
            >
              <Button fullWidth disabled={isButtonDisabled} onClick={handleSubmit}>
                {t('migration.organizationsPage.next')}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </MigrationPanelContent>
    </MigrationPanel>
  );
};

export default OrganizationsPage;
