import {
  type VaultSecureNoteCreationRequestedPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultSecureNoteCreation from '../common/handleAdminVaultSecureNoteCreation';

const ERROR_PREFIX = 'TrustedPortal/handleVaultSecureNoteCreationRequestedEvent';

const handleVaultSecureNoteCreationRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultSecureNoteCreationRequestedPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { note } = parsedPayload;

  const {
    encryptedNote,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultSecureNoteCreation({
    organizationPublicKey,
    note,
    errorPrefix: ERROR_PREFIX,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueVaultSecureNoteCreationRequestedApprovePost({
      queueMessageId,
      note: encryptedNote,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleVaultSecureNoteCreationRequestedEvent;
