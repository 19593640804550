import { useMemo, memo } from 'react';
import {
  IUseTableReturn, Table, TypographyWithTooltip, useTableLocalization, useTranslations,
} from '@uniqkey-frontend/shared-app';
import { noop } from 'lodash';
import {
  IInviteEmployee,
} from '../../../pages/EmployeesPage/components/InviteEmployeesModal/interfaces';

interface IEmployeesToInviteTableProps {
  users: IInviteEmployee[];
  selectedUsers: IUseTableReturn<IInviteEmployee>['selectedRows'];
  createColumns: IUseTableReturn<IInviteEmployee>['createColumns'];
  onRowSelectionChange: IUseTableReturn<IInviteEmployee>['onRowSelectionChange'];
}

const TABLE_OPTIONS = {
  selection: true,
};

const EmployeesToInviteTable = (props: IEmployeesToInviteTableProps) => {
  const {
    users,
    selectedUsers,
    createColumns,
    onRowSelectionChange,
  } = props;

  const { t } = useTranslations();
  const { localization } = useTableLocalization({
    noDataMessageKey: 'inviteEmployeesModal.noData',
  });
  const columns = useMemo(() => createColumns([
    {
      title: t('inviteEmployeesModal.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
    },
    {
      title: t('inviteEmployeesModal.name'),
      field: 'name',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      columns={columns}
      data={users}
      totalItems={users.length}
      perPage={users.length}
      options={TABLE_OPTIONS}
      activePage={0}
      selectedRows={selectedUsers}
      rowSelectByKey="id"
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      hidePagination
      onPerPageChange={noop}
      onColumnOrderDirectionChange={noop}
      onActivePageChange={noop}
      onColumnOrderByChange={noop}
      rowHeight={51}
    />
  );
};

export default memo(EmployeesToInviteTable);
