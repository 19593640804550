import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  useTablePreserverDecoderCustomConfigMerger,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TUseTablePreserverDecoderCustomConfig,
  TSetSearchParams,
  TSecurityScoreName,
  getSecurityScoreStrength,
} from '@uniqkey-frontend/shared-app';
import {
  GetVaultPasswordsResponseModel,
  VaultOrderProperty,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetVaultPasswordsParams,
  TGetVaultPasswordsMethod,
} from '../../useVaultsAPI/interfaces';
import {
  IEmployeeLoginsTabFilterSubmitResult,
// eslint-disable-next-line max-len
} from '../../../pages/EmployeePage/components/EmployeeLoginsTab/components/EmployeeLoginsTabFilter';
import {
  IGroupLoginsTabFilterSubmitResult,
} from '../../../pages/GroupPage/components/GroupLoginsTab/components/GroupLoginsTabFilter';
import useVaultsAPI from '../../useVaultsAPI';
import tablePreservationConfigs from '../../../helpers/tablePreservation/configs';

interface IPersistentFilters extends Pick<
  IGetVaultPasswordsParams, 'groupId' | 'employeeAccountId'
> {}

export interface IUseEmployeeGroupLoginsTableProps {
  persistentFilters?: IPersistentFilters;
  customPreservationConfig?: ICustomPreservationConfig;
  noDataMessageKey: string;
}

export interface IEmployeeGroupLoginsTableRow extends GetVaultPasswordsResponseModel {
  lastActivity: string | null;
}

export interface IUseEmployeeGroupLoginsTableReturn extends
  Omit<IUseTableReturn<IEmployeeGroupLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseTableFetchReturn<GetVaultPasswordsResponseModel>, 'data'>,
  IUseFilterButtonReturn
{
  logins: IUseTableFetchReturn<GetVaultPasswordsResponseModel>['data'];
  selectedLogins: IUseTableReturn<IEmployeeGroupLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetVaultPasswordsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetVaultPasswordsParams['searchQuery']>>;
  filterValues: IEmployeeLoginsTabFilterSubmitResult | IGroupLoginsTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<
    IEmployeeLoginsTabFilterSubmitResult | IGroupLoginsTabFilterSubmitResult
  >>;
}

interface ITablePreservationConfigs extends
  Omit<IGetVaultPasswordsParams,
    'page' | 'pageLength' | 'groupId' | 'employeeAccountId' | 'orderPropertyName' | 'isDescending'
    | 'minStrength' | 'maxStrength' | 'cancelToken'
  >,
  Pick<IUseTableReturn<IEmployeeGroupLoginsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetVaultPasswordsParams['page'];
  perPage: IGetVaultPasswordsParams['pageLength'];
  securityScoreName: TSecurityScoreName;
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: VaultOrderProperty },
  ),
  columnOrderDirection: true,
  securityScoreName: true,
  ownership: tablePreservationConfigs.ownershipWithoutUnmanaged,
  applicationName: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.STRING,
  ),
};

interface ICustomPreservationConfig extends TUseTablePreserverDecoderCustomConfig<
  ITablePreservationConfigs, 'ownership'
> {}

const useEmployeeGroupLoginsTable = (
  props: IUseEmployeeGroupLoginsTableProps,
): IUseEmployeeGroupLoginsTableReturn => {
  const {
    persistentFilters,
    customPreservationConfig,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVaultPasswords } = useVaultsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const preservationConfig = useTablePreserverDecoderCustomConfigMerger<
    ITablePreservationConfigs, ICustomPreservationConfig
  >(PRESERVATION_CONFIGS, customPreservationConfig);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
    securityScoreName: initialSecurityScoreName,
    ownership: initialOwnership,
    applicationName: initialApplicationName,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, preservationConfig);

  const [searchQuery, setSearchQuery] = useState<
    IGetVaultPasswordsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IEmployeeLoginsTabFilterSubmitResult | IGroupLoginsTabFilterSubmitResult
  >({
    ownership: initialOwnership,
    applicationName: initialApplicationName,
    securityScoreName: initialSecurityScoreName,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedLogins,
    resetTableMethods,
    ...restTableProps
  } = useTable<IEmployeeGroupLoginsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      ownership: undefined,
      applicationName: undefined,
      securityScoreName: TablePreserverCommonInitialTableValues.securityScoreName,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: logins, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetVaultPasswordsParams,
    TGetVaultPasswordsMethod,
    GetVaultPasswordsResponseModel
  >({
    request: getVaultPasswords,
    params: {
      page: activePage,
      pageLength: perPage,
      ownership: (filterValues as IEmployeeLoginsTabFilterSubmitResult).ownership,
      applicationName: filterValues.applicationName,
      ...getSecurityScoreStrength(filterValues.securityScoreName),
      searchQuery,
      orderPropertyName: VaultOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof VaultOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    ownership: (filterValues as IEmployeeLoginsTabFilterSubmitResult).ownership,
    applicationName: filterValues.applicationName,
    securityScoreName: filterValues.securityScoreName,
  });

  return {
    logins,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedLogins,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useEmployeeGroupLoginsTable;
