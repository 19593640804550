import PubSub from 'pubsub-js';
import {
  type TrustedPortalEventCreatedNotification,
} from '@uniqkey-backend-organization-web/api-client';
import API from './api';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import TrustedPortalPubSubEventEnum from './enums/PubSubEventEnum';
import TrustedPortalRealtimeAPIEventTypeEnum from './enums/RealtimeAPIEventTypeEnum';
import DataSynchronizationChangeTypeEnum from '../../enums/DataSynchronizationChangeTypeEnum';
import { type ITrustedPortalStore } from './store';
import {
  hasDataSynchronizationChangeType,
  type IOrganizationPortalSynchronizationPayload,
} from '../../helpers/dataSynchronization';
import { buildPubSubTopic, parsePubSubTopic } from '../../helpers/PubSub';
import { getUserTokens } from '../../services/authService';
import { subscribeToRealtimeAPIEvent } from '../../services/webSocketsManager';
import { getSymmetricKey } from '../../services/companionApplicationService';
import { logException } from '../../services/sentryService';
import Axios from './axios';
import Helpers from './helpers';
import WorkerManager from './managers/WorkerManager';
import TrustedPortalStoreActions from './store/actions';

const LOG_COLOR = 'darkblue';

const fetchAndNormalizeTrustedActions = async () => {
  const [
    newTrustedPortalActions,
    organizationsPublicKeys,
  ] = await Promise.all([
    API.getAllTrustedPortalActions(),
    API.getAllOrganizationPublicKeys(),
  ]);
  const symmetricKey = getSymmetricKey();
  if (!symmetricKey) {
    throw new Error('No symmetric key found');
  }
  return Helpers.normalizeTrustedActions(
    newTrustedPortalActions,
    symmetricKey,
    organizationsPublicKeys,
  );
};

const initializeTrustedPortal = async () => {
  try {
    if (!getUserTokens()) {
      WorkerManager.destroyAll();
      return;
    }
    const normalizedTrustedPortalActions = await fetchAndNormalizeTrustedActions();
    WorkerManager.destroy(normalizedTrustedPortalActions.organizationIdsToDestroy);
    WorkerManager.create(
      normalizedTrustedPortalActions.organizationIdsToCreate,
      normalizedTrustedPortalActions.byOrganizationId,
    );
  } catch (e) {
    logException(e, { message: 'TrustedPortalModule/initializeTrustedPortal exception' });
  }
};

const resetTrustedPortal = (
  initialStoreOverrides: Partial<ITrustedPortalStore> = { isInitialized: true },
) => {
  WorkerManager.destroyAll();
  TrustedPortalStoreActions.resetStore(initialStoreOverrides);
};

const listenEvents = () => {
  subscribeToRealtimeAPIEvent<IOrganizationPortalSynchronizationPayload>(
    RealtimeAPIEventTypeEnum.OrganizationPortalSynchronization,
    async (payload) => {
      const { changeType } = payload;
      if (hasDataSynchronizationChangeType(
        changeType,
        DataSynchronizationChangeTypeEnum.TrustedAction,
      )) {
        await initializeTrustedPortal();
      }
    },
  );

  subscribeToRealtimeAPIEvent<TrustedPortalEventCreatedNotification>(
    TrustedPortalRealtimeAPIEventTypeEnum.TrustedPortalEventCreatedNotification,
    (payload) => {
      const { organizationId } = payload;
      if (!organizationId) {
        return;
      }

      Helpers.tpLogMessage({
        messages: [
          '[MODULE] receive TrustedPortalEventCreatedNotification',
          `\n\torganizationId: ${organizationId}`,
        ],
        color: LOG_COLOR,
      });

      if (!TrustedPortalStoreActions.getIsEnabledByOrganizationId(organizationId)) {
        // eslint-disable-next-line no-console
        console.warn(
          'TrustedPortalModule/TrustedPortalEventCreatedNotification on disabled organization',
          organizationId,
        );
        return;
      }

      if (TrustedPortalStoreActions.getIsProcessingByOrganizationId(organizationId)) {
        return;
      }

      Helpers.tpLogMessage({
        message: '[MODULE] publish TRUSTED_PORTAL_PROVIDE_EVENT',
        color: LOG_COLOR,
      });
      PubSub.publish(
        buildPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT, organizationId),
      );
    },
  );

  PubSub.subscribe(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT, (message) => {
    const [, organizationId] = parsePubSubTopic(message);
    if (!TrustedPortalStoreActions.getIsEnabledByOrganizationId(organizationId)) {
      // eslint-disable-next-line no-console
      console.warn(
        'TrustedPortalModule/TRUSTED_PORTAL_PROVIDE_EVENT on disabled organization',
        organizationId,
      );
      return;
    }
    Helpers.tpLogMessage({
      messages: [
        '[MODULE]',
        'receive TRUSTED_PORTAL_PROVIDE_EVENT',
        `\n\torganizationId: ${organizationId}`,
      ],
      color: LOG_COLOR,
    });
    TrustedPortalStoreActions.setIsProcessingByOrganizationId(organizationId, true);
  });

  PubSub.subscribe(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_NO_EVENTS, (message) => {
    const [, organizationId] = parsePubSubTopic(message);
    const worker = WorkerManager.getById(organizationId);
    if (!worker) {
      return;
    }
    const isExecuting = worker.isExecuting();
    Helpers.tpLogMessage({
      messages: [
        '[MODULE]',
        'receive TRUSTED_PORTAL_NO_EVENTS',
        `\n\torganizationId      : ${organizationId}`,
        `\n\tis worker executing : ${isExecuting}`,
      ],
      color: LOG_COLOR,
    });
    if (isExecuting) {
      return;
    }
    TrustedPortalStoreActions.setIsProcessingByOrganizationId(organizationId, false);
  });
};

const initModule = async () => {
  if (TrustedPortalStoreActions.getIsInitialized()) {
    return;
  }

  Axios.attachTrustedPortalAxiosInterceptor();

  await initializeTrustedPortal();

  listenEvents();

  TrustedPortalStoreActions.setIsInitialized(true);
};

export default {
  initModule,
  resetTrustedPortal,
};
