import { useCallback } from 'react';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
  IUseTableResetTableMethodsReturn,
} from '@uniqkey-frontend/shared-app';
import { GetVerifiedDomainsResponseModel } from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetVerifiedDomainsParams,
  TGetVerifiedDomainsMethod,
} from '../../useVerifiedDomainsAPI/interfaces';
import { IVerifiedDomainsTableRow } from '../../../components/tables/VerifiedDomainsTable';
import useVerifiedDomainsAPI from '../../useVerifiedDomainsAPI';

export interface IUseVerifiedDomainsTableProps {
  noDataMessageKey: string;
}

export interface IUseVerifiedDomainsTableReturn extends
  Omit<
    IUseTableReturn<IVerifiedDomainsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseTableFetchReturn<GetVerifiedDomainsResponseModel>, 'data'>
{
  verifiedDomains: IUseTableFetchReturn<GetVerifiedDomainsResponseModel>['data'];
  selectedVerifiedDomains: IUseTableReturn<IVerifiedDomainsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetVerifiedDomainsParams, 'page' | 'pageLength' | 'cancelToken'>
{
  activePage: IGetVerifiedDomainsParams['page'];
  perPage: IGetVerifiedDomainsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useVerifiedDomainsTable = (
  props: IUseVerifiedDomainsTableProps,
): IUseVerifiedDomainsTableReturn => {
  const {
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVerifiedDomains } = useVerifiedDomainsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedVerifiedDomains,
    resetTableMethods,
    ...restTableProps
  } = useTable<IVerifiedDomainsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: verifiedDomains, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetVerifiedDomainsParams,
    TGetVerifiedDomainsMethod,
    GetVerifiedDomainsResponseModel
  >({
    request: getVerifiedDomains,
    params: {
      page: activePage,
      pageLength: perPage,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    verifiedDomains,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    selectedVerifiedDomains,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useVerifiedDomainsTable;
