import { type AxiosInstance } from 'axios';
import {
  TrustedActionsApi,
  QueueApi,
  OrganizationKeysApi,
} from '@uniqkey-backend-organization-web/api-client';
import config from '../../../../config';

const API_BASE_URL = config.getWebApiUrl();

const getTrustedActionsAPIClient = (instance: AxiosInstance) => new TrustedActionsApi(
  undefined,
  API_BASE_URL,
  instance,
);

const getOrganizationKeysAPIClient = (instance: AxiosInstance) => new OrganizationKeysApi(
  undefined,
  API_BASE_URL,
  instance,
);

const getQueueAPIClient = (instance: AxiosInstance) => new QueueApi(
  undefined,
  API_BASE_URL,
  instance,
);

export default {
  getTrustedActionsAPIClient,
  getOrganizationKeysAPIClient,
  getQueueAPIClient,
};
