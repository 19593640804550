enum GroupPolicyEnum {
  IsExportAllowed = 'isExportAllowed',
  CanManageVaults = 'canManageVaults',
  TrustedBrowserAllowed = 'trustedBrowserAllowed',
  CanView = 'canView',
  CanCopy = 'canCopy',
  CanAdd = 'canAdd',
  CanEdit = 'canEdit',
  CanDelete = 'canDelete',
}

export default GroupPolicyEnum;
