import { ChangeEvent, memo } from 'react';
import { EmployeeAccountStatus } from '@uniqkey-backend-organization-web/api-client';
import {
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTranslations,
} from '@uniqkey-frontend/shared-app';

interface IInviteEmployeesRadioGroupProps {
  onRadioGroupChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const LABEL_SX = {
  marginRight: 0,
};

const InviteEmployeesRadioGroup = (props: IInviteEmployeesRadioGroupProps) => {
  const { onRadioGroupChange } = props;
  const { t } = useTranslations();
  return (
    <RadioGroup onChange={onRadioGroupChange}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <FormControlLabel
            value={EmployeeAccountStatus.Staged}
            control={<Radio />}
            label={t('inviteEmployeesModal.addWithoutInvite')}
            sx={LABEL_SX}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            value={EmployeeAccountStatus.Invited}
            control={<Radio />}
            label={t('inviteEmployeesModal.addWithInvite')}
            sx={LABEL_SX}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default memo(InviteEmployeesRadioGroup);
