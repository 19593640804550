import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { NoContentResult, TrustedActionType } from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { getCompanionApplicationId } from '../../services/companionApplicationService';
import { getActiveOrganizationId } from '../../services/organizationService';

const useTrustedActionsAPI = () => {
  const { trustedActionsAPIClient } = useApiClients();

  const deleteTrustedAction = useCallback((
    trustedActionType: TrustedActionType,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => {
    const companionApplicationId = getCompanionApplicationId();
    const organizationId = getActiveOrganizationId();
    if (!companionApplicationId) {
      throw new Error('No companion application id found');
    }
    if (!organizationId) {
      throw new Error('No organization id found');
    }
    return trustedActionsAPIClient
      .apiV1TrustedActionsCompanionApplicationIdTrustedActionTypeOrganizationIdDelete(
        companionApplicationId,
        trustedActionType,
        organizationId,
        options,
      ).then(dataExtractor<NoContentResult>());
  }, [trustedActionsAPIClient]);

  return { deleteTrustedAction };
};

export default useTrustedActionsAPI;
