import {
  useState, useCallback, useMemo, SyntheticEvent,
} from 'react';
import {
  Typography, useTranslations, Grid, AB3, Button, useSnackbar, PanelContent, CircularProgress, S5,
} from '@uniqkey-frontend/shared-app';
import {
  useLocation, Link, useNavigate,
} from 'react-router-dom';
import { GetOrganizationsResponseModel } from '@uniqkey-backend-organization-web/api-client';
import MigrationPanel from '../../components/MigrationPanel';
import MigrationImage from '../../images/MigrationImage';
import MigrationPanelHeader from '../../components/MigrationPanel/components/MigrationPanelHeader';
import MigrationPanelContent
  from '../../components/MigrationPanel/components/MigrationPanelContent';
import InstallMobileAppModal from './components/InstallMobileAppModal';
import MainPageRouteEnum from '../../../enums/PageRouteEnum';
import MigrationPageRouteEnum from '../../enums/MigrationPageRouteEnum';
import { useGetOrganizations } from '../../hooks/reactQuery/useMigrationAPI';
import { logout } from '../../services/authService';

const CongratsPage = () => {
  const [isInstallMobileModalOpen, setIsInstallMobileModalOpen] = useState<boolean>(false);
  const { state: locationsState } = useLocation();
  const { name } = locationsState as GetOrganizationsResponseModel;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { data, isLoading } = useGetOrganizations({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const { organizations = [] } = data ?? {};
  const notMigratedOrganizationsCount = useMemo(() => organizations.filter(
    (org) => !org.isAlreadyMigrated,
  ).length, [organizations]);

  const toggleInstallMobileAppModal = useCallback(
    () => setIsInstallMobileModalOpen((isOpen) => !isOpen),
    [],
  );
  const handleLinkClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    toggleInstallMobileAppModal();
  }, [toggleInstallMobileAppModal]);

  const handleClose = useCallback(() => {
    navigate(MainPageRouteEnum.Login);
    logout();
    window.location.reload();
  }, [navigate]);

  const runNewMigrationClick = useCallback(() => {
    navigate(MigrationPageRouteEnum.Organizations);
  }, [navigate]);

  if (isLoading) {
    return (
      <PanelContent p={3} className="height-100-percent">
        <Grid container alignItems="center" justifyContent="center" height="inherit">
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  return (
    <MigrationPanel>
      <MigrationPanelHeader
        title={t('migration.congratsPage.header')}
      />
      <MigrationPanelContent>
        <MigrationImage />
        <Grid item container justifyContent="center" textAlign="center" rowSpacing={2} mt={3}>
          <Grid item xs={9}>
            <Typography variant="body1">
              {t('migration.congratsPage.migrationCompleted', { name })}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" fontWeight={700}>
              {t('migration.congratsPage.dontHaveUniqkey')}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" component="span">
              {t('migration.congratsPage.click')}
            </Typography>
            <span>&nbsp;</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="#" color={AB3} onClick={handleLinkClick}>
              <Typography variant="caption" component="span">
                {t('migration.congratsPage.installUniqkey')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" textAlign="center" rowSpacing={2} mt={3}>
          <Grid item xs={3.5}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              {t('migration.congratsPage.close')}
            </Button>
          </Grid>
          {!!notMigratedOrganizationsCount && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" color={S5}>
                  {t('migration.congratsPage.thereHaveLeft', {
                    count: notMigratedOrganizationsCount,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="text" onClick={runNewMigrationClick}>
                  {t('migration.congratsPage.runNewMigration')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </MigrationPanelContent>
      <InstallMobileAppModal
        handleToggle={toggleInstallMobileAppModal}
        isModalOpen={isInstallMobileModalOpen}
      />
    </MigrationPanel>
  );
};

export default CongratsPage;
