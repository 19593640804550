import { useMemo, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { useGetEmployeeAccountById } from '../../../../hooks/reactQuery';

interface IEmployeePageBreadcrumbsProps {
  employeeAccountId: string;
}

const EmployeePageBreadcrumbs = (props: IEmployeePageBreadcrumbsProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const { data: employeeAccount, isLoading, refetch } = useGetEmployeeAccountById(
    { employeeAccountId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.Employees);
      },
    },
  );

  const handleRefetch = useCallback(() => refetch(), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_EMPLOYEE, handleRefetch);

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !employeeAccount) {
      return [];
    }
    return [
      { title: t('navigation.employees'), onClick: () => navigate(PageRouteEnum.Employees) },
      { title: employeeAccount.email },
    ];
  }, [isLoading, employeeAccount, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(EmployeePageBreadcrumbs);
