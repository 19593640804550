import {
  memo, useState, useCallback, useMemo,
} from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  SearchableTextField,
  useTranslations,
  Box,
  Button,
  PlusIcon,
  Tooltip,
  ActionButton,
  ShareIcon,
  ErrorIcon,
  PromptModal,
  usePubSub,
  useMapKeyValueExtractor,
  useSnackbar,
  encryptSymmetric,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import useEmployeeGroupCreditCardsTable, { IEmployeeGroupCreditCardsTableRow } from
  '../../../../hooks/tables/useEmployeeGroupCreditCardsTable';
import GroupCreditCardsTable from '../../../../components/tables/GroupCreditCardsTable';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { useDeleteVaultsToGroups, useGetGroupById } from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import useVaultsToGroupsAPI from '../../../../hooks/useVaultsToGroupsAPI';
import CreateCreditCardModal, {
  ICreateCreditCardReturnValue,
} from '../../../../components/CreateCreditCardModal';
import { generateTooltipTitle } from '../../../../helpers/tooltips';

interface IGroupCreditCardsTabProps {
  groupId: string;
}

const GroupCreditCardsTab = (props: IGroupCreditCardsTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const [isDeleteCreditCardLoading, setIsDeleteCreditCardLoading] = useState(false);
  const [isDeleteCreditCardModalOpen, setIsDeleteCreditCardModalOpen] = useState<boolean>(false);
  const [isShareCreditCardsModalOpen, setIsShareCreditCardsModalOpen] = useState<boolean>(false);
  const [isCreateCreditCardModalOpen, setIsCreateCreditCardModalOpen] = useState<boolean>(false);
  const [isCreateCreditCardLoading, setIsCreateCreditCardLoading] = useState<boolean>(false);

  const handleDeleteCreditCardModalOpen = useCallback(
    () => setIsDeleteCreditCardModalOpen(true),
    [],
  );
  const handleDeleteCreditCardModalClose = useCallback(
    () => setIsDeleteCreditCardModalOpen(false),
    [],
  );
  const handleShareCreditCardsModalOpen = useCallback(
    () => setIsShareCreditCardsModalOpen(true),
    [],
  );
  const handleShareCreditCardsModalClose = useCallback(
    () => setIsShareCreditCardsModalOpen(false),
    [],
  );
  const handleCreateCreditCardModalOpen = useCallback(
    () => setIsCreateCreditCardModalOpen(true),
    [],
  );
  const handleCreateCreditCardModalClose = useCallback(
    () => setIsCreateCreditCardModalOpen(false),
    [],
  );

  const {
    selectedCreditCards,
    refetch,
    resetSelectedRows,
    resetActivePage,
    searchQuery,
    setSearchQuery,
    ...restTableProps
  } = useEmployeeGroupCreditCardsTable({
    persistentFilters: { groupId },
    customPreservationConfig: { ownership: false },
    noDataMessageKey: 'creditCardsTab.table.noData',
  });
  const { data: group } = useGetGroupById(
    { groupId },
  );

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT, handleRefetch);

  const { showError, showSuccess, showWarning } = useSnackbar();
  const { symmetricKey } = useCompanionApplicationContext();
  const { createVaultCreditCardForGroup } = useVaultsToGroupsAPI();
  const { mutate: mutateDeleteCreditCard } = useDeleteVaultsToGroups();
  const {
    values: selectedCreditCardsAsObjects, keys: selectedCreditCardsIds,
  } = useMapKeyValueExtractor<IEmployeeGroupCreditCardsTableRow>(selectedCreditCards);

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleDeleteCreditCard = useCallback(async () => {
    setIsDeleteCreditCardLoading(true);
    mutateDeleteCreditCard({
      vaultIds: selectedCreditCardsIds, groupId,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteCreditCardModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteCreditCardModal.errorMessage', { count: failCount }),
          });
        }
        handleDeleteCreditCardModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t('common.somethingWentWrong') });
        logException(e, { message: 'GroupCreditCardsTab/handleDeleteCreditCard exception' });
      },
      onSettled: () => setIsDeleteCreditCardLoading(false),
    });
  }, [
    groupId,
    selectedCreditCardsIds,
    mutateDeleteCreditCard,
    handleDeleteCreditCardModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleCreateCreditCard = useCallback(async (params: ICreateCreditCardReturnValue) => {
    const {
      name, cardNumber, expiryDate, cvv, cardHolderName,
    } = params;
    setIsCreateCreditCardLoading(true);
    try {
      await createVaultCreditCardForGroup({
        groupId,
        name,
        cardNumber: encryptSymmetric({
          key: symmetricKey,
          string: cardNumber,
        }),
        expiryDate: encryptSymmetric({
          key: symmetricKey,
          string: expiryDate,
          fallbackValue: '',
        }),
        cardHolderName: encryptSymmetric({
          key: symmetricKey,
          string: cardHolderName,
          fallbackValue: '',
        }),
        cvv: encryptSymmetric({
          key: symmetricKey,
          string: cvv,
          fallbackValue: '',
        }),
      });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.creditCard.created'),
        });
      } else {
        showWarning({
          text: t('createCreditCardModal.approvalOnMobileToast'),
        });
      }
      handleCreateCreditCardModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupCreditCardsTab/handleCreateCreditCard exception',
      });
    } finally {
      setIsCreateCreditCardLoading(false);
    }
  }, [
    createVaultCreditCardForGroup,
    handleCreateCreditCardModalClose,
    groupId,
    symmetricKey,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const { shareTooltipTitle, removeTooltipTitle } = useMemo(() => {
    const shareTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCards.size,
      t,
      isDisabled: false,
      key: 'creditCardsTab.share',
    });
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCards.size,
      t,
      isDisabled: false,
      key: 'creditCardsTab.remove',
    });

    return {
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
    };
  }, [selectedCreditCards.size, t]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareCreditCardsModalOpen}
                disabled={!selectedCreditCards.size}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteCreditCardModalOpen}
                disabled={!selectedCreditCards.size}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          <Box mr={3} />
          <Grid item>
            <Button
              icon={<PlusIcon />}
              onClick={handleCreateCreditCardModalOpen}
            >
              {t('creditCardsTab.createCreditCardButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <GroupCreditCardsTable
        selectedCreditCards={selectedCreditCards}
        {...restTableProps}
      />
      {isDeleteCreditCardModalOpen && (
        <PromptModal
          open={isDeleteCreditCardModalOpen}
          onClose={handleDeleteCreditCardModalClose}
          onSubmit={handleDeleteCreditCard}
          title={t(
            'deleteCreditCardModal.deleteFromGroup.title',
            {
              count: selectedCreditCards.size,
              name: group?.name,
              interpolation: { escapeValue: false },
            },
          )}
          description={t('deleteCreditCardModal.deleteFromGroup.description')}
          list={selectedCreditCardsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteCreditCardLoading}
        />
      )}
      {isShareCreditCardsModalOpen && (
        <ShareVaultsToGroupModal
          isOpen={isShareCreditCardsModalOpen}
          onClose={handleShareCreditCardsModalClose}
          vaults={selectedCreditCardsAsObjects}
          type={VaultTypeEnum.CreditCard}
          onSubmit={resetSelectedRows}
        />
      )}
      {isCreateCreditCardModalOpen && (
        <CreateCreditCardModal
          isOpen={isCreateCreditCardModalOpen}
          onClose={handleCreateCreditCardModalClose}
          onSubmit={handleCreateCreditCard}
          isLoading={isCreateCreditCardLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupCreditCardsTab);
