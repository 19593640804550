import { useState, useCallback, useMemo } from 'react';
import {
  Button,
  Grid,
  Typography,
  useTranslations,
  UniqkeyShieldLogoImage,
  S5,
  InputAdornment,
  IconButton,
  ClosedEyeIcon,
  EyeIcon,
  validateEmail,
  useSnackbar,
  ReactHookFormTextField,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import MigrationPanel from '../../components/MigrationPanel';
import MigrationPanelHeader from '../../components/MigrationPanel/components/MigrationPanelHeader';
import MigrationPanelContent
  from '../../components/MigrationPanel/components/MigrationPanelContent';
import { logException } from '../../../services/sentryService';
import useMigrationAPI from '../../hooks/useMigrationAPI';
import {
  authUser, generateMasterPasswordHash, setMasterPasswordHash,
} from '../../services/authService';
import MigrationPageRouteEnum from '../../enums/MigrationPageRouteEnum';
import { useUser } from '../../contexts/UserContext';
import { useCompanionApplicationContext } from '../../contexts/CompanionApplicationContext';

interface ILoginForm {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { isAuthenticated } = useUser();
  const { saveCompanionApplicationData } = useCompanionApplicationContext();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control, handleSubmit, formState: { errors, dirtyFields },
  } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });
  const { login } = useMigrationAPI();

  const handleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const handleFormSubmit = useCallback(async (form: ILoginForm) => {
    try {
      const { password, email } = form;
      setIsLoading(true);
      const masterPasswordHash = generateMasterPasswordHash(password);
      const { token } = await login({
        masterPasswordHash,
        email: email.toLowerCase().trim(),
      });
      saveCompanionApplicationData();
      setMasterPasswordHash(masterPasswordHash);
      await authUser({ token });
    } catch (e) {
      showError({ text: t('migration.loginPage.userEmailOrPasswordIsIncorrect') });
      logException(e, { message: 'migrationModulesLoginPage/handleFormSubmit exception' });
      setIsLoading(false);
    }
  }, [login, saveCompanionApplicationData, showError, t]);

  const isSubmitButtonDisabled = useMemo(
    () => isLoading || !dirtyFields.email || !dirtyFields.password,
    [isLoading, dirtyFields.email, dirtyFields.password],
  );

  if (isAuthenticated) {
    return <Navigate to={MigrationPageRouteEnum.Organizations} />;
  }

  return (
    <MigrationPanel>
      <MigrationPanelHeader title={t('migration.loginPage.startYourMigration')} />
      <MigrationPanelContent component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <UniqkeyShieldLogoImage />
        <Grid item container alignItems="center" justifyContent="center">
          <Grid item xs={8} mt={1}>
            <Typography align="center" variant="body1" color={S5}>
              {t('migration.loginPage.description')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          rowSpacing={2}
          mt={1}
        >
          <Grid item xs={8}>
            <ReactHookFormTextField
              name="email"
              control={control}
              fullWidth
              autoFocus
              error={!!errors.email?.message}
              label={`${t('migration.loginPage.email.label')}*`}
              placeholder={t('migration.loginPage.email.placeholder')}
              helperText={errors.email?.message}
              rules={{
                required: t('validation.required'),
                validate: (email) => validateEmail(email)
                  || buildInvalidFieldTranslation('migration.loginPage.email.label'),
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <ReactHookFormTextField
              name="password"
              control={control}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              error={!!errors.password?.message}
              label={`${t('migration.loginPage.masterPassword.label')}*`}
              placeholder={t('migration.loginPage.masterPassword.placeholder')}
              helperText={errors.password?.message}
              rules={{
                required: t('validation.required'),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleShowPassword}>
                      {showPassword ? <ClosedEyeIcon /> : <EyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          mt={3}
        >
          <Grid item xs={4}>
            <Button disabled={isSubmitButtonDisabled} isLoading={isLoading} fullWidth type="submit">
              {t('migration.loginPage.signIn')}
            </Button>
          </Grid>
        </Grid>
      </MigrationPanelContent>
    </MigrationPanel>
  );
};

export default LoginPage;
