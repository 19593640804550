import { useMemo } from 'react';

export interface IUseFilteredEntityParams<TEntity, TValue> {
  entities: TEntity[];
  values: TValue[];
  filterByFieldName: keyof TEntity;
}

const useFilteredEntity = <TEntity extends object, TValue extends TEntity[keyof TEntity]>(
  params: IUseFilteredEntityParams<TEntity, TValue>,
): TEntity[] => {
  const { entities, values, filterByFieldName } = params;
  const filteredEntities = useMemo(() => (
    entities.filter(
      (entity) => values.includes(entity[filterByFieldName] as TValue),
    )
  ), [entities, filterByFieldName, values]);
  return filteredEntities;
};

export default useFilteredEntity;
