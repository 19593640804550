import { memo, useCallback, useState } from 'react';
import {
  Grid,
  useTranslations,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  DEFAULT_LANGUAGE,
  B1,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { useLanguages } from '../../../../hooks/reactQuery';

interface SendInviteModalProps {
  areStagedAndInvitedOnly: boolean;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onApprove: (language: string) => Promise<void>;
}

const SendInviteModal = (props: SendInviteModalProps) => {
  const {
    areStagedAndInvitedOnly, isOpen, onClose, isLoading, onApprove,
  } = props;
  const [language, setLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const { languages, isLoading: areLanguagesLoading } = useLanguages();
  const { t } = useTranslations();
  const handleLanguageChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => setLanguage(event.target.value as string), []);
  const handleSubmit = useCallback(() => {
    onApprove(language);
  }, [onApprove, language]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      clickOutsideToClose={!isLoading}
    >
      <DialogTitle onClose={onClose} isLoading={isLoading}>
        {t('sendInviteModal.title')}
      </DialogTitle>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            {areStagedAndInvitedOnly
              ? t('sendInviteModal.description')
              : t('sendInviteModal.descriptionForMigratedUsers')}
          </Grid>
          {areStagedAndInvitedOnly && (
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <Typography variant="body2" color={B1}>
                  {t('sendInviteModal.langSelectorLabel')}
                </Typography>
              </Grid>
              <Grid item xs>
                {!areLanguagesLoading && (
                  <Select fullWidth value={language} onChange={handleLanguageChange}>
                    {
                      languages.map((currLanguage) => (
                        <MenuItem key={currLanguage.code} value={currLanguage.code}>
                          {currLanguage.localized_name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button onClick={handleSubmit} isLoading={isLoading} fullWidth>
              {t('common.send')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={onClose} disabled={isLoading} variant="outlined" fullWidth>
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default memo(SendInviteModal);
