import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetApplicationById } from '../../../../hooks/reactQuery';

interface IServicePageBreadcrumbsProps {
  applicationId: string;
}
const ServicePageBreadcrumbs = (props: IServicePageBreadcrumbsProps) => {
  const { applicationId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const { data: service, isLoading } = useGetApplicationById(
    { applicationId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.Services);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !service) {
      return [];
    }
    return [
      { title: t('navigation.services'), onClick: () => navigate(PageRouteEnum.Services) },
      { title: service.name },
    ];
  }, [isLoading, service, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(ServicePageBreadcrumbs);
