import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetSecuritySettingsResponse,
  NoContentResult,
  UpdateSecuritySettingsRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useOrganizationSecuritySettingsAPI = () => {
  const { organizationSecuritySettingsAPIClient } = useApiClients();

  const getOrganizationSecuritySettings = useCallback(async (
    options?: AxiosRequestConfig,
  ): Promise<GetSecuritySettingsResponse> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsGet(options)
    .then(dataExtractor<GetSecuritySettingsResponse>()), [organizationSecuritySettingsAPIClient]);

  const updateOrganizationSecuritySettings = useCallback(async (
    securitySettings?: UpdateSecuritySettingsRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsPut(securitySettings, options)
    .then(dataExtractor<NoContentResult>()), [organizationSecuritySettingsAPIClient]);

  return ({
    getOrganizationSecuritySettings,
    updateOrganizationSecuritySettings,
  });
};

export default useOrganizationSecuritySettingsAPI;
