import axios from 'axios';
import PubSub from 'pubsub-js';
import {
  EmployeeTokenInfo,
  RefreshTokenResponse, UserApi,
} from '@uniqkey-backend-organization-web/api-client';
import { keyBy } from 'lodash';
import { dataExtractor } from '../../helpers/apiClients';
import config from '../../config';
import {
  getUserTokens, ITokens, logout, setEmployeesTokens, setUserTokens,
} from '../authService';
import PubSubEventEnum from '../../enums/PubSubEventEnum';

const axiosInstanceWithoutAuthRefresh = axios.create({
  baseURL: config.getWebApiUrl(),
  timeout: config.requestTimeout,
});

const userClient = new UserApi(
  undefined,
  config.getWebApiUrl(),
  axiosInstanceWithoutAuthRefresh,
);

let tokensRefreshPromise: Promise<RefreshTokenResponse> | null = null;

const sendRefreshTokensRequest = async (tokens: ITokens): Promise<RefreshTokenResponse> => {
  if (tokensRefreshPromise) {
    return tokensRefreshPromise;
  }
  tokensRefreshPromise = userClient
    .apiV1UserRefreshTokenPost(tokens)
    .then(dataExtractor<RefreshTokenResponse>())
    .finally(() => {
      tokensRefreshPromise = null;
    });

  return tokensRefreshPromise;
};

const refreshTokens = async () => {
  const userTokens = getUserTokens();
  if (!userTokens) throw new Error('no user tokens found');

  const { tokens } = await sendRefreshTokensRequest(userTokens);

  const { userToken, employeeTokens } = tokens;

  const employeeTokensDictionary = keyBy<EmployeeTokenInfo>(employeeTokens, 'organizationId');

  setUserTokens(userToken);
  setEmployeesTokens(employeeTokensDictionary);
  PubSub.publish(PubSubEventEnum.TOKENS_REFRESHED);
};

export const triggerTokensRefresh = () => {
  PubSub.publish(PubSubEventEnum.TRIGGER_TOKENS_REFRESH);
};

export const listenRefreshTokensEvents = () => {
  PubSub.subscribe(PubSubEventEnum.TRIGGER_TOKENS_REFRESH, async () => {
    try {
      await refreshTokens();
    } catch (e) {
      // https://uniqkey.atlassian.net/browse/NEXTGEN-6379
      logout({ showMessage: true });
    }
  });
};

export default refreshTokens;
