import { type INormalizedTrustedPortalActions } from '../../helpers';
import TrustedPortalStoreActions from '../../store/actions';
import { TrustedPortalWorker } from '../../Worker';

const workers: Map<string, TrustedPortalWorker> = new Map();

const getAllKeys = () => Array.from(workers.keys());

const getById = (id: string) => workers.get(id) ?? null;

const create = (
  organizationIds: string[],
  normalizedTrustedActionsByOrganizationId: INormalizedTrustedPortalActions['byOrganizationId'],
) => {
  organizationIds.forEach((organizationId) => {
    const trustedPortalAction = normalizedTrustedActionsByOrganizationId[organizationId];
    const {
      organizationPrivateKey,
      organizationPublicKey,
      token,
      refreshToken,
    } = trustedPortalAction.parsedPayload;

    const worker = new TrustedPortalWorker(
      organizationId,
      organizationPrivateKey,
      organizationPublicKey,
      token,
      refreshToken,
    );

    // init store
    TrustedPortalStoreActions.setIsProcessingByOrganizationId(organizationId, false);
    TrustedPortalStoreActions.setIsEnabledByOrganizationId(organizationId, true);

    workers.set(organizationId, worker);

    worker.start();
  });
};

const destroy = (organizationIds: string[]) => {
  organizationIds.forEach((organizationId) => {
    const workerToDestroy = workers.get(organizationId);
    if (!workerToDestroy) {
      return;
    }
    workerToDestroy.destroy();
    TrustedPortalStoreActions.removeIsProcessingByOrganizationId(organizationId);
    TrustedPortalStoreActions.removeIsEnabledByOrganizationId(organizationId);
    workers.delete(organizationId);
  });
};

const destroyAll = () => {
  const allKeys = getAllKeys();
  destroy(allKeys);
};

export default {
  getAllKeys,
  getById,
  create,
  destroy,
  destroyAll,
};
