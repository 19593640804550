import {
  ForwardedRef, useEffect, useRef,
} from 'react';
import type {
  ChartDataset, ParsingOptions, ChartData, Chart,
} from 'chart.js';
import { AB3 } from '@uniqkey-frontend/shared-app';
import { createLineChartGradient } from '../../helpers/ChartJSLib';
import type { IChartDataPoint, TLineChartType } from '../../helpers/ChartJSLib/interfaces';

interface IUseLineChartProps {
  data: ChartDataset<TLineChartType, IChartDataPoint[]>['data'];
  parsing: ParsingOptions['parsing'];
  withGradient?: boolean;
}

interface IUseLineChartReturn {
  initialChartData: ChartData<TLineChartType, IChartDataPoint[]>;
  chartRef: ForwardedRef<Chart<TLineChartType, IChartDataPoint[]> | undefined>;
}

const useLineChart = (props: IUseLineChartProps): IUseLineChartReturn => {
  const { data, parsing, withGradient = true } = props;

  const initialChartData = useRef<IUseLineChartReturn['initialChartData']>({
    datasets: [{
      data,
      parsing,
    }],
  });

  const chartRef: IUseLineChartReturn['chartRef'] = useRef(undefined);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const updatedDataset: ChartDataset<TLineChartType, IChartDataPoint[]> = {
      data,
      parsing,
    };
    if (data.length === 1) {
      updatedDataset.pointBackgroundColor = AB3;
      updatedDataset.pointBorderColor = AB3;
      updatedDataset.pointRadius = 4.5;
    }
    if (withGradient) {
      updatedDataset.backgroundColor = createLineChartGradient(chart.ctx, chart.chartArea);
      updatedDataset.fill = 'start';
    }
    chart.data.datasets = [updatedDataset];
    chart.update();
  }, [data, parsing, withGradient]);

  return { initialChartData: initialChartData.current, chartRef };
};

export default useLineChart;
