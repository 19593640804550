import { decryptAsymmetric, encryptSymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultPasswordCreationRequestedForGroupPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultPasswordCreation from '../common/handleAdminVaultPasswordCreation';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultPasswordCreationForGroupRequestedEvent';

const handleAdminVaultPasswordCreationForGroupRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultPasswordCreationRequestedForGroupPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { password, groupSecret } = parsedPayload;

  if (!groupSecret) {
    throw new Error(`${ERROR_PREFIX} no groupSecret in event`);
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: groupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const {
    encryptedPassword,
    strength,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultPasswordCreation({
    organizationPublicKey,
    password,
    errorPrefix: ERROR_PREFIX,
  });

  const encryptedSymmetricKey = encryptSymmetric({
    string: symmetricKey,
    key: decryptedGroupSecret,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminVaultPasswordCreationRequestedForGroupApprovePost({
      queueMessageId,
      password: encryptedPassword,
      strength,
      totpToken: null,
      vaultSymmetricKey: encryptedSymmetricKey,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleAdminVaultPasswordCreationForGroupRequestedEvent;
