import { encryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultPasswordCreationRequestedForEmployeePayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultPasswordCreation from '../common/handleAdminVaultPasswordCreation';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultPasswordCreationForEmployeeRequestedEvent';

const handleAdminVaultPasswordCreationForEmployeeRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultPasswordCreationRequestedForEmployeePayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    password,
    employeeAccountId,
    employeeAccountKeyPairPublic,
  } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!employeeAccountKeyPairPublic) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountKeyPairPublic in event`);
  }

  const {
    encryptedPassword,
    strength,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultPasswordCreation({
    organizationPublicKey,
    password,
    errorPrefix: ERROR_PREFIX,
  });

  const encryptedSymmetricKey = await encryptAsymmetric({
    string: symmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminVaultPasswordCreationRequestedForEmployeeApprovePost({
      queueMessageId,
      password: encryptedPassword,
      strength,
      totpToken: null,
      vaultSymmetricKey: encryptedSymmetricKey,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleAdminVaultPasswordCreationForEmployeeRequestedEvent;
