import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  formatDate,
  TypographyWithTooltip,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetVerifiedDomainsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';

export interface IVerifiedDomainsTableRow extends GetVerifiedDomainsResponseModel {
  parsedCreatedAt: string;
}

interface IVerifiedDomainsTableProps extends
  Omit<
    IUseTableReturn<IVerifiedDomainsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Omit<IUseTableFetchReturn<GetVerifiedDomainsResponseModel>, 'data' | 'refetch'>
{
  verifiedDomains: IUseTableFetchReturn<GetVerifiedDomainsResponseModel>['data'];
  selectedVerifiedDomains: IUseTableReturn<IVerifiedDomainsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IVerifiedDomainsTableRow> = {
  selection: true,
};

const VerifiedDomainsTable = (props: IVerifiedDomainsTableProps) => {
  const {
    verifiedDomains: preVerifiedDomains,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    onColumnOrderByChange,
    onColumnOrderDirectionChange,
    selectedVerifiedDomains,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const verifiedDomains = useMemo(() => preVerifiedDomains.map((verifiedDomain) => ({
    ...verifiedDomain,
    parsedCreatedAt: formatDate({ date: verifiedDomain.createdAt }) ?? '-',
  })), [preVerifiedDomains]);

  const columns = useMemo(() => createColumns([
    {
      title: t('settingsPage.verifiedDomainsTab.domain'),
      field: 'domainName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.domainName}
        </TypographyWithTooltip>
      ),
      sorting: false,
      width: '75%',
    },
    {
      title: t('settingsPage.verifiedDomainsTab.createdAt'),
      field: 'createdAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.parsedCreatedAt}
        </TypographyWithTooltip>
      ),
      sorting: false,
      width: '25%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={verifiedDomains}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      onColumnOrderByChange={onColumnOrderByChange}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedVerifiedDomains}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="verifiedDomainId"
      rowHeight={51}
    />
  );
};

export default memo(VerifiedDomainsTable);
