import { TUseQueryOptions, TUseQueryOptionsWithKey } from '@uniqkey-frontend/shared-app';
import {
  ApplicationGetByIdResponse,
  GetDomainRulesAndDesktopApplicationsByApplicationIdResponse,
  ApplicationGetResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useApplicationsAPI from '../../useApplicationsAPI';
import { IGetServicesListParams } from '../../useApplicationsAPI/interfaces';

interface IUseGetApplicationByIdParams {
  applicationId: string;
}

export const useGetApplicationById = (
  params: IUseGetApplicationByIdParams,
  options: TUseQueryOptions<ApplicationGetByIdResponse> = {},
) => {
  const { applicationId } = params;
  const { getApplicationById } = useApplicationsAPI();
  return useQuery<ApplicationGetByIdResponse>(
    [ReactQueryKeyEnum.Service, applicationId],
    ({ signal }) => getApplicationById(applicationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetServicesByApplicationId = (
  params: IUseGetApplicationByIdParams,
  options: TUseQueryOptions<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse> = {},
) => {
  const { applicationId } = params;
  const { getServicesByApplicationId } = useApplicationsAPI();
  return useQuery<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse>(
    [ReactQueryKeyEnum.ServicesByApplicationId, applicationId],
    ({ signal }) => getServicesByApplicationId(applicationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetServices = (
  params: IGetServicesListParams,
  options: TUseQueryOptionsWithKey<ApplicationGetResponse> = {},
) => {
  const { queryKey = [ReactQueryKeyEnum.Services] } = options;
  const { getServicesList } = useApplicationsAPI();
  return useQuery<ApplicationGetResponse>(
    queryKey,
    ({ signal }) => getServicesList({ signal, ...params }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
