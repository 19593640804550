import { ReactNode } from 'react';
import { Grid, Panel } from '@uniqkey-frontend/shared-app';

const PANEL_SX = {
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 180px)',
  width: 776,
};

export interface IMigrationPanelProps {
  children: ReactNode;
}

const MigrationPanel = (props: IMigrationPanelProps) => {
  const { children } = props;

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Panel sx={PANEL_SX}>
        {children}
      </Panel>
    </Grid>
  );
};

export default MigrationPanel;
