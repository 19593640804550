import { decryptAsymmetric, encryptSymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultSharingToGroupRequestedPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultSharingToGroupRequestedEvent';

const handleAdminVaultSharingToGroupRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultSharingToGroupRequestedPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
    organizationPrivateKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { vaultSymmetricKeyBackup, groupSecret } = parsedPayload;

  if (!vaultSymmetricKeyBackup) {
    throw new Error(`${ERROR_PREFIX} no vaultSymmetricKeyBackup in event`);
  }

  if (!groupSecret) {
    throw new Error(`${ERROR_PREFIX} no groupSecret in event`);
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: groupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const decryptedSymmetricKeyBackup = await decryptAsymmetric({
    cipher: vaultSymmetricKeyBackup,
    publicKey: organizationPublicKey,
    privateKey: organizationPrivateKey,
  });

  const encryptedSymmetricKey = encryptSymmetric({
    string: decryptedSymmetricKeyBackup,
    key: decryptedGroupSecret,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminAddRequestedForGroupApprovePost({
      vaultSymmetricKey: encryptedSymmetricKey,
      queueMessageId,
    });
};

export default handleAdminVaultSharingToGroupRequestedEvent;
