import { FC, Suspense } from 'react';
import {
  Navigate, RouteProps, Outlet,
} from 'react-router-dom';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { useUser } from '../../contexts/UserContext';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';

type TProtectedRouteParams = RouteProps & {
  isAuthorized: boolean;
  isAllowed: boolean;
  withLayout: boolean;
}

const ProtectedRoute: FC<TProtectedRouteParams> = (
  props: TProtectedRouteParams,
) => {
  const {
    isAuthorized = false,
    isAllowed = true,
    withLayout,
  } = props;

  const { isCurrentUserLoading } = useUser();
  if (!isAuthorized || !isAllowed) {
    return (
      <Navigate to={PageRouteEnum.Login} />
    );
  }
  if (isCurrentUserLoading) {
    return <Suspense fallback />;
  }
  if (withLayout) {
    return (
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    );
  }
  return (
    <Outlet />
  );
};

export default ProtectedRoute;
