import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import { IAuditLogsTableRow } from '../../../components/tables/AuditLogsTable';
import {
  IAuditLog,
  IGetAuditLogsParams,
  TGetAuditLogsMethod,
} from '../../useAuditLogsAPI/interfaces';
import {
  IAuditLogsListFilterSubmitResult,
} from '../../../pages/AuditLogsPage/components/AuditLogsTab/components/AuditLogsListFilter';
import useAuditLogsAPI from '../../useAuditLogsAPI';

export interface IUseAuditLogsTableProps {
  noDataMessageKey: string;
}

export interface IUseAuditLogsTableReturn extends
  Omit<
    IUseTableReturn<IAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseTableFetchReturn<IAuditLog>, 'data'>,
  IUseFilterButtonReturn
{
  auditLogs: IUseTableFetchReturn<IAuditLog>['data'];
  selectedAuditLogs: IUseTableReturn<IAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  filterValues: IAuditLogsListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IAuditLogsListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetAuditLogsParams,
    'page' | 'pageLength' | 'employeeAccountId' | 'beforeDate' | 'afterDate' | 'cancelToken'
  >
{
  activePage: IGetAuditLogsParams['page'];
  perPage: IGetAuditLogsParams['pageLength'];
  employeeAccount: IAuditLogsListFilterSubmitResult['employeeAccount'];
  dateRange: IAuditLogsListFilterSubmitResult['dateRange'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  employeeAccount: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.OBJECT,
  ),
  dateRange: true,
};

const useAuditLogsTable = (props: IUseAuditLogsTableProps): IUseAuditLogsTableReturn => {
  const {
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getAuditLogs } = useAuditLogsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    employeeAccount: initialEmployeeAccount,
    dateRange: initialDateRange,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [filterValues, setFilterValues] = useState<
    IAuditLogsListFilterSubmitResult
  >({
    employeeAccount: initialEmployeeAccount,
    dateRange: initialDateRange,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    selectedRows: selectedAuditLogs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IAuditLogsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setFilterValues({
      employeeAccount: undefined,
      dateRange: TablePreserverCommonInitialTableValues.dateRange,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: auditLogs, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetAuditLogsParams,
    TGetAuditLogsMethod,
    IAuditLog
  >({
    request: getAuditLogs,
    params: {
      page: activePage,
      pageLength: perPage,
      employeeAccountId: filterValues.employeeAccount?.value,
      afterDate: filterValues.dateRange?.from ?? undefined,
      beforeDate: filterValues.dateRange?.to ?? undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    employeeAccount: {
      value: filterValues.employeeAccount,
      parser: JSON.stringify,
    },
    dateRange: {
      value: filterValues.dateRange,
      parser: JSON.stringify,
    },
  });

  return {
    auditLogs,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    selectedAuditLogs,
    localization,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useAuditLogsTable;
