import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  OkResult,
  NoContentResult,
  GetUserProfileResponse,
  UpdateUserNameRequest,
  UpdateUserLanguageRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useUserAPI = () => {
  const { userAPIClient } = useApiClients();

  const getCurrentUser = useCallback(
    (options?: AxiosRequestConfig)
      : Promise<GetUserProfileResponse> => userAPIClient
      .apiV1UserCurrentUserProfileGet(options)
      .then(dataExtractor<GetUserProfileResponse>()),
    [userAPIClient],
  );

  const updateCurrentUserProfile = useCallback(
    (updateUserNameRequest?: UpdateUserNameRequest, options?: AxiosRequestConfig)
      : Promise<OkResult> => userAPIClient
      .apiV1UserUpdateCurrentUserProfilePut(updateUserNameRequest, options)
      .then(dataExtractor<OkResult>()),
    [userAPIClient],
  );

  const updateCurrentUserLanguage = useCallback(
    (updateUserLanguageRequest?: UpdateUserLanguageRequest, options?: AxiosRequestConfig)
      : Promise<OkResult> => userAPIClient
      .apiV1UserUpdateUserLanguagePut(updateUserLanguageRequest, options)
      .then(dataExtractor<OkResult>()),
    [userAPIClient],
  );

  const logoutMe = useCallback(
    // @ts-ignore
    (options?: AxiosRequestConfig): Promise<NoContentResult> => userAPIClient
      .apiV1UserLogoutPost(options)
      .then(dataExtractor<NoContentResult>())
      .catch(() => {}), // do not throw an error on failed logout
    [userAPIClient],
  );

  return ({
    updateCurrentUserProfile,
    updateCurrentUserLanguage,
    getCurrentUser,
    logoutMe,
  });
};

export default useUserAPI;
