import { useCallback, useMemo, useState } from 'react';
import {
  useSnackbar,
  useTranslations,
  formatDateRanges,
  initDateRange,
  DateRange,
  IDateRangePickerWithTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import { useGetActiveEmployeesRate } from '../../../../../../hooks/reactQuery';
import DashboardEmployeeRateWidgetContainer from '../DashboardEmployeeRateWidgetContainer';

const DEFAULT_VALUES = { refreshDate: '', data: [] };

const DashboardActiveEmployeesRateWidget = () => {
  const { t } = useTranslations();
  const { showError } = useSnackbar();

  const [range, setRange] = useState<DateRange>(() => initDateRange({
    to: new Date(),
    duration: { day: 7 },
  }));
  const formattedRange = useMemo(
    () => formatDateRanges(range, { asISODate: true, inUTC: false }),
    [range],
  );
  const handleRangeChange = useCallback<
    IDateRangePickerWithTextFieldProps['onSubmit']
  >((newRange) => {
    setRange(newRange);
  }, []);

  const { data: activeEmployeesRate, isLoading, isError } = useGetActiveEmployeesRate(
    { from: formattedRange.from!, to: formattedRange.to! },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
      enabled: !!(formattedRange.from && formattedRange.to),
    },
  );

  if (isError) {
    return null;
  }

  const { refreshDate, data } = activeEmployeesRate ?? DEFAULT_VALUES;

  return (
    <DashboardEmployeeRateWidgetContainer
      headerTranslationKey="activeEmployeesRateWidget.title"
      tooltipTranslationKey="activeEmployeesRateWidget.tooltip"
      updatedAtTranslationKey="activeEmployeesRateWidget.updatedAt"
      noDataTranslationKey="activeEmployeesRateWidget.noData"
      updatedAt={refreshDate}
      from={range.from}
      to={range.to}
      onSubmit={handleRangeChange}
      onClear={handleRangeChange}
      isLoading={isLoading}
      data={data}
    />
  );
};

export default DashboardActiveEmployeesRateWidget;
