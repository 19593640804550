import { create } from 'zustand';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import type { Dictionary } from '../../../types/common';

export interface ITrustedPortalStore {
  isInitialized: boolean;
  isEnabledByOrganizationId: Dictionary<boolean>;
  isProcessingByOrganizationId: Dictionary<boolean>;
}

export const INITIAL_TRUSTED_PORTAL_STORE_STATE = {
  isInitialized: false,
  isEnabledByOrganizationId: {},
  isProcessingByOrganizationId: {},
};

const useTrustedPortalStoreBase = create<ITrustedPortalStore>()(
  () => INITIAL_TRUSTED_PORTAL_STORE_STATE,
);

export const useTrustedPortalStore = createSelectorHooks(useTrustedPortalStoreBase);
