import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import {
  GetEmployeeAccountsOnBoardResponse,
  GetEmployeeActivationRateResponse,
  GetActiveEmployeesRateResponse,
  GetAverageSecurityScoreForWidgetResponse,
  GetSecurityScoreRateResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery, useQueryClient } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useDashboardAPI from '../../useDashboardAPI';

export const useGetEmployeeAccountsOnBoard = (
  options: TUseQueryOptions<GetEmployeeAccountsOnBoardResponse> = {},
) => {
  const { getEmployeeAccountsOnBoard } = useDashboardAPI();
  return useQuery<GetEmployeeAccountsOnBoardResponse>(
    [ReactQueryKeyEnum.DashboardEmployeeAccountsOnBoard],
    ({ signal }) => getEmployeeAccountsOnBoard({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetEmployeeActivationRateParams {
  from: string;
  to: string;
}
export const useGetEmployeeActivationRate = (
  params: IUseGetEmployeeActivationRateParams,
  options: TUseQueryOptions<GetEmployeeActivationRateResponse> = {},
) => {
  const { from, to } = params;
  const queryClient = useQueryClient();
  const { getEmployeeActivationRate } = useDashboardAPI();
  return useQuery<GetEmployeeActivationRateResponse>(
    [ReactQueryKeyEnum.DashboardEmployeeActivationRate, `${from}--${to}`],
    ({ signal }) => getEmployeeActivationRate(from, to, { signal }),
    {
      notifyOnChangeProps: 'tracked',
      ...options,
      onSettled: () => {
        queryClient.removeQueries(
          [ReactQueryKeyEnum.DashboardEmployeeActivationRate],
          // after changing from and to -> the previous query will become inactive
          { inactive: true },
        );
      },
    },
  );
};

interface IUseGetActiveEmployeesRateParams {
  from: string;
  to: string;
}
export const useGetActiveEmployeesRate = (
  params: IUseGetActiveEmployeesRateParams,
  options: TUseQueryOptions<GetActiveEmployeesRateResponse> = {},
) => {
  const { from, to } = params;
  const queryClient = useQueryClient();
  const { getActiveEmployeesRate } = useDashboardAPI();
  return useQuery<GetActiveEmployeesRateResponse>(
    [ReactQueryKeyEnum.DashboardActiveEmployeesRate, `${from}--${to}`],
    ({ signal }) => getActiveEmployeesRate(from, to, { signal }),
    {
      notifyOnChangeProps: 'tracked',
      ...options,
      onSettled: () => {
        queryClient.removeQueries(
          [ReactQueryKeyEnum.DashboardActiveEmployeesRate],
          // after changing from and to -> the previous query will become inactive
          { inactive: true },
        );
      },
    },
  );
};

export const useGetAverageSecurityScore = (
  options: TUseQueryOptions<GetAverageSecurityScoreForWidgetResponse> = {},
) => {
  const { getAverageSecurityScore } = useDashboardAPI();
  return useQuery<GetAverageSecurityScoreForWidgetResponse>(
    [ReactQueryKeyEnum.DashboardAverageSecurityScore],
    ({ signal }) => getAverageSecurityScore({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetSecurityScoreRateParams {
  from: string;
  to: string;
}
export const useGetSecurityScoreRate = (
  params: IUseGetSecurityScoreRateParams,
  options: TUseQueryOptions<GetSecurityScoreRateResponse> = {},
) => {
  const { from, to } = params;
  const queryClient = useQueryClient();
  const { getSecurityScoreRate } = useDashboardAPI();
  return useQuery<GetSecurityScoreRateResponse>(
    [ReactQueryKeyEnum.DashboardSecurityScoreRate, `${from}--${to}`],
    ({ signal }) => getSecurityScoreRate(from, to, { signal }),
    {
      notifyOnChangeProps: 'tracked',
      ...options,
      onSettled: () => {
        queryClient.removeQueries(
          [ReactQueryKeyEnum.DashboardSecurityScoreRate],
          // after changing from and to -> the previous query will become inactive
          { inactive: true },
        );
      },
    },
  );
};
