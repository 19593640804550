import PubSub from 'pubsub-js';
import TrustedPortalPubSubEventEnum from '../enums/PubSubEventEnum';
import { buildPubSubTopic } from '../../../helpers/PubSub';
import { logException } from '../../../services/sentryService';
import API from '../api';
import Helpers from '../helpers';

const LOG_COLOR = 'darkgreen';

interface ITrustedPortalEventProvider {
  isProviding: boolean;
  destroy(): void;
}

// eslint-disable-next-line import/prefer-default-export
export class TrustedPortalEventProvider implements ITrustedPortalEventProvider {
  private readonly unsubscribeLoadEvents: string;

  private isDestroyed = false;

  isProviding = false;

  constructor(
    private readonly organizationId: string,
  ) {
    this.unsubscribeLoadEvents = PubSub.subscribe(
      this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_LOAD_EVENTS),
      async (_, data) => {
        try {
          const { quantity } = data;

          Helpers.tpLogMessage({
            message: `[PROVIDER] receive TRUSTED_PORTAL_LOAD_EVENTS, amount: ${quantity}`,
            color: LOG_COLOR,
          });

          if (this.isProviding) {
            Helpers.tpLogMessage({ message: '[PROVIDER] already providing', color: LOG_COLOR });
            return;
          }

          Helpers.tpLogMessage({ message: '[PROVIDER] start providing', color: LOG_COLOR });
          this.isProviding = true;

          const events = await API.getQueueMessages({
            quantity,
            activeOrganizationId: this.organizationId,
          });

          const orderedEvents = Helpers.orderQueueMessagesByPriority(events);

          if (this.isDestroyed) {
            Helpers.tpLogMessage({
              message: `[PROVIDER] isDestroyed: ${this.isDestroyed}`,
              color: LOG_COLOR,
            });
            return;
          }

          Helpers.tpLogMessage({
            message: `[PROVIDER] publish TRUSTED_PORTAL_EVENTS_LOADED, amount: ${
              orderedEvents.length
            }`,
            color: LOG_COLOR,
          });
          PubSub.publish(
            this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_EVENTS_LOADED),
            { events: orderedEvents },
          );
        } catch (e) {
          logException(e, {
            message: 'TrustedPortalModule/EventProvider/TRUSTED_PORTAL_LOAD_EVENTS exception',
          });
          Helpers.tpLogMessage({ messages: ['[PROVIDER] error:', e], color: LOG_COLOR });
        } finally {
          this.isProviding = false;
          Helpers.tpLogMessage({ message: '[PROVIDER] finish providing', color: LOG_COLOR });
        }
      },
    );
  }

  private getPubSubTopic(topic: string): string {
    return buildPubSubTopic(topic, this.organizationId);
  }

  destroy(): void {
    PubSub.unsubscribe(this.unsubscribeLoadEvents);
    this.isDestroyed = true;
  }
}
