import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import OrganizationLoginPageBreadcrumbs from './components/OrganizationLoginPageBreadcrumbs';

const OrganizationLoginPage = () => {
  const params = useParams();
  const { vaultId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <OrganizationLoginPageBreadcrumbs vaultId={vaultId!} />
      </Grid>
      <Grid item xs>
        <Outlet context={{ vaultId }} />
      </Grid>
    </Grid>
  );
};

export default OrganizationLoginPage;
