import {
  decryptSymmetric,
  encryptAsymmetric,
  encryptSymmetric,
  generateSymmetricKey,
} from '@uniqkey-frontend/shared-app';
import {
  type VaultSecureNoteCreationRequestedForEmployeePayload,
  type VaultSecureNoteCreationRequestedForGroupPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../../interfaces';
import { getSymmetricKey } from '../../../../../../services/companionApplicationService';

type TNote = VaultSecureNoteCreationRequestedForEmployeePayload['note']
  | VaultSecureNoteCreationRequestedForGroupPayload['note'];

interface IHandleAdminVaultSecureNoteCreationParams extends Pick<
  ITrustedPortalHandlerParams, 'organizationPublicKey'
> {
  note: TNote;
  errorPrefix: string;
}

const handleAdminVaultSecureNoteCreation = async (
  params: IHandleAdminVaultSecureNoteCreationParams,
) => {
  const { organizationPublicKey, note, errorPrefix } = params;

  const companionApplicationSymmetricKey = getSymmetricKey();
  if (!companionApplicationSymmetricKey) {
    throw new Error(`${errorPrefix} companionApplicationSymmetricKey not found`);
  }

  const symmetricKey = generateSymmetricKey();

  let encryptedNote: null | string = null;

  if (note) {
    const decryptedNote = decryptSymmetric({
      cipher: note,
      key: companionApplicationSymmetricKey,
    });

    encryptedNote = encryptSymmetric({
      string: decryptedNote,
      key: symmetricKey,
    });
  }

  const encryptedSymmetricKeyBackup = await encryptAsymmetric({
    string: symmetricKey,
    publicKey: organizationPublicKey,
  });

  return {
    encryptedNote,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  };
};

export default handleAdminVaultSecureNoteCreation;
