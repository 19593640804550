import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel,
  PanelHeader,
  useTranslations,
  Tab,
  Tabs,
  ConditionalTabWrapper,
} from '@uniqkey-frontend/shared-app';
import {
  Navigate, useMatch, useNavigate, useOutletContext,
} from 'react-router-dom';
import { useGetApplicationById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import ServiceEmployeesTab from '../ServiceEmployeesTab';
import ServiceLoginsTab from '../ServiceLoginsTab';

export enum ServiceModuleTabEnum {
  Employees = 'employees',
  Logins = 'logins'
}

interface IServiceModuleProps {
  activeTab: ServiceModuleTabEnum;
}

interface IServiceModuleContext {
  applicationId: string;
}

const ServiceModule = (props: IServiceModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { applicationId } = useOutletContext<IServiceModuleContext>();
  const navigate = useNavigate();

  const { isLoading, data: service } = useGetApplicationById(
    { applicationId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: ServiceModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Services}/${applicationId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [applicationId, activeTab, navigate]);

  const { userCount, vaultCount, name } = service ?? {};
  const isLoginsTab = useMatch(PageRouteEnum.ServiceLoginsTab);

  if (!isLoading && !userCount) {
    if (!vaultCount) {
      return <Navigate to={PageRouteEnum.Services} />;
    }
    if (!isLoginsTab) {
      return (
        <Navigate
          to={`${PageRouteEnum.Services}/${applicationId}/${ServiceModuleTabEnum.Logins}`}
          replace
        />
      );
    }
  }

  return (
    <Panel>
      <PanelHeader px={3}>
        <Tabs value={activeTab} isLoading={isLoading}>
          {!!userCount && (
            <Tab
              label={t('servicePage.tabs.employees')}
              value={ServiceModuleTabEnum.Employees}
              onClick={handleTabClick}
            />
          )}
          {!!vaultCount && (
            <Tab
              label={t('servicePage.tabs.logins')}
              value={ServiceModuleTabEnum.Logins}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      {activeTab === ServiceModuleTabEnum.Employees && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={!!userCount}
          fallback={<Navigate to={`${PageRouteEnum.Services}/${applicationId}`} />}
        >
          <ServiceEmployeesTab applicationId={applicationId} />
        </ConditionalTabWrapper>
      )}
      {activeTab === ServiceModuleTabEnum.Logins && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={!!vaultCount}
          fallback={<Navigate to={`${PageRouteEnum.Services}/${applicationId}`} />}
        >
          <ServiceLoginsTab applicationName={name!} />
        </ConditionalTabWrapper>
      )}
    </Panel>
  );
};

export default memo(ServiceModule);
