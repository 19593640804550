import { useMemo, memo, useCallback } from 'react';
import {
  Table,
  SecurityScoreIcon,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
  Grid,
  LoginAvatar,
  AG2,
  AR2,
  getTimeFromNow,
} from '@uniqkey-frontend/shared-app';
import { GetVaultPasswordsResponseModel } from '@uniqkey-backend-organization-web/api-client';
import { useNavigate } from 'react-router-dom';
import { MFA_TRANSLATION_KEYS, OWNERSHIP_TRANSLATION_KEYS } from '../../../constants';
import PageRouteEnum from '../../../enums/PageRouteEnum';

export interface IServiceLoginsTableRow extends GetVaultPasswordsResponseModel {
  lastActivity: string | null;
}

interface IServiceLoginsTableProps extends
  Omit<IUseTableReturn<IServiceLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetVaultPasswordsResponseModel>, 'data' | 'refetch'>
{
  logins: IUseTableFetchReturn<GetVaultPasswordsResponseModel>['data'];
  selectedLogins: IUseTableReturn<IServiceLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IServiceLoginsTableRow> = {
  selection: false,
};

const MFA_COLORS = {
  true: AG2,
  false: AR2,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const ServiceLoginsTable = (props: IServiceLoginsTableProps) => {
  const {
    logins: preLogins,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedLogins,
    onRowSelectionChange,
    createColumns,
  } = props;
  const navigate = useNavigate();
  const { t, currentLanguage } = useTranslations();

  const logins = useMemo(() => preLogins.map((login) => ({
    ...login,
    lastActivity: getTimeFromNow({
      date: login.activityAt,
      locale: currentLanguage,
    }),
  })), [preLogins, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('loginsTab.table.columns.loginName'),
      field: 'name',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <LoginAvatar src={rowData.iconUrl} />
          <TypographyWithTooltip variant="body2">
            {rowData.name}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '20%',
    },
    {
      title: t('loginsTab.table.columns.userName'),
      field: 'username',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.username}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: t('loginsTab.table.columns.securityScore'),
      field: 'strength',
      render: (rowData) => (
        <SecurityScoreIcon percentage={rowData.strength} />
      ),
      width: '15%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('loginsTab.table.columns.mfa.columnTooltip')}
        >
          {t('loginsTab.table.columns.mfa')}
        </TypographyWithTooltip>
      ),
      field: 'hasTotp',
      render: (rowData) => {
        const is2FAEnabledAsString = rowData.hasTotp.toString();
        return (
          <TypographyWithTooltip
            variant="body2"
            color={MFA_COLORS[is2FAEnabledAsString as keyof typeof MFA_COLORS]}
          >
            {t(MFA_TRANSLATION_KEYS[is2FAEnabledAsString as keyof typeof MFA_TRANSLATION_KEYS])}
          </TypographyWithTooltip>
        );
      },
      width: '15%',
    },
    {
      title: t('loginsTab.table.columns.managedBy'),
      field: 'ownership',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(OWNERSHIP_TRANSLATION_KEYS[
            rowData.ownership as keyof typeof OWNERSHIP_TRANSLATION_KEYS
          ])}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('loginsTab.table.columns.lastActivity.columnTooltip')}
        >
          {t('loginsTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
  ]), [createColumns, t]);

  const handleLoginRowClick = useCallback<
    NonNullable<IMaterialTableProps<IServiceLoginsTableRow>['onRowClick']>
  >((event, login) => {
    navigate(`${PageRouteEnum.AllSecuredData}/logins/${login!.vaultId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={logins}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedLogins}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleLoginRowClick}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(ServiceLoginsTable);
