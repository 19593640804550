import { ReactNode } from 'react';
import { Grid } from '@uniqkey-frontend/shared-app';
import {
// matchPath,
// useLocation,
} from 'react-router-dom';
import Side from './Side';

interface AuthorizedLayoutProps {
  children: ReactNode,
}

const SIDE_WIDTH = 270;
const CHILDREN_WIDTH = `calc(100% - ${SIDE_WIDTH}px)`;

const AuthorizedLayout = (props: AuthorizedLayoutProps) => {
  const { children } = props;
  return (
    <Grid container className="min-height-100-vh" columnSpacing={2} p={2}>
      <Grid container flexDirection="column" item width={SIDE_WIDTH}>
        <Side />
      </Grid>
      <Grid item width={CHILDREN_WIDTH}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthorizedLayout;
