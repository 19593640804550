import { Grid } from '@uniqkey-frontend/shared-app';
import DashboardAverageSecurityScoreWidget from './components/DashboardAverageSecurityScoreWidget';
import DashboardAverageSecurityScoreWithGraphWidget
  from './components/DashboardAverageSecurityScoreWithGraphWidget';

const DashboardSecurityScoreWidgets = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} lg={6}>
      <DashboardAverageSecurityScoreWidget />
    </Grid>
    <Grid item xs={12} lg={6}>
      <DashboardAverageSecurityScoreWithGraphWidget />
    </Grid>
  </Grid>
);

export default DashboardSecurityScoreWidgets;
