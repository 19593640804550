import { TUseMutationOptions } from '@uniqkey-frontend/shared-app';
import {
  DeleteBulkVaultToGroupRequest, DeleteBulkVaultToGroupResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useMutation, useQueryClient } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useVaultsToGroupsAPI from '../../useVaultsToGroupsAPI';

// TODO: remove this when more methods will be added
// eslint-disable-next-line import/prefer-default-export
export const useDeleteVaultsToGroups = (
  options: TUseMutationOptions<
    DeleteBulkVaultToGroupResponse,
    unknown,
    DeleteBulkVaultToGroupRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { deleteVaultsToGroups } = useVaultsToGroupsAPI();
  return useMutation((
    deleteBulkVaultToGroupRequest,
  ) => deleteVaultsToGroups(deleteBulkVaultToGroupRequest), {
    onSettled: (data, error, variables) => {
      variables.vaultIds.forEach((vaultId) => {
        queryClient.removeQueries([ReactQueryKeyEnum.Login, vaultId], { exact: true });
      });
    },
    ...options,
  });
};
