import { memo, useMemo } from 'react';
import {
  Grid,
  Typography,
  TypographyWithCountUp,
  Divider,
  S3,
  S4,
  S5,
  Tooltip,
  HelpIcon,
  S6,
} from '@uniqkey-frontend/shared-app';

interface IDashboardEmployeesInfoWidgetActiveUsersItemProps {
  activeTitle: string;
  totalTitle: string;
  titleHelperText: string;
  linkText: string;
  activeCount: number;
  totalCount: number;
  onClick: () => void;
  showDivider?: boolean;
}

const VALUE_LENGTH_TO_WIDTH = {
  1: '1ch',
  2: '2ch',
  3: '3ch',
  4: '4ch',
};

const getWidthFromValue = (value: number) => {
  const valueLength = value.toString().length;
  return VALUE_LENGTH_TO_WIDTH[valueLength as keyof typeof VALUE_LENGTH_TO_WIDTH] ?? '5ch';
};

const DashboardEmployeesInfoWidgetActiveUsersItem = (
  props: IDashboardEmployeesInfoWidgetActiveUsersItemProps,
) => {
  const {
    onClick,
    activeTitle,
    totalTitle,
    titleHelperText,
    linkText,
    activeCount,
    totalCount,
    showDivider = true,
  } = props;
  const activeCountWidth = useMemo(() => getWidthFromValue(activeCount), [activeCount]);
  const totalCountWidth = useMemo(() => getWidthFromValue(totalCount), [totalCount]);

  return (
    <Grid item container xs={3} flexDirection="column" alignItems="center">
      <Grid item container flexDirection="row" alignItems="center">
        <Grid item container xs={1.5} flexDirection="column" alignItems="center" />
        <Grid
          item
          container
          xs={4}
          flexDirection="column"
          alignItems="center"
          alignContent="flex-end"
        >
          <Grid item mt={2}>
            <Typography variant="caption" color={S5} noWrap>
              {activeTitle}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <TypographyWithCountUp
              variant="h3"
              value={activeCount}
              minWidth={activeCountWidth}
              color={S6}
            />
          </Grid>
        </Grid>
        <Grid item container xs={1} mt={6} flexDirection="column" alignItems="center">
          <Typography variant="h6" color={S3}>
            /
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={4}
          flexDirection="column"
          alignItems="center"
          alignContent="flex-start"
        >
          <Grid item mt={2}>
            <Typography color={S4} variant="caption" noWrap>
              {totalTitle}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <TypographyWithCountUp
              variant="h3"
              value={totalCount}
              color={S4}
              minWidth={totalCountWidth}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={1.5}
          flexDirection="column"
          alignItems="center"
          alignContent="flex-start"
          mb={8}
        >
          <Tooltip title={titleHelperText} color={S5} cursorPointer>
            <HelpIcon />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item mt={4} mb={1}>
        <Typography variant="subtitle1" asLink onClick={onClick}>
          {linkText}
        </Typography>
      </Grid>
      {showDivider && <Divider orientation="vertical" />}
    </Grid>
  );
};

export default memo(DashboardEmployeesInfoWidgetActiveUsersItem);
