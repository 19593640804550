import {
  type VaultPasswordCreationRequestedPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultPasswordCreation from '../common/handleAdminVaultPasswordCreation';

const ERROR_PREFIX = 'TrustedPortal/handleVaultPasswordCreationRequestedEvent';

const handleVaultPasswordCreationRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultPasswordCreationRequestedPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { password } = parsedPayload;

  const {
    encryptedPassword,
    strength,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultPasswordCreation({
    organizationPublicKey,
    password,
    errorPrefix: ERROR_PREFIX,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueVaultPasswordCreationRequestedApprovePost({
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
      queueMessageId,
      password: encryptedPassword,
      totpToken: null,
      strength,
    });
};

export default handleVaultPasswordCreationRequestedEvent;
