import { useCallback, memo } from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  useTranslations,
  SearchableTextField,
  usePubSub,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import useOrganizationLoginEmployeesToGroupsTable
  from '../../../../hooks/tables/useOrganizationLoginEmployeesToGroupsTable';
import OrganizationLoginEmployeesToGroupsTable
  from '../../../../components/tables/OrganizationLoginEmployeesToGroupsTable';

interface IOrganizationLoginEmployeesToGroupsTabProps {
  vaultId: string;
}

const OrganizationLoginEmployeesToGroupsTab = (
  props: IOrganizationLoginEmployeesToGroupsTabProps,
) => {
  const { vaultId } = props;
  const { t } = useTranslations();

  const {
    refetch,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps
  } = useOrganizationLoginEmployeesToGroupsTable({
    persistentFilters: { vaultId },
    noDataMessageKey: 'table.noData.default',
  });

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT, handleRefetch);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" />
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationLoginEmployeesToGroupsTable {...restTableProps} />
    </PanelContent>
  );
};

export default memo(OrganizationLoginEmployeesToGroupsTab);
