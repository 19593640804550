import { useCallback, memo, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Panel, PanelHeader, Tab, Tabs, useTranslations,
} from '@uniqkey-frontend/shared-app';
import OrganizationLoginsTab from './components/OrganizationLoginsTab';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../helpers/tablePreservation/reset';
import OrganizationCreditCardsTab from './components/OrganizationCreditCardsTab';
import OrganizationSecureNotesTab from './components/OrganizationSecureNotesTab';

export enum AllSecuredDataPageTabEnum {
  Logins = 'logins',
  CreditCards = 'payment-cards',
  SecureNotes = 'secure-notes',
}

interface IAllSecuredDataPageProps {
  activeTab: AllSecuredDataPageTabEnum;
}

const AllSecuredDataPage = (props: IAllSecuredDataPageProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: AllSecuredDataPageTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.AllSecuredData}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [activeTab, navigate]);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3}>
        <Tabs value={activeTab}>
          <Tab
            label={t('allSecuredDataPage.tabs.logins')}
            value={AllSecuredDataPageTabEnum.Logins}
            onClick={handleTabClick}
          />
          <Tab
            label={t('allSecuredDataPage.tabs.creditCards')}
            value={AllSecuredDataPageTabEnum.CreditCards}
            onClick={handleTabClick}
          />
          <Tab
            label={t('allSecuredDataPage.tabs.secureNotes')}
            value={AllSecuredDataPageTabEnum.SecureNotes}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      {activeTab === AllSecuredDataPageTabEnum.Logins && (
        <OrganizationLoginsTab />
      )}
      {activeTab === AllSecuredDataPageTabEnum.CreditCards && (
        <OrganizationCreditCardsTab />
      )}
      {activeTab === AllSecuredDataPageTabEnum.SecureNotes && (
        <OrganizationSecureNotesTab />
      )}
    </Panel>
  );
};

export default memo(AllSecuredDataPage);
