import { encryptAsymmetric, decryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultSharingToEmployeeAccountRequestedPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultSharingToEmployeeAccountRequestedEvent';

const handleAdminVaultSharingToEmployeeAccountRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultSharingToEmployeeAccountRequestedPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
    organizationPrivateKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    vaultSymmetricKey,
    employeeAccountId,
    employeeAccountKeyPairPublic,
  } = parsedPayload;

  if (!vaultSymmetricKey) {
    throw new Error(`${ERROR_PREFIX} no vaultSymmetricKey in event`);
  }

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!employeeAccountKeyPairPublic) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountKeyPairPublic in event`);
  }

  const decryptedSymmetricKey = await decryptAsymmetric({
    cipher: vaultSymmetricKey,
    publicKey: organizationPublicKey,
    privateKey: organizationPrivateKey,
  });

  const encryptedSymmetricKey = await encryptAsymmetric({
    string: decryptedSymmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminAddRequestedForEmployeeApprovePost({
      vaultSymmetricKey: encryptedSymmetricKey,
      queueMessageId,
    });
};

export default handleAdminVaultSharingToEmployeeAccountRequestedEvent;
