import { memo } from 'react';
import { Grid, PanelContent } from '@uniqkey-frontend/shared-app';
import GroupDetailsWidget from '../GroupDetailsWidget';

interface IGroupDetailsTabProps {
  groupId: string;
}

const GroupDetailsTab = (props: IGroupDetailsTabProps) => {
  const { groupId } = props;

  return (
    <PanelContent p={3} pt={1} className="height-100-percent">
      <Grid container>
        <Grid item xs={6}>
          <GroupDetailsWidget groupId={groupId} />
        </Grid>
      </Grid>
    </PanelContent>
  );
};

export default memo(GroupDetailsTab);
