import { memo } from 'react';
import {
  Dialog, DialogTitle, DialogContent, QrCode, Typography, Grid, useTranslations,
} from '@uniqkey-frontend/shared-app';

interface IUserModalDialogProps {
  handleToggle: () => void;
  isModalOpen: boolean;
}

const InstallMobileAppModal = (props: IUserModalDialogProps) => {
  const { handleToggle, isModalOpen } = props;
  const { t } = useTranslations();

  return (
    <Dialog onClose={handleToggle} open={isModalOpen} maxWidth="sm">
      <DialogTitle onClose={handleToggle}>
        {t('migration.installMobileAppModal.title')}
      </DialogTitle>
      <DialogContent>
        <Grid item container justifyContent="center" alignItems="center">
          <QrCode size={250} padding={0} value="https://onelink.to/business-passwords" />
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" mt={3}>
          <Typography variant="subtitle1">
            {t('migration.installMobileAppModal.description')}
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(InstallMobileAppModal);
