import { GetUserProfileResponse, UserApi } from '@uniqkey-backend-organization-web/api-client';
import { userAxiosInstanceWithoutLogout } from '../../axios';
import config from '../../config';
import { dataExtractor } from '../../helpers/apiClients';

const API_URL = config.getWebApiUrl();

export const getUserAPIClient = () => new UserApi(
  undefined,
  API_URL,
  userAxiosInstanceWithoutLogout,
);

// eslint-disable-next-line import/prefer-default-export
export const getCurrentUserProfileWithoutLogout = async () => getUserAPIClient()
  .apiV1UserCurrentUserProfileGet()
  .then(dataExtractor<GetUserProfileResponse>());
