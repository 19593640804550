import { IKeyPair } from '@uniqkey-frontend/shared-app';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import { type ICompanionApplicationContext } from '../../contexts/CompanionApplicationContext';

export interface ICompanionApplicationData {
  symmetricKey: string;
  asymmetricKeys: IKeyPair;
  guid: string;
}

type TSymmetricKey = ICompanionApplicationContext['symmetricKey'] | null;
type TCompanionApplicationId = ICompanionApplicationContext['guid'] | null;

export const getSymmetricKey = (): TSymmetricKey => localStorage.getItem(
  LocalStorageKeyEnum.SymmetricKey,
);
export const setSymmetricKey = (symmetricKey: TSymmetricKey) => {
  if (!symmetricKey) {
    localStorage.removeItem(LocalStorageKeyEnum.SymmetricKey);
    return;
  }
  localStorage.setItem(LocalStorageKeyEnum.SymmetricKey, symmetricKey);
};

export const getCompanionApplicationId = (): TCompanionApplicationId => localStorage.getItem(
  LocalStorageKeyEnum.Guid,
);
export const setCompanionApplicationId = (guid: TCompanionApplicationId) => {
  if (!guid) {
    localStorage.removeItem(LocalStorageKeyEnum.Guid);
    return;
  }
  localStorage.setItem(LocalStorageKeyEnum.Guid, guid);
};

export const removeCompanionApplicationData = () => {
  setSymmetricKey(null);
  localStorage.removeItem(LocalStorageKeyEnum.AsymmetricKeys);
  setCompanionApplicationId(null);
};

export const storeCompanionApplicationData = (params: ICompanionApplicationData) => {
  const { symmetricKey, asymmetricKeys, guid } = params;
  setSymmetricKey(symmetricKey);
  localStorage.setItem(LocalStorageKeyEnum.AsymmetricKeys, JSON.stringify(asymmetricKeys));
  setCompanionApplicationId(guid);
};
