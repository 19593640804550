import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  getEmployeesTokens,
  getUserTokens,
  logout,
} from '../services/authService';
import { getActiveOrganizationId } from '../services/organizationService';
import refreshTokens from '../services/tokensManager';
import config from '../config';

const WEB_API_BASE_URL = config.getWebApiUrl();

const publicAxiosInstance = axios.create({
  baseURL: WEB_API_BASE_URL,
  timeout: config.requestTimeout,
});

const employeeAxiosInstance = axios.create({
  baseURL: WEB_API_BASE_URL,
  timeout: config.requestTimeout,
});

const userAxiosInstance = axios.create({
  baseURL: WEB_API_BASE_URL,
  timeout: config.requestTimeout,
});

const userAxiosInstanceWithoutLogout = axios.create({
  baseURL: WEB_API_BASE_URL,
  timeout: config.requestTimeout,
});

const getCurrentUserToken = () => {
  const userTokens = getUserTokens();
  if (!userTokens) {
    throw new Error('No user tokens');
  }

  const { token } = userTokens;
  return token;
};

const getCurrentEmployeeToken = () => {
  const activeOrganizationId = getActiveOrganizationId();
  const employeeTokens = getEmployeesTokens();

  if (!activeOrganizationId) throw new Error('No active organization id');
  // todo add auto activeOrganizationId selector here instead of throw new Error

  if (!employeeTokens) {
    throw new Error('No employee tokens');
  }

  const activeEmployeeTokens = employeeTokens[activeOrganizationId];

  if (!activeEmployeeTokens) throw new Error('No active employee token');

  const { token } = activeEmployeeTokens;

  return token;
};

interface IHandleAuthAttachParams {
  instance: AxiosInstance
  getToken: () => string;
}

const createAuthAttachInterceptor = (params: IHandleAuthAttachParams) => {
  const { instance, getToken } = params;
  instance.interceptors.request.use(async (axiosRequestConfig) => {
    const token = getToken();
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    axiosRequestConfig.headers.Authorization = `Bearer ${token}`;
    return axiosRequestConfig;
  });
};

interface IHandleAuthRefreshParams {
  getToken: () => string;
  withLogout?: boolean;
}

const handleAuthRefresh = (params: IHandleAuthRefreshParams) => async (failedRequest: any) => {
  const { getToken, withLogout = true } = params;
  try {
    await refreshTokens();
    const validToken = getToken();
    if (!validToken) throw new Error('Token not found');
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${validToken}`;
  } catch (e) {
    if (withLogout) {
      logout({ showMessage: true });
    }
  }
};

createAuthAttachInterceptor({
  getToken: getCurrentEmployeeToken,
  instance: employeeAxiosInstance,
});

createAuthAttachInterceptor({
  getToken: getCurrentUserToken,
  instance: userAxiosInstance,
});
createAuthAttachInterceptor({
  getToken: getCurrentUserToken,
  instance: userAxiosInstanceWithoutLogout,
});

createAuthRefreshInterceptor(
  employeeAxiosInstance,
  handleAuthRefresh({
    getToken: getCurrentEmployeeToken,
  }),
  { pauseInstanceWhileRefreshing: true },
);

createAuthRefreshInterceptor(
  userAxiosInstance,
  handleAuthRefresh({
    getToken: getCurrentUserToken,
  }),
  { pauseInstanceWhileRefreshing: true },
);

createAuthRefreshInterceptor(
  userAxiosInstanceWithoutLogout,
  handleAuthRefresh({
    withLogout: false,
    getToken: getCurrentUserToken,
  }),
  { pauseInstanceWhileRefreshing: true },
);

export {
  employeeAxiosInstance,
  userAxiosInstance,
  userAxiosInstanceWithoutLogout,
  publicAxiosInstance,
};
