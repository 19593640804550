import { memo } from 'react';
import {
  Dialog, DialogTitle, Grid, Typography, useTranslations,
} from '@uniqkey-frontend/shared-app';
import MigrationProcessImage from '../../../../images/MigrationProcessImage';

interface IMigrationProcessModalProps {
  isModalOpen: boolean;
  name: string;
}

const MigrationProcessModal = (props: IMigrationProcessModalProps) => {
  const { isModalOpen, name } = props;
  const { t } = useTranslations();

  return (
    <Dialog open={isModalOpen} maxWidth="sm">
      <DialogTitle>
        {t('migration.migrationProcessModal.title', { name })}
      </DialogTitle>
      <Grid container justifyContent="center" mb={3}>
        <Grid item>
          <MigrationProcessImage />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" textAlign="center">
            {t('migration.migrationProcessModal.description')}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default memo(MigrationProcessModal);
