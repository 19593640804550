import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  getJWTTokens, logout,
} from '../services/authService';
import refreshTokens from '../services/tokensManager';
import config from '../../config';
import { logException } from '../../services/sentryService';

interface IHandleAuthAttachParams {
  instance: AxiosInstance;
  getToken: () => string;
}

const axiosInstance = axios.create({
  baseURL: config.getWebApiUrl(),
  timeout: config.requestTimeout,
});

const getCurrentUserToken = () => {
  const jwtTokens = getJWTTokens();
  if (!jwtTokens) {
    throw new Error('No jwt tokens');
  }
  const { token } = jwtTokens;
  return token;
};

const createAuthAttachInterceptor = (params: IHandleAuthAttachParams) => {
  const { instance, getToken } = params;
  instance.interceptors.request.use(async (axiosRequestConfig) => {
    const token = getToken();
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    axiosRequestConfig.headers.Authorization = `Bearer ${token}`;
    return axiosRequestConfig;
  });
};

interface IHandleAuthRefreshParams {
  getToken: () => string;
}

const handleAuthRefresh = (params: IHandleAuthRefreshParams) => async (failedRequest: any) => {
  try {
    const { getToken } = params;
    await refreshTokens();
    const validToken = getToken();
    if (!validToken) throw new Error('Token not found');
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${validToken}`;
  } catch (e) {
    logout({ showMessage: true });
    logException(e, { message: 'migrationModules/handleAuthRefresh exception' });
  }
};

createAuthAttachInterceptor({
  getToken: getCurrentUserToken,
  instance: axiosInstance,
});

createAuthRefreshInterceptor(
  axiosInstance,
  handleAuthRefresh({
    getToken: getCurrentUserToken,
  }),
  { pauseInstanceWhileRefreshing: true },
);

export default axiosInstance;
