import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { GetEmployeeAccountByIdResponse } from '@uniqkey-backend-organization-web/api-client';
import { useGetEmployeeAccountById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import EmployeeDetailsTab from '../EmployeeDetailsTab';
import EmployeeLoginsTab from '../EmployeeLoginsTab';
import EmployeeGroupsTab from '../EmployeeGroupsTab';
import EmployeeSecureNotesTab from '../EmployeeSecureNotesTab';
import EmployeeCreditCardsTab from '../EmployeeCreditCardsTab';

export enum EmployeeModuleTabEnum {
  Details = 'details',
  Logins = 'logins',
  CreditCards = 'payment-cards',
  SecureNotes = 'secure-notes',
  Groups = 'groups'
}

interface IEmployeeModuleProps {
  activeTab: EmployeeModuleTabEnum;
}

interface IEmployeeModuleContext {
  employeeAccountId: string;
}

const EmployeeModule = (props: IEmployeeModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { employeeAccountId } = useOutletContext<IEmployeeModuleContext>();
  const navigate = useNavigate();

  const { isLoading, data: employeeAccount } = useGetEmployeeAccountById(
    { employeeAccountId },
  );
  const { employeeAccountStatus } = employeeAccount ?? {} as GetEmployeeAccountByIdResponse;

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: EmployeeModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Employees}/${employeeAccountId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [employeeAccountId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === EmployeeModuleTabEnum.Details;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('employeePage.tabs.employeeDetails')}
            value={EmployeeModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          <Tab
            label={t('employeePage.tabs.logins')}
            value={EmployeeModuleTabEnum.Logins}
            onClick={handleTabClick}
          />
          <Tab
            label={t('employeePage.tabs.creditCards')}
            value={EmployeeModuleTabEnum.CreditCards}
            onClick={handleTabClick}
          />
          <Tab
            label={t('employeePage.tabs.secureNotes')}
            value={EmployeeModuleTabEnum.SecureNotes}
            onClick={handleTabClick}
          />
          <Tab
            label={t('employeePage.tabs.groups')}
            value={EmployeeModuleTabEnum.Groups}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      {activeTab === EmployeeModuleTabEnum.Details && (
        <EmployeeDetailsTab employeeAccountId={employeeAccountId} />
      )}
      {activeTab === EmployeeModuleTabEnum.Logins && (
        <EmployeeLoginsTab
          employeeAccountId={employeeAccountId}
          employeeAccountStatus={employeeAccountStatus}
        />
      )}
      {activeTab === EmployeeModuleTabEnum.CreditCards && (
        <EmployeeCreditCardsTab
          employeeAccountId={employeeAccountId}
          employeeAccountStatus={employeeAccountStatus}
        />
      )}
      {activeTab === EmployeeModuleTabEnum.SecureNotes && (
        <EmployeeSecureNotesTab
          employeeAccountId={employeeAccountId}
          employeeAccountStatus={employeeAccountStatus}
        />
      )}
      {activeTab === EmployeeModuleTabEnum.Groups && (
        <EmployeeGroupsTab
          employeeAccountId={employeeAccountId}
          employeeAccountStatus={employeeAccountStatus}
        />
      )}
    </Panel>
  );
};

export default memo(EmployeeModule);
