import {
  useCallback, useEffect, useMemo, useState, ReactNode,
} from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar, useTranslations, usePubSub } from '@uniqkey-frontend/shared-app';
import { EmployeeTokenInfo } from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import UserContext from '.';
import useUserActivityChecker from '../../hooks/useUserActivityChecker';
import { useGetCurrentUser, useGetCurrentEmployee } from '../../hooks/reactQuery';
import {
  getEmployeesTokens,
  logout,
  isAuthenticated as initialIsAuthenticated,
  type ILogoutParams,
} from '../../services/authService';
import { Dictionary } from '../../types/common';
import { clearContexts, setUserContext } from '../../services/sentryService';

const CURRENT_USER_REACT_QUERY_STALE_TIME = 60 * 1000;

interface IUserContextParams {
  children: ReactNode,
}

const UserProviderContext = (props: IUserContextParams) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => initialIsAuthenticated());
  const [employeeTokens, setEmployeeTokens] = useState<Dictionary<EmployeeTokenInfo> | null>(
    () => getEmployeesTokens(),
  );
  const { t, setLanguage, currentLanguage } = useTranslations();
  const { showError } = useSnackbar();
  const queryClient = useQueryClient();

  const handleSetEmployeeTokens = useCallback(() => {
    setEmployeeTokens(() => getEmployeesTokens());
  }, []);

  const handleLogin = useCallback(() => {
    setIsAuthenticated(true);
    handleSetEmployeeTokens();
  }, [handleSetEmployeeTokens]);

  const handleLogout = useCallback((message: string, data: Partial<ILogoutParams>) => {
    setIsAuthenticated((prevIsAuthenticated) => {
      if (!prevIsAuthenticated) {
        return false;
      }
      const { showMessage } = data ?? {};
      if (showMessage) {
        showError({ text: t('common.unauthorizedError') });
      }
      queryClient.clear();
      return false;
    });
    setEmployeeTokens(null);
  }, [showError, queryClient, t]);

  usePubSub(PubSubEventEnum.LOGIN, handleLogin);

  usePubSub(PubSubEventEnum.LOGOUT, handleLogout);

  useUserActivityChecker(isAuthenticated);

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    refetch: refetchCurrentUser,
  } = useGetCurrentUser({
    onSuccess: async (data) => {
      if (currentLanguage === data.language) {
        return undefined;
      }
      return setLanguage(data.language);
    },
    onError: () => {
      logout({ showMessage: true });
    },
    enabled: isAuthenticated,
    // will keep the query fresh and won't refetch so often
    staleTime: CURRENT_USER_REACT_QUERY_STALE_TIME,
  });

  const {
    data: currentEmployee,
    isLoading: isCurrentEmployeeLoading,
    refetch: refetchCurrentEmployee,
  } = useGetCurrentEmployee({
    enabled: isAuthenticated,
    // will keep the query fresh and won't refetch so often
    staleTime: CURRENT_USER_REACT_QUERY_STALE_TIME,
  });

  const handleRefetch = useCallback(() => {
    refetchCurrentUser();
    refetchCurrentEmployee();
  }, [refetchCurrentUser, refetchCurrentEmployee]);

  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_CURRENT_USER, handleRefetch);

  usePubSub(PubSubEventEnum.TOKENS_REFRESHED, handleSetEmployeeTokens);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === LocalStorageKeyEnum.UserTokens) {
        setIsAuthenticated(!!event.newValue);
      }
      if (event.key === LocalStorageKeyEnum.EmployeesTokens) {
        handleSetEmployeeTokens();
      }
    };
    // NOTE: add listener storage - not working on the current tab
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [handleSetEmployeeTokens]);

  useEffect(() => {
    if (!currentUser) {
      clearContexts();
      return;
    }
    setUserContext({
      id: currentUser.userId,
    });
  }, [currentUser]);

  const value = useMemo(() => ({
    currentUser: currentUser ?? null,
    currentEmployee: currentEmployee ?? null,
    isAuthenticated,
    isCurrentUserLoading: isCurrentEmployeeLoading || isCurrentUserLoading,
    employeeTokens,
  }), [
    currentUser,
    currentEmployee,
    isAuthenticated,
    isCurrentEmployeeLoading,
    isCurrentUserLoading,
    employeeTokens,
  ]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProviderContext;
