import { Grid } from '@uniqkey-frontend/shared-app';
// import DashboardUnmanagedLoginsWidget from './components/DashboardUnmanagedLoginsWidget';
// import DashboardTopUsedServicesWidget from './components/DashboardTopUsedServicesWidget';
// import DashboardUnderutilizedLicensesWidget from
//   './components/DashboardUnderutilizedLicensesWidget';
import DashboardMostUnsecuredServicesWidget from
  './components/DashboardMostUnsecuredServicesWidget';
import DashboardUnusedServicesWidget from './components/DashboardUnusedServicesWidget';

const DashboardServicesAndLoginsWidgets = () => (
  <Grid container spacing={3}>
    {/* <Grid item container xs={12} lg={6} spacing={3}> */}
    {/*  <Grid item xs={12}> */}
    {/*    <DashboardUnmanagedLoginsWidget /> */}
    {/*  </Grid> */}
    {/*  <Grid item xs={12}> */}
    {/*    <DashboardTopUsedServicesWidget /> */}
    {/*  </Grid> */}
    {/* </Grid> */}
    {/* <Grid item xs={12} lg={6}> */}
    {/*  <DashboardUnderutilizedLicensesWidget /> */}
    {/* </Grid> */}
    <Grid item xs={12} lg={6}>
      <DashboardMostUnsecuredServicesWidget />
    </Grid>
    <Grid item xs={12} lg={6}>
      <DashboardUnusedServicesWidget />
    </Grid>
  </Grid>
);

export default DashboardServicesAndLoginsWidgets;
