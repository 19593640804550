import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import PubSub from 'pubsub-js';
import {
  Box, MenuItem, SideMenuSelect, usePubSub, TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useUser } from '../../../../../contexts/UserContext';
import {
  getActiveOrganizationId, setActiveOrganizationId,
} from '../../../../../services/organizationService';
import PageRouteEnum from '../../../../../enums/PageRouteEnum';
import PubSubEventEnum from '../../../../../enums/PubSubEventEnum';
import { triggerTokensRefresh } from '../../../../../services/tokensManager';
import LocalStorageKeyEnum from '../../../../../enums/LocalStorageKeyEnum';
import { getEmployeesTokens } from '../../../../../services/authService';

const MENU_PROPS = { sx: { maxWidth: 248 } };

const OrganizationSelector = () => {
  const [organizationId, setOrganizationId] = useState<string | null>(
    () => getActiveOrganizationId(),
  );
  const { employeeTokens } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const organizations = useMemo(() => Object.values(employeeTokens ?? {}), [employeeTokens]);
  const changeOrganization = useCallback((orgId: string) => {
    setOrganizationId(orgId);
    setActiveOrganizationId(orgId);
    queryClient.invalidateQueries();
    navigate(PageRouteEnum.Dashboard);
    PubSub.publish(PubSubEventEnum.ACTIVE_ORGANIZATION_CHANGED);
  }, [navigate, queryClient]);
  const handleOrganizationChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => {
    changeOrganization(event.target.value as string);
  }, [changeOrganization]);
  const menuItems = useMemo(() => organizations.map((org) => (
    <MenuItem value={org.organizationId} key={org.organizationId}>
      {org.organizationName}
    </MenuItem>
  )), [organizations]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_ORGANIZATION_DETAILS, triggerTokensRefresh);

  const handleTokensRefreshed = useCallback(() => {
    if (!organizationId) {
      return;
    }
    const newTokens = getEmployeesTokens();
    if (!newTokens) {
      return;
    }
    if (!newTokens[organizationId]) {
      changeOrganization(organizations[0].organizationId);
    }
  }, [organizations, organizationId, changeOrganization]);

  usePubSub(PubSubEventEnum.TOKENS_REFRESHED, handleTokensRefreshed);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === LocalStorageKeyEnum.ActiveOrganizationId) {
        setOrganizationId(event.newValue);
        queryClient.invalidateQueries();
        navigate(PageRouteEnum.Dashboard);
      }
    };
    // NOTE: add listener storage - not working on the current tab
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [navigate, queryClient]);

  if (organizations.length <= 1) return null;

  return (
    <Box ml={1}>
      <SideMenuSelect
        fullWidth
        disableMinWidth
        value={organizationId}
        onChange={handleOrganizationChange}
        MenuProps={MENU_PROPS}
      >
        {menuItems}
      </SideMenuSelect>
    </Box>
  );
};

export default OrganizationSelector;
