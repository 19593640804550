import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetScimSettingsResponse,
  UpdateScimSettingsRequest,
  NoContentResult,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useOrganizationScimSettingsAPI from '../../useOrganizationScimSettingsAPI';

export const useGetOrganizationScimSettings = (
  options: TUseQueryOptions<GetScimSettingsResponse> = {},
) => {
  const { getOrganizationScimSettings } = useOrganizationScimSettingsAPI();
  return useQuery<GetScimSettingsResponse>(
    ReactQueryKeyEnum.OrganizationScimSettings,
    ({ signal }) => getOrganizationScimSettings({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateOrganizationScimSettingsParams {
  useOptimisticUpdates?: boolean;
}
export type TUseUpdateOrganizationScimSettingsOptions = TUseMutationOptions<
  NoContentResult,
  unknown,
  UpdateScimSettingsRequest,
  TUseMutationContext<UpdateScimSettingsRequest>
>;
export const useUpdateOrganizationScimSettings = (
  params: IUseUpdateOrganizationScimSettingsParams,
  options: TUseUpdateOrganizationScimSettingsOptions = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateOrganizationScimSettings } = useOrganizationScimSettingsAPI();
  const mutationKey = ReactQueryKeyEnum.OrganizationScimSettings;
  return useMutation(mutationKey, (scimSettings) => updateOrganizationScimSettings(scimSettings), {
    onMutate: async (newScimSettings: UpdateScimSettingsRequest) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      await queryClient.cancelQueries(mutationKey);
      const previousValue = queryClient.getQueryData<UpdateScimSettingsRequest>(mutationKey);
      queryClient.setQueryData<UpdateScimSettingsRequest>(mutationKey, (oldScimSettings) => ({
        ...oldScimSettings,
        ...newScimSettings,
      }));
      return { previousValue: previousValue as UpdateScimSettingsRequest };
    },
    onError: (err, scimSettings, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<UpdateScimSettingsRequest>(mutationKey, context.previousValue);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(mutationKey);
    },
    ...options,
  });
};
