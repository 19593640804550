import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  getTimeFromNow,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetManagedByEmployeesByVaultIdResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';

export interface IOrganizationLoginEmployeesToEmployeesTableRow
extends GetManagedByEmployeesByVaultIdResponseModel {
  managedBy: string;
  lastActivity: string | null;
}

interface IOrganizationLoginEmployeesToEmployeesTableProps extends
  Omit<
    IUseTableReturn<IOrganizationLoginEmployeesToEmployeesTableRow>,
    'selectedRows' | 'resetTableMethods'
  >,
  Omit<IUseTableFetchReturn<GetManagedByEmployeesByVaultIdResponseModel>, 'data' | 'refetch'>
{
  employees: IUseTableFetchReturn<GetManagedByEmployeesByVaultIdResponseModel>['data'];
  selectedEmployees: IUseTableReturn<
    IOrganizationLoginEmployeesToEmployeesTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationLoginEmployeesToEmployeesTableRow> = {
  selection: false,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationLoginEmployeesToEmployeesTable = (
  props: IOrganizationLoginEmployeesToEmployeesTableProps,
) => {
  const {
    employees: preEmployees,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedEmployees,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const employees = useMemo(() => preEmployees.map((employee) => {
    const { sharingInformation } = employee;
    return {
      ...employee,
      managedBy: sharingInformation.isCreatedDirectly
        ? t('organizationLoginPage.employeesToEmployeesTab.table.createdDirectly')
        : t(
          'organizationLoginPage.employeesToEmployeesTab.table.sharedByEmail',
          { email: sharingInformation.creatorEmail },
        ),
      lastActivity: getTimeFromNow({
        date: employee.activityAt,
        locale: currentLanguage,
      }),
    };
  }), [preEmployees, currentLanguage, t]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationLoginPage.employeesToEmployeesTab.table.columns.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '30%',
    },
    {
      title: t('organizationLoginPage.employeesToEmployeesTab.table.columns.managedBy'),
      field: 'managedBy',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.managedBy}
        </TypographyWithTooltip>
      ),
      width: '45%',
      sorting: false,
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          // eslint-disable-next-line max-len
          title={t('organizationLoginPage.employeesToEmployeesTab.table.columns.lastActivity.columnTooltip')}
        >
          {t('organizationLoginPage.employeesToEmployeesTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
  ]), [createColumns, t]);

  const handleEmployeeRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationLoginEmployeesToEmployeesTableRow>['onRowClick']>
  >((event, employee) => {
    navigate(`${PageRouteEnum.Employees}/${employee!.employeeAccountId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={employees}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedEmployees}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleEmployeeRowClick}
      rowSelectByKey="employeeAccountId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationLoginEmployeesToEmployeesTable);
