import { createContext, useContext } from 'react';

export interface IUserContext {
  isAuthenticated: boolean;
}

const defaultContext: IUserContext = {
  isAuthenticated: false,
};

const UserContext = createContext<IUserContext>(defaultContext);

UserContext.displayName = 'UserContext';

export default UserContext;

export const useUser = (): IUserContext => useContext<IUserContext>(UserContext);
