import {
  type VaultCreditCardCreationRequestedPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultCreditCardCreation from '../common/handleAdminVaultCreditCardCreation';

const ERROR_PREFIX = 'TrustedPortal/handleVaultCreditCardCreationRequestedEvent';

const handleVaultCreditCardCreationRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultCreditCardCreationRequestedPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    cardNumber, cardHolderName, expiryDate, cvv,
  } = parsedPayload;

  if (!cardNumber) {
    throw new Error(`${ERROR_PREFIX} no cardNumber in event`);
  }

  const {
    encryptedCardNumber,
    encryptedCardHolderName,
    encryptedExpiryDate,
    encryptedCVV,
    creditCardInfo,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultCreditCardCreation({
    organizationPublicKey,
    cardNumber,
    cardHolderName,
    expiryDate,
    cvv,
    errorPrefix: ERROR_PREFIX,
  });
  const { lastDigits, paymentSystem } = creditCardInfo;

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueVaultCreditCardCreationRequestedApprovePost({
      queueMessageId,
      cardNumber: encryptedCardNumber,
      cardHolderName: encryptedCardHolderName,
      expiryDate: encryptedExpiryDate,
      cvv: encryptedCVV,
      lastDigits,
      paymentSystem,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleVaultCreditCardCreationRequestedEvent;
