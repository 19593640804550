import { useMemo } from 'react';
import useFilteredEntity, { IUseFilteredEntityParams } from '../useFilteredEntity';

interface IUseFilteredEntityIdsParams<TEntity, TValue>
  extends IUseFilteredEntityParams<TEntity, TValue> {
  idFieldName: keyof TEntity;
}

const useFilteredEntityIds = <TEntity extends object, TValue extends TEntity[keyof TEntity]>(
  params: IUseFilteredEntityIdsParams<TEntity, TValue>,
): string[] => {
  const {
    entities, values, filterByFieldName, idFieldName,
  } = params;
  const filteredEntities = useFilteredEntity<TEntity, TValue>(
    { entities, values, filterByFieldName },
  );
  const processedEntities = useMemo(() => (
    filteredEntities.map((entity) => entity[idFieldName] as string)
  ), [filteredEntities, idFieldName]);
  return processedEntities;
};

export default useFilteredEntityIds;
