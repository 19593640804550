import { TUseMutationOptions } from '@uniqkey-frontend/shared-app';
import {
  DetachBulkEmployeeAccountsToGroupRequest,
  DetachBulkEmployeeAccountsToGroupResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useMutation } from 'react-query';
import useEmployeeAccountsToGroupsAPI from '../../useEmployeeAccountsToGroupsAPI';

// TODO: remove this when more methods will be added
// eslint-disable-next-line import/prefer-default-export
export const useDetachBulkEmployeeAccountsToGroups = (
  options: TUseMutationOptions<
    DetachBulkEmployeeAccountsToGroupResponse,
    unknown,
    DetachBulkEmployeeAccountsToGroupRequest,
    void
  > = {},
) => {
  const { detachBulkEmployeeAccountsToGroup } = useEmployeeAccountsToGroupsAPI();
  return useMutation((
    detachBulkEmployeeAccountsToGroupRequest,
  ) => detachBulkEmployeeAccountsToGroup(detachBulkEmployeeAccountsToGroupRequest), {
    ...options,
  });
};
