import {
  useCallback, useEffect, useMemo, useState, ReactNode,
} from 'react';
import {
  generateGUID, generateSymmetricKey, generateAsymmetricKeys,
} from '@uniqkey-frontend/shared-app';
import CompanionApplicationContext from '.';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import {
  getCompanionApplicationId,
  getSymmetricKey,
  removeCompanionApplicationData,
  storeCompanionApplicationData,
  ICompanionApplicationData,
} from '../../services/companionApplicationService';
import { useUser } from '../UserContext';
import { logException } from '../../services/sentryService';

const CompanionApplicationProviderContext = (
  { children }: { children: ReactNode },
): JSX.Element => {
  const [asymmetricKeys, setAsymmetricKeys] = useState(() => {
    try {
      const storageValue = localStorage.getItem(LocalStorageKeyEnum.AsymmetricKeys);
      if (!storageValue) {
        return null;
      }
      return JSON.parse(storageValue);
    } catch (e) {
      logException(
        e,
        { message: 'CompanionApplicationProviderContext/asymmetricKeys state exception' },
      );
      return null;
    }
  });
  const { isAuthenticated } = useUser();

  const symmetricKey = useMemo(
    () => getSymmetricKey() || generateSymmetricKey(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated],
  );

  useEffect(() => {
    const generateKeys = async () => {
      try {
        if (!asymmetricKeys) {
          const keys = await generateAsymmetricKeys();
          setAsymmetricKeys(keys);
        }
      } catch (e) {
        logException(e, {
          message: 'CompanionApplicationProviderContext/generateKeys exception',
        });
      }
    };
    generateKeys();
  }, [asymmetricKeys]);

  const guid = useMemo(
    () => getCompanionApplicationId() || generateGUID(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated],
  );

  const saveCompanionApplicationData = useCallback((
    updatedSymmetricKey: ICompanionApplicationData['symmetricKey'] | undefined,
  ) => {
    try {
      storeCompanionApplicationData({
        guid, symmetricKey: updatedSymmetricKey ?? symmetricKey, asymmetricKeys,
      });
    } catch (e) {
      logException(e, {
        message: 'CompanionApplicationProviderContext/saveCompanionApplicationData exception',
      });
    }
  }, [guid, symmetricKey, asymmetricKeys]);

  const deleteCompanionApplicationData = useCallback(() => {
    removeCompanionApplicationData();
  }, []);

  const value = useMemo(() => ({
    saveCompanionApplicationData,
    deleteCompanionApplicationData,
    guid,
    symmetricKey,
    asymmetricKeys,
  }), [
    saveCompanionApplicationData,
    deleteCompanionApplicationData,
    guid,
    symmetricKey,
    asymmetricKeys,
  ]);

  return (
    <CompanionApplicationContext.Provider value={value}>
      {children}
    </CompanionApplicationContext.Provider>
  );
};

export default CompanionApplicationProviderContext;
