import { type ReactNode, useEffect } from 'react';
import PubSub from 'pubsub-js';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import { useCompanionApplicationContext } from '../../contexts/CompanionApplicationContext';

interface IInitializationContainerProps {
  children: ReactNode;
}
const InitializationContainer = (props: IInitializationContainerProps) => {
  const { children } = props;

  const { guid } = useCompanionApplicationContext();

  useEffect(() => {
    PubSub.publish(PubSubEventEnum.WS_CHANGE_URL, { guid });
  }, [guid]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment,react/react-in-jsx-scope
    <>{children}</>
  );
};

export default InitializationContainer;
