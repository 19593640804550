import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Filler,
  defaults,
  type ChartArea,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import {
  AB3, S2, S4, S5,
} from '@uniqkey-frontend/shared-app';

// register elements before overriding global defaults
ChartJS.register(TimeScale, LinearScale, LineElement, PointElement, Tooltip, Filler);

// global configs
defaults.borderColor = S2;
defaults.color = S4;

defaults.font.family = 'Poppins, serif';
defaults.font.size = 10;
defaults.font.weight = 500;
defaults.font.lineHeight = 1.5;

defaults.scale.ticks.padding = 8;

defaults.elements.line.tension = 0.3;
defaults.elements.line.borderColor = AB3;
defaults.elements.line.borderWidth = 2;

ChartJS.overrides.line = {
  ...ChartJS.overrides.line,
  clip: 5,
  spanGaps: true,
};

defaults.elements.point.hoverBackgroundColor = AB3;
defaults.elements.point.hoverBorderColor = AB3;
defaults.elements.point.radius = 0;
defaults.elements.point.hoverRadius = 4.5;

defaults.transitions.active.animation.duration = 0;

defaults.plugins.tooltip.backgroundColor = `${S2}F2`;
defaults.plugins.tooltip.titleColor = S5;
defaults.plugins.tooltip.titleFont = {
  size: 12,
  weight: 600,
  lineHeight: 1.8,
};
defaults.plugins.tooltip.titleAlign = 'center';
defaults.plugins.tooltip.titleSpacing = 0;
defaults.plugins.tooltip.titleMarginBottom = 0;

defaults.plugins.tooltip.bodyColor = S5;
defaults.plugins.tooltip.bodyAlign = 'center';

defaults.plugins.tooltip.padding = {
  top: 8, right: 16, bottom: 8, left: 16,
} as ChartArea;
defaults.plugins.tooltip.caretPadding = 5;
defaults.plugins.tooltip.caretSize = 0;
defaults.plugins.tooltip.cornerRadius = 8;
defaults.plugins.tooltip.displayColors = false;

// global configs end
