import { memo, useCallback } from 'react';
import {
  ClickableContainer, Grid, Typography, S4, Box,
} from '@uniqkey-frontend/shared-app';
import { Ownership, RestrictionType } from '@uniqkey-backend-organization-web/api-client';
import AvatarWithIcon from '../AvatarWithIcon';

export interface ISelectionModalOptionProps {
  title: string;
  description: string;
  type: TSelectionOptionType;
  onClick: (type: TSelectionOptionType) => void;
}

export type TSelectionOptionType = typeof RestrictionType.IpAddress | typeof RestrictionType.Time
  | typeof RestrictionType.Location | typeof Ownership.Employees | typeof Ownership.Groups;

const SelectionModalOption = (props: ISelectionModalOptionProps) => {
  const {
    title, description, type, onClick,
  } = props;
  const handleClick = useCallback(() => onClick(type), [onClick, type]);

  return (
    <ClickableContainer container alignItems="center" onClick={handleClick}>
      <Grid item xs="auto" mr={0.5}>
        <AvatarWithIcon type={type} avatarSize={40} iconSize={24} />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Box mt={0.5} />
        <Typography variant="body2" color={S4}>
          {description}
        </Typography>
      </Grid>
    </ClickableContainer>
  );
};

export default memo(SelectionModalOption);
