import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetCurrentOrganizationResponse,
  Operation,
  NoContentResult,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IReplacePatchOperation, parseReplacePatchOperation } from '../../../helpers/apiClients';
import useOrganizationAPI from '../../useOrganizationAPI';

export const useGetCurrentOrganization = (
  options: TUseQueryOptions<GetCurrentOrganizationResponse> = {},
) => {
  const { getCurrentOrganization } = useOrganizationAPI();
  return useQuery<GetCurrentOrganizationResponse>(
    ReactQueryKeyEnum.Organization,
    ({ signal }) => getCurrentOrganization({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUsePatchCurrentOrganizationParams {
  useOptimisticUpdates?: boolean;
}
export const usePatchCurrentOrganization = (
  params: IUsePatchCurrentOrganizationParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Operation[],
    TUseMutationContext<GetCurrentOrganizationResponse>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { patchCurrentOrganization } = useOrganizationAPI();
  const mutationKey = ReactQueryKeyEnum.Organization;
  return useMutation(mutationKey, (operations) => patchCurrentOrganization(operations), {
    onMutate: async (operations) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      const updatedParts = operations.reduce((acc, operation) => ({
        ...acc,
        ...parseReplacePatchOperation(operation as IReplacePatchOperation),
      }), {});
      await queryClient.cancelQueries(mutationKey);
      const previousValue = queryClient.getQueryData<GetCurrentOrganizationResponse>(mutationKey);
      queryClient.setQueryData<GetCurrentOrganizationResponse>(
        mutationKey,
        (oldOrganization) => ({
          ...oldOrganization,
          ...updatedParts as GetCurrentOrganizationResponse,
        }),
      );
      return { previousValue: previousValue as GetCurrentOrganizationResponse };
    },
    onError: (err, organization, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<GetCurrentOrganizationResponse>(
          mutationKey,
          context.previousValue,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(mutationKey);
    },
    ...options,
  });
};
