import { memo } from 'react';
import { Grid, PanelContent } from '@uniqkey-frontend/shared-app';
import OrganizationLoginDetailsWidget from '../OrganizationLoginDetailsWidget';
import OrganizationLoginDetailsSecurityScoreWidget
  from '../OrganizationLoginDetailsSecurityScoreWidget';

interface IOrganizationLoginDetailsTabProps {
  vaultId: string;
}

const OrganizationLoginDetailsTab = (props: IOrganizationLoginDetailsTabProps) => {
  const { vaultId } = props;

  return (
    <PanelContent p={3} pt={1} className="height-100-percent">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OrganizationLoginDetailsWidget vaultId={vaultId} />
        </Grid>
        <Grid item xs={6}>
          <OrganizationLoginDetailsSecurityScoreWidget vaultId={vaultId} />
        </Grid>
      </Grid>
    </PanelContent>
  );
};

export default memo(OrganizationLoginDetailsTab);
