import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, ConditionalTabWrapper,
} from '@uniqkey-frontend/shared-app';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { useGetGroupById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import GroupDetailsTab from '../GroupDetailsTab';
import GroupLoginsTab from '../GroupLoginsTab';
import GroupMembersTab from '../GroupMembersTab';
import GroupRestrictionsTab from '../GroupRestrictionsTab';
import GroupSettingsTab from '../GroupSettingsTab';
import GroupSecureNotesTab from '../GroupSecureNotesTab';
import GroupCreditCardsTab from '../GroupCreditCardsTab';

export enum GroupModuleTabEnum {
  Details = 'details',
  Logins = 'logins',
  CreditCards = 'payment-cards',
  SecureNotes = 'secure-notes',
  Members = 'members',
  Restrictions = 'restrictions',
  Settings = 'settings'
}

interface IGroupModuleProps {
  activeTab: GroupModuleTabEnum;
}

interface IGroupModuleContext {
  groupId: string;
}

const GroupModule = (props: IGroupModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { groupId } = useOutletContext<IGroupModuleContext>();
  const navigate = useNavigate();

  const { data: group, isLoading } = useGetGroupById(
    { groupId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: GroupModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Groups}/${groupId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [groupId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === GroupModuleTabEnum.Details
    || activeTab === GroupModuleTabEnum.Settings;

  const { canManageVaults } = group ?? {};
  const canManageVaultAsBoolean = !!canManageVaults;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('groupPage.tabs.groupDetails')}
            value={GroupModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canManageVaults && (
            <Tab
              label={t('groupPage.tabs.logins')}
              value={GroupModuleTabEnum.Logins}
              onClick={handleTabClick}
            />
          )}
          {canManageVaults && (
            <Tab
              label={t('groupPage.tabs.creditCards')}
              value={GroupModuleTabEnum.CreditCards}
              onClick={handleTabClick}
            />
          )}
          {canManageVaults && (
            <Tab
              label={t('groupPage.tabs.secureNotes')}
              value={GroupModuleTabEnum.SecureNotes}
              onClick={handleTabClick}
            />
          )}
          <Tab
            label={t('groupPage.tabs.members')}
            value={GroupModuleTabEnum.Members}
            onClick={handleTabClick}
          />
          <Tab
            label={t('groupPage.tabs.restrictions')}
            value={GroupModuleTabEnum.Restrictions}
            onClick={handleTabClick}
          />
          <Tab
            label={t('groupPage.tabs.settings')}
            value={GroupModuleTabEnum.Settings}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      {activeTab === GroupModuleTabEnum.Details && <GroupDetailsTab groupId={groupId} />}
      {activeTab === GroupModuleTabEnum.Logins && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={canManageVaultAsBoolean}
          fallback={<Navigate to={`${PageRouteEnum.Groups}/${groupId}`} />}
        >
          <GroupLoginsTab groupId={groupId} />
        </ConditionalTabWrapper>
      )}
      {activeTab === GroupModuleTabEnum.CreditCards && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={canManageVaultAsBoolean}
          fallback={<Navigate to={`${PageRouteEnum.Groups}/${groupId}`} />}
        >
          <GroupCreditCardsTab groupId={groupId} />
        </ConditionalTabWrapper>
      )}
      {activeTab === GroupModuleTabEnum.SecureNotes && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={canManageVaultAsBoolean}
          fallback={<Navigate to={`${PageRouteEnum.Groups}/${groupId}`} />}
        >
          <GroupSecureNotesTab groupId={groupId} />
        </ConditionalTabWrapper>
      )}
      {activeTab === GroupModuleTabEnum.Members && <GroupMembersTab groupId={groupId} />}
      {activeTab === GroupModuleTabEnum.Restrictions && <GroupRestrictionsTab groupId={groupId} />}
      {activeTab === GroupModuleTabEnum.Settings && <GroupSettingsTab groupId={groupId} />}
    </Panel>
  );
};

export default memo(GroupModule);
