import {
  decryptSymmetric,
  encryptAsymmetric,
  encryptSymmetric,
  generateSymmetricKey,
  getCreditCardInfo,
} from '@uniqkey-frontend/shared-app';
import {
  type VaultCreditCardCreationRequestedForEmployeePayload,
  type VaultCreditCardCreationRequestedForGroupPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../../interfaces';
import { getSymmetricKey } from '../../../../../../services/companionApplicationService';

type TCardNumber = VaultCreditCardCreationRequestedForEmployeePayload['cardNumber']
  | VaultCreditCardCreationRequestedForGroupPayload['cardNumber'];
type TCardHolderName = VaultCreditCardCreationRequestedForEmployeePayload['cardHolderName']
  | VaultCreditCardCreationRequestedForGroupPayload['cardHolderName'];
type TExpiryDate = VaultCreditCardCreationRequestedForEmployeePayload['expiryDate']
  | VaultCreditCardCreationRequestedForGroupPayload['expiryDate'];
type TCvv = VaultCreditCardCreationRequestedForEmployeePayload['cvv']
  | VaultCreditCardCreationRequestedForGroupPayload['cvv'];

interface IHandleAdminVaultCreditCardCreationParams extends Pick<
  ITrustedPortalHandlerParams, 'organizationPublicKey'
> {
  cardNumber: NonNullable<TCardNumber>;
  cardHolderName: TCardHolderName;
  expiryDate: TExpiryDate;
  cvv: TCvv;
  errorPrefix: string;
}

const handleAdminVaultCreditCardCreation = async (
  params: IHandleAdminVaultCreditCardCreationParams,
) => {
  const {
    organizationPublicKey,
    cardNumber,
    cardHolderName,
    expiryDate,
    cvv,
    errorPrefix,
  } = params;

  const companionApplicationSymmetricKey = getSymmetricKey();
  if (!companionApplicationSymmetricKey) {
    throw new Error(`${errorPrefix} companionApplicationSymmetricKey not found`);
  }

  const decryptedCardNumber = decryptSymmetric({
    cipher: cardNumber,
    key: companionApplicationSymmetricKey,
  });

  const creditCardInfo = getCreditCardInfo(decryptedCardNumber);

  if (!creditCardInfo.isValid) {
    throw new Error(`${errorPrefix} Invalid credit card number`);
  }

  const symmetricKey = generateSymmetricKey();

  const encryptedCardNumber = encryptSymmetric({
    string: decryptedCardNumber,
    key: symmetricKey,
  });

  let encryptedCardHolderName = null;
  if (cardHolderName) {
    const decryptedCardHolderName = decryptSymmetric({
      cipher: cardHolderName,
      key: companionApplicationSymmetricKey,
    });

    encryptedCardHolderName = encryptSymmetric({
      string: decryptedCardHolderName,
      key: symmetricKey,
    });
  }

  let encryptedExpiryDate = null;
  if (expiryDate) {
    const decryptedExpiryDate = decryptSymmetric({
      cipher: expiryDate,
      key: companionApplicationSymmetricKey,
    });

    encryptedExpiryDate = encryptSymmetric({
      string: decryptedExpiryDate,
      key: symmetricKey,
    });
  }

  let encryptedCVV = null;
  if (cvv) {
    const decryptedCVV = decryptSymmetric({
      cipher: cvv,
      key: companionApplicationSymmetricKey,
    });

    encryptedCVV = encryptSymmetric({
      string: decryptedCVV,
      key: symmetricKey,
    });
  }

  const encryptedSymmetricKeyBackup = await encryptAsymmetric({
    string: symmetricKey,
    publicKey: organizationPublicKey,
  });

  return {
    encryptedCardNumber,
    encryptedCardHolderName,
    encryptedExpiryDate,
    encryptedCVV,
    creditCardInfo,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  };
};

export default handleAdminVaultCreditCardCreation;
