import { useCallback, useState } from 'react';
import {
  Panel,
  PanelContent,
  WidgetContainer,
  Grid,
  Typography,
  Select,
  MenuItem,
  AB3,
  S5,
  S6,
  EditableContainer,
  TypographyWithTooltip,
  InputAdornment,
  GlobeIcon,
  useTranslations,
  useSnackbar,
  usePubSub,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  useGetCurrentUser,
  useUpdateCurrentUserProfile,
  useUpdateCurrentUserLanguage,
  useLanguages,
} from '../../../../hooks/reactQuery';
import EditProfileModal, { IEditProfileModalSubmitResult } from '../EditProfileModal';

const SELECT_SX = { width: 215 };
const SELECT_START_ADORNMENT = (
  <InputAdornment position="start">
    <GlobeIcon color={AB3} />
  </InputAdornment>
);

const ProfileModule = () => {
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const handleEditProfileModalOpen = useCallback(() => setIsEditProfileModalOpen(true), []);
  const handleEditProfileModalClose = useCallback(() => setIsEditProfileModalOpen(false), []);

  const {
    data: currentUser, isLoading: isCurrentUserLoading, isError, refetch,
  } = useGetCurrentUser();

  const { languages, isLoading: areLanguagesLoading } = useLanguages();

  const {
    mutate: mutateCurrentUserProfile,
    isLoading: isCurrentUserProfileUpdating,
  } = useUpdateCurrentUserProfile({
    useOptimisticUpdates: true,
  });
  const {
    mutate: mutateCurrentUserLanguage,
    isLoading: isCurrentUserLanguageUpdating,
  } = useUpdateCurrentUserLanguage({
    useOptimisticUpdates: true,
  });

  const handleRefetch = useCallback(() => refetch(), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_CURRENT_USER, handleRefetch);

  const handleEditProfile = useCallback((value: IEditProfileModalSubmitResult) => {
    mutateCurrentUserProfile(
      value,
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('profilePage.toast.profileUpdated') });
          handleEditProfileModalClose();
        },
      },
    );
  }, [mutateCurrentUserProfile, handleEditProfileModalClose, showError, showSuccess, t]);

  const handleLanguageChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => {
    mutateCurrentUserLanguage(
      { language: event.target.value as string },
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => showSuccess({ text: t('profilePage.toast.languageUpdated') }),
      },
    );
  }, [mutateCurrentUserLanguage, showError, showSuccess, t]);

  if (isError) {
    return null;
  }

  const { name, email, language } = currentUser ?? {};

  const isCurrentUserUpdating = isCurrentUserProfileUpdating || isCurrentUserLanguageUpdating;

  return (
    <Panel className="height-100-percent">
      <PanelContent p={3} pt={3.5}>
        <Grid container gap={2}>
          <WidgetContainer
            item
            withShadow
            /*
             While the user is loading, we need to have padding here so the skeleton doesn't
             take the full widget size.
             When the user is loaded - we set the proper padding in the EditableContainer below.
            */
            p={isCurrentUserLoading ? 3 : 0}
            xs={12}
            md={6}
            isLoading={isCurrentUserLoading}
          >
            <EditableContainer
              container
              item
              flexDirection="column"
              p={isCurrentUserLoading ? 0 : 3}
              rowGap={3}
              truncate={false}
              onClick={handleEditProfileModalOpen}
              disabled={isCurrentUserUpdating}
            >
              <Grid container flexDirection="column">
                <Typography variant="caption" color={S5} mb={1}>
                  {t('profilePage.name')}
                </Typography>
                <TypographyWithTooltip variant="body3" color={S6}>
                  {name}
                </TypographyWithTooltip>
              </Grid>
              <Grid container flexDirection="column">
                <Typography variant="caption" color={S5} mb={1}>
                  {t('profilePage.email')}
                </Typography>
                <TypographyWithTooltip variant="body3" color={S6}>
                  {email}
                </TypographyWithTooltip>
              </Grid>
            </EditableContainer>
          </WidgetContainer>
          <WidgetContainer
            container
            item
            withShadow
            p={3}
            xs={12}
            md
            isLoading={areLanguagesLoading}
          >
            <Grid container flexDirection="column">
              <Typography variant="caption" color={S5} mb={1}>
                {t('profilePage.changeLanguage')}
              </Typography>
              <Select
                value={language}
                onChange={handleLanguageChange}
                sx={SELECT_SX}
                startAdornment={SELECT_START_ADORNMENT}
                disabled={isCurrentUserUpdating}
              >
                {
                  languages.map((currLanguage) => (
                    <MenuItem key={currLanguage.code} value={currLanguage.code}>
                      {currLanguage.localized_name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
          </WidgetContainer>
        </Grid>
      </PanelContent>
      {isEditProfileModalOpen && (
        <EditProfileModal
          isOpen={isEditProfileModalOpen}
          isLoading={isCurrentUserUpdating}
          onSubmit={handleEditProfile}
          onClose={handleEditProfileModalClose}
          currentUser={currentUser!}
        />
      )}
    </Panel>
  );
};

export default ProfileModule;
