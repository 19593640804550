import PubSub from 'pubsub-js';
import { type QueueMessagesInfo } from '@uniqkey-backend-organization-web/api-client';
import TrustedPortalPubSubEventEnum from '../enums/PubSubEventEnum';
import { buildPubSubTopic } from '../../../helpers/PubSub';
import Helpers from '../helpers';

const LOG_COLOR = 'blue';

interface ITrustedPortalEventQueue {
  isProviding: boolean;
  destroy(): void;
}

// eslint-disable-next-line import/prefer-default-export
export class TrustedPortalEventQueue implements ITrustedPortalEventQueue {
  private readonly queue: QueueMessagesInfo[] = [];

  private readonly unsubscribeProvideEvent: string;

  private readonly unsubscribeEventsLoaded: string;

  private isRequested = false;

  isProviding = false;

  constructor(
    private readonly organizationId: string,
    private readonly maxCount: number,
    private readonly threshold: number,
  ) {
    if (this.threshold >= this.maxCount) {
      this.threshold = 1;
      if (this.maxCount <= this.threshold) {
        this.maxCount = 2;
      }
      // eslint-disable-next-line no-console
      console.warn(
        'TrustedPortalModule/EventQueue threshold >= maxCount\n',
        `New values: maxCount: ${this.maxCount}, threshold: ${this.threshold}`,
      );
    }

    this.unsubscribeProvideEvent = PubSub.subscribe(
      this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT),
      () => {
        this.isProviding = true;
        Helpers.tpLogMessage({
          messages: [
            `[QUEUE: ${this.queue.length}]`,
            'receive TRUSTED_PORTAL_PROVIDE_EVENT',
          ],
          color: LOG_COLOR,
        });

        if (!this.queue.length) {
          this.isRequested = true;
          Helpers.tpLogMessage({
            messages: [
              `[QUEUE: ${this.queue.length}]`,
              'empty queue',
              `\n\tpublish TRUSTED_PORTAL_LOAD_EVENTS, amount: ${this.maxCount}`,
            ],
            color: LOG_COLOR,
          });
          PubSub.publish(
            this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_LOAD_EVENTS),
            { quantity: this.maxCount },
          );
          return;
        }

        if (this.queue.length <= this.threshold) {
          const quantityToLoad = this.maxCount - (this.queue.length - 1);
          Helpers.tpLogMessage({
            messages: [
              `[QUEUE: ${this.queue.length}]`,
              `queue.length <= threshold (${this.threshold})`,
              `\n\tpublish TRUSTED_PORTAL_LOAD_EVENTS, amount: ${quantityToLoad}`,
            ],
            color: LOG_COLOR,
          });
          PubSub.publish(
            this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_LOAD_EVENTS),
            { quantity: quantityToLoad },
          );
        }

        const event = this.queue.shift();
        Helpers.tpLogMessage({
          messages: [`[QUEUE: ${this.queue.length}]`, 'publish TRUSTED_PORTAL_EVENT_PROVIDED'],
          color: LOG_COLOR,
        });
        PubSub.publish(
          this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_EVENT_PROVIDED),
          { event },
        );
        this.isProviding = false;
      },
    );

    this.unsubscribeEventsLoaded = PubSub.subscribe(
      this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_EVENTS_LOADED),
      (_, data) => {
        const { events } = data;

        Helpers.tpLogMessage({
          messages: [
            `[QUEUE: ${this.queue.length}]`,
            `receive TRUSTED_PORTAL_EVENTS_LOADED, amount: ${events.length}`,
          ],
          color: LOG_COLOR,
        });

        if (!events.length && !this.queue.length) {
          Helpers.tpLogMessage({
            messages: [`[QUEUE: ${this.queue.length}]`, 'publish TRUSTED_PORTAL_NO_EVENTS'],
            color: LOG_COLOR,
          });
          PubSub.publish(
            this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_NO_EVENTS),
          );
          this.isRequested = false;
          this.isProviding = false;
          return;
        }

        if (!events.length) {
          return;
        }

        this.queue.push(...events);

        Helpers.tpLogMessage({
          messages: [`[QUEUE: ${this.queue.length}]`, `pushed amount: ${events.length}`],
          color: LOG_COLOR,
        });

        if (!this.isRequested) {
          return;
        }

        const event = this.queue.shift();

        Helpers.tpLogMessage({
          messages: [
            `[QUEUE: ${this.queue.length}]`,
            'publish TRUSTED_PORTAL_EVENT_PROVIDED when isRequested = true',
          ],
          color: LOG_COLOR,
        });
        PubSub.publish(
          this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_EVENT_PROVIDED),
          { event },
        );
        this.isRequested = false;
        this.isProviding = false;
      },
    );
  }

  private getPubSubTopic(topic: string): string {
    return buildPubSubTopic(topic, this.organizationId);
  }

  destroy(): void {
    PubSub.unsubscribe(this.unsubscribeProvideEvent);
    PubSub.unsubscribe(this.unsubscribeEventsLoaded);
  }
}
