import {
  CSSProperties, useCallback, useEffect, useRef, memo,
} from 'react';
import {
  BackdropSecondary,
  Button,
  CircularProgress,
  Grid,
  IphoneImage,
  S1,
  Typography,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { logException } from '../../services/sentryService';
import useQueueAPI from '../../hooks/useQueueAPI';

const SX = { zIndex: (theme: any) => theme.zIndex.drawer + 1 };

const IPHONE_IMAGE_HEIGHT = 196;

const IPhoneStyle: CSSProperties = {
  position: 'absolute',
  bottom: -IPHONE_IMAGE_HEIGHT,
  transition: 'all 0.5s ease',
  height: IPHONE_IMAGE_HEIGHT,
};

const TRANSITION_DURATION = 100;

interface IMobileRequestOverlayProps {
  isOpen: boolean;
  queueMessageId: string;
  onCancel?: () => void | Promise<void>;
  cancelButtonTranslationKey?: string
}

const MobileRequestOverlay = (props: IMobileRequestOverlayProps) => {
  const {
    isOpen,
    queueMessageId,
    onCancel,
    cancelButtonTranslationKey = 'mobileApproveOverlay.cancel',
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { cancelQueueMessage } = useQueueAPI();

  const handleOverlayClose = useCallback(async () => {
    try {
      await cancelQueueMessage(queueMessageId);
      if (onCancel) {
        onCancel();
      }
      showSuccess({ text: t(cancelButtonTranslationKey) });
    } catch (e) {
      logException(e, { message: 'MobileRequestOverlay/handleOverlayClose exception' });
      showError({
        text: t('common.somethingWentWrong'),
      });
    }
  }, [
    cancelQueueMessage,
    onCancel,
    cancelButtonTranslationKey,
    queueMessageId,
    showSuccess,
    showError,
    t,
  ]);

  useEffect(() => {
    let timeoutID: ReturnType<typeof setTimeout> | null = null;
    if (isOpen) {
      timeoutID = setTimeout(() => {
        ref.current!.style.bottom = '0px';
      }, TRANSITION_DURATION);
    } else {
      ref.current!.style.bottom = `-${IPHONE_IMAGE_HEIGHT}px`;
    }
    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [isOpen]);

  return (
    <BackdropSecondary
      sx={SX}
      transitionDuration={TRANSITION_DURATION}
      open={isOpen}
    >
      <Grid container mt={-10}>
        <Grid item container alignItems="center" justifyContent="center">
          <Typography align="center" color={S1} variant="h5">
            {t('mobileApproveOverlay.confirm')}
          </Typography>
        </Grid>
        <Grid item container alignItems="center" justifyContent="center" mt={3}>
          <CircularProgress color={S1} />
        </Grid>
        <Grid item container alignItems="center" justifyContent="center" mt={3}>
          <Button variant="text" onClick={handleOverlayClose}>
            <Typography color={S1} variant="subtitle1">
              {t('common.cancel')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <div ref={ref} style={IPhoneStyle}>
        <IphoneImage />
      </div>
    </BackdropSecondary>
  );
};

export default memo(MobileRequestOverlay);
