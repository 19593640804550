import { type AxiosInstance } from 'axios';
import {
  EmployeeAccountsApi,
  QueueApi,
  TrustedPortalApi,
  MasterPasswordResetRequestApi,
} from '@uniqkey-backend-organization-mobile/api-client';
import config from '../../../../config';

const API_BASE_URL = config.getMobileApiUrl();

const getQueueAPIClient = (instance: AxiosInstance) => new QueueApi(
  undefined,
  API_BASE_URL,
  instance,
);

const getTrustedPortalAPIClient = (instance: AxiosInstance) => new TrustedPortalApi(
  undefined,
  API_BASE_URL,
  instance,
);

const getEmployeeAccountsAPIClient = (instance: AxiosInstance) => new EmployeeAccountsApi(
  undefined,
  API_BASE_URL,
  instance,
);

const getMasterPasswordResetRequestAPIClient = (
  instance: AxiosInstance,
) => new MasterPasswordResetRequestApi(
  undefined,
  API_BASE_URL,
  instance,
);

export default {
  getQueueAPIClient,
  getTrustedPortalAPIClient,
  getEmployeeAccountsAPIClient,
  getMasterPasswordResetRequestAPIClient,
};
