import { encryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type VaultSecureNoteCreationRequestedForEmployeePayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import handleAdminVaultSecureNoteCreation from '../common/handleAdminVaultSecureNoteCreation';

const ERROR_PREFIX = 'TrustedPortal/handleAdminVaultSecureNoteCreationForEmployeeRequestedEvent';

const handleAdminVaultSecureNoteCreationForEmployeeRequestedEvent = async (
  params: ITrustedPortalHandlerParams<VaultSecureNoteCreationRequestedForEmployeePayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    note,
    employeeAccountId,
    employeeAccountKeyPairPublic,
  } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!employeeAccountKeyPairPublic) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountKeyPairPublic in event`);
  }

  const {
    encryptedNote,
    symmetricKey,
    encryptedSymmetricKeyBackup,
  } = await handleAdminVaultSecureNoteCreation({
    organizationPublicKey,
    note,
    errorPrefix: ERROR_PREFIX,
  });

  const encryptedSymmetricKey = await encryptAsymmetric({
    string: symmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueAdminVaultSecureNoteCreationRequestedForEmployeeApprovePost({
      queueMessageId,
      note: encryptedNote,
      vaultSymmetricKey: encryptedSymmetricKey,
      vaultSymmetricKeyBackup: encryptedSymmetricKeyBackup,
    });
};

export default handleAdminVaultSecureNoteCreationForEmployeeRequestedEvent;
