import axios from 'axios';
import {
  MigrationApi, RefreshMigrationTokenResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { dataExtractor } from '../../../helpers/apiClients';
import config from '../../../config';
import {
  getJWTTokens, setJWTTokens, ITokens,
} from '../authService';

const axiosInstanceWithoutAuthRefresh = axios.create({
  baseURL: config.getWebApiUrl(),
  timeout: config.requestTimeout,
});

const migrationClient = new MigrationApi(
  undefined,
  config.getWebApiUrl(),
  axiosInstanceWithoutAuthRefresh,
);

let tokensRefreshPromise: Promise<RefreshMigrationTokenResponse> | null = null;

const sendRefreshTokensRequest = async (tokens: ITokens)
  : Promise<RefreshMigrationTokenResponse> => {
  if (tokensRefreshPromise) {
    return tokensRefreshPromise;
  }
  tokensRefreshPromise = migrationClient
    .apiV1MigrationRefreshMigrationTokenPost(tokens)
    .then(dataExtractor<RefreshMigrationTokenResponse>())
    .finally(() => {
      tokensRefreshPromise = null;
    });

  return tokensRefreshPromise;
};

const refreshTokens = async () => {
  const jwtTokens = getJWTTokens();
  if (!jwtTokens) throw new Error('no jwt tokens found');
  const { token } = await sendRefreshTokensRequest(jwtTokens);
  setJWTTokens(token);
};

export default refreshTokens;
