import { type ReactNode } from 'react';
import { type Libraries, LoadScript } from '@react-google-maps/api';
import { FALLBACK_LANGUAGE } from '@uniqkey-frontend/shared-app';
import config from '../../../config';

interface IGoogleMapsScriptLoaderProps {
  children: ReactNode;
}

const LIBRARIES: Libraries = ['places'];

const LOADING_ELEMENT = <div />;

const GoogleMapsScriptLoader = (props: IGoogleMapsScriptLoaderProps) => {
  const { children } = props;
  return (
    <LoadScript
      googleMapsApiKey={config.GMapsApiKey!}
      libraries={LIBRARIES}
      language={FALLBACK_LANGUAGE}
      loadingElement={LOADING_ELEMENT}
    >
      {children}
    </LoadScript>
  );
};

export default GoogleMapsScriptLoader;
