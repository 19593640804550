import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import AuditLogsTab from '../AuditLogsTab';
import AccountRecoveryRequestsTab from '../AccountRecoveryRequestsTab';
import PageRouteEnum from '../../../../enums/PageRouteEnum';

export enum AuditLogsModuleTabEnum {
  AuditLogs = 'logs',
  AccountRecovery = 'account-recovery',
}

interface IAuditLogsModuleProps {
  activeTab: AuditLogsModuleTabEnum;
}

const AuditLogsModule = (props: IAuditLogsModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: AuditLogsModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Audit}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [activeTab, navigate]);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3}>
        <Tabs value={activeTab}>
          <Tab
            label={t('auditLogsPage.tabs.auditLogs')}
            value={AuditLogsModuleTabEnum.AuditLogs}
            onClick={handleTabClick}
          />
          <Tab
            label={t('auditLogsPage.tabs.accountRecoveryRequests')}
            value={AuditLogsModuleTabEnum.AccountRecovery}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      {activeTab === AuditLogsModuleTabEnum.AuditLogs && <AuditLogsTab />}
      {activeTab === AuditLogsModuleTabEnum.AccountRecovery && <AccountRecoveryRequestsTab />}
    </Panel>
  );
};

export default memo(AuditLogsModule);
