import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetSecuritySettingsResponse,
  UpdateSecuritySettingsRequest,
  NoContentResult,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useOrganizationSecuritySettingsAPI from '../../useOrganizationSecuritySettingsAPI';

export const useGetOrganizationSecuritySettings = (
  options: TUseQueryOptions<GetSecuritySettingsResponse> = {},
) => {
  const { getOrganizationSecuritySettings } = useOrganizationSecuritySettingsAPI();
  return useQuery<GetSecuritySettingsResponse>(
    ReactQueryKeyEnum.OrganizationSecuritySettings,
    ({ signal }) => getOrganizationSecuritySettings({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateOrganizationSecuritySettingsParams {
  useOptimisticUpdates?: boolean;
}
export const useUpdateOrganizationSecuritySettings = (
  params: IUseUpdateOrganizationSecuritySettingsParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateSecuritySettingsRequest,
    TUseMutationContext<UpdateSecuritySettingsRequest>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateOrganizationSecuritySettings } = useOrganizationSecuritySettingsAPI();
  const mutationKey = ReactQueryKeyEnum.OrganizationSecuritySettings;
  return useMutation(mutationKey, (
    securitySettings,
  ) => updateOrganizationSecuritySettings(securitySettings), {
    onMutate: async (newSettings: UpdateSecuritySettingsRequest) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      await queryClient.cancelQueries(mutationKey);
      const previousValue = queryClient.getQueryData<UpdateSecuritySettingsRequest>(mutationKey);
      queryClient.setQueryData<UpdateSecuritySettingsRequest>(mutationKey, (oldSettings) => ({
        ...oldSettings,
        ...newSettings,
      }));
      return { previousValue: previousValue as UpdateSecuritySettingsRequest };
    },
    onError: (err, settings, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<UpdateSecuritySettingsRequest>(mutationKey, context.previousValue);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(mutationKey);
    },
    ...options,
  });
};
