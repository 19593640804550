import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TUseMutationOptions, TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import {
  DetachBulkVaultsRequest,
  DetachBulkVaultsResponse,
  GetVaultPasswordByIdResponse,
  DeleteBulkVaultsRequest,
  DeleteBulkVaultsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useVaultsAPI from '../../useVaultsAPI';

interface IUseGetVaultPasswordByIdParams {
  vaultId: string;
}
export const useGetVaultPasswordById = (
  params: IUseGetVaultPasswordByIdParams,
  options: TUseQueryOptions<GetVaultPasswordByIdResponse> = {},
) => {
  const { vaultId } = params;
  const { getVaultPasswordById } = useVaultsAPI();
  return useQuery<GetVaultPasswordByIdResponse>(
    [ReactQueryKeyEnum.Login, vaultId],
    ({ signal }) => getVaultPasswordById(vaultId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useDetachVaults = (
  options: TUseMutationOptions<
    DetachBulkVaultsResponse,
    unknown,
    DetachBulkVaultsRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { detachVaults } = useVaultsAPI();
  return useMutation((detachBulkVaultsRequest) => detachVaults(detachBulkVaultsRequest), {
    onSettled: (data, error, variables) => {
      variables.vaultIds.forEach((vaultId) => {
        queryClient.removeQueries([ReactQueryKeyEnum.Login, vaultId], { exact: true });
      });
    },
    ...options,
  });
};

export const useDeleteVaults = (
  options: TUseMutationOptions<
    DeleteBulkVaultsResponse,
    unknown,
    DeleteBulkVaultsRequest,
    void
    > = {},
) => {
  const queryClient = useQueryClient();
  const { deleteVaults } = useVaultsAPI();
  return useMutation((deleteBulkVaultsRequest) => deleteVaults(deleteBulkVaultsRequest), {
    onSettled: (data, error, variables) => {
      variables.vaultIds.forEach((vaultId) => {
        queryClient.removeQueries([ReactQueryKeyEnum.Login, vaultId], { exact: true });
      });
    },
    ...options,
  });
};
