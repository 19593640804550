import {
  useCallback, useMemo, ReactNode,
} from 'react';
import { generateGUID } from '@uniqkey-frontend/shared-app';
import CompanionApplicationContext from '.';
import {
  getCompanionApplicationId,
  setCompanionApplicationId,
} from '../../services/companionApplicationService';
import { useUser } from '../UserContext';
import { logException } from '../../../services/sentryService';

const CompanionApplicationProviderContext = (
  { children }: { children: ReactNode },
): JSX.Element => {
  const { isAuthenticated } = useUser();

  const guid = useMemo(
    () => getCompanionApplicationId() || generateGUID(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated],
  );

  const saveCompanionApplicationData = useCallback(() => {
    try {
      setCompanionApplicationId(guid);
    } catch (e) {
      logException(e, {
        message: 'CompanionApplicationProviderContext/saveCompanionApplicationData exception',
      });
    }
  }, [guid]);

  const value = useMemo(() => ({
    saveCompanionApplicationData,
    guid,
  }), [
    saveCompanionApplicationData,
    guid,
  ]);

  return (
    <CompanionApplicationContext.Provider value={value}>
      {children}
    </CompanionApplicationContext.Provider>
  );
};

export default CompanionApplicationProviderContext;
