import { useEffect } from 'react';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import { logout } from '../../services/authService';
import { useUser } from '../../contexts/UserContext';
import { triggerTokensRefresh } from '../../services/tokensManager';
import {
  handleDataSynchronization,
  type IOrganizationPortalSynchronizationPayload,
} from '../../helpers/dataSynchronization';
import { subscribeToRealtimeAPIEvent } from '../../services/webSocketsManager';

const useRealtimeAPIGlobalSubscriptions = () => {
  const { isAuthenticated } = useUser();

  useEffect(() => {
    if (!isAuthenticated) {
      return undefined;
    }

    const unsubscribeOrganizationPortalLogout = subscribeToRealtimeAPIEvent(
      RealtimeAPIEventTypeEnum.OrganizationPortalLogout,
      () => {
        logout({ showMessage: true });
      },
    );

    const unsubscribeOrganizationPortalSynchronization = subscribeToRealtimeAPIEvent<
      IOrganizationPortalSynchronizationPayload
    >(
      RealtimeAPIEventTypeEnum.OrganizationPortalSynchronization,
      handleDataSynchronization,
    );

    const unsubscribeOrganizationEntitiesChanged = subscribeToRealtimeAPIEvent(
      RealtimeAPIEventTypeEnum.OrganizationEntitiesChanged,
      triggerTokensRefresh,
    );

    return () => {
      unsubscribeOrganizationPortalLogout();
      unsubscribeOrganizationPortalSynchronization();
      unsubscribeOrganizationEntitiesChanged();
    };
  }, [isAuthenticated]);
};

export default useRealtimeAPIGlobalSubscriptions;
