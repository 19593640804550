import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import EmployeePageBreadcrumbs from './components/EmployeePageBreadcrumbs';

const EmployeePage = () => {
  const params = useParams();
  const { employeeAccountId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <EmployeePageBreadcrumbs employeeAccountId={employeeAccountId!} />
      </Grid>
      <Grid item xs>
        <Outlet context={{ employeeAccountId }} />
      </Grid>
    </Grid>
  );
};

export default EmployeePage;
