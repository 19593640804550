import { FC } from 'react';
import {
  Navigate, RouteProps, Outlet,
} from 'react-router-dom';
import MigrationPageRouteEnum from '../../enums/MigrationPageRouteEnum';

type TProtectedRouteParams = RouteProps & {
  isAuthorized: boolean;
}

const ProtectedRoute: FC<TProtectedRouteParams> = (
  props: TProtectedRouteParams,
) => {
  const { isAuthorized = false } = props;

  if (!isAuthorized) {
    return (
      <Navigate to={MigrationPageRouteEnum.Migration} />
    );
  }
  return (
    <Outlet />
  );
};

export default ProtectedRoute;
