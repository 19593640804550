import { useMemo } from 'react';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { useGetCurrentOrganization } from '../../../../hooks/reactQuery';

const DashboardPageBreadcrumbs = () => {
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { data: organization, isLoading, isError } = useGetCurrentOrganization({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !organization) {
      return [];
    }
    return [
      { title: organization.name },
    ];
  }, [isLoading, organization]);

  if (isError) {
    return null;
  }

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default DashboardPageBreadcrumbs;
