import { useQuery } from 'react-query';
import { TUseQueryOptionsWithKey } from '@uniqkey-frontend/shared-app';
import { GetUnprocessedInfoResponse } from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useQueueAPI from '../../useQueueAPI';

interface IUseGetUnprocessedInfoParams {
  activeOrganizationId: string | null;
}

// eslint-disable-next-line import/prefer-default-export
export const useGetUnprocessedInfo = (
  params: IUseGetUnprocessedInfoParams,
  options: TUseQueryOptionsWithKey<GetUnprocessedInfoResponse> = {},
) => {
  const { activeOrganizationId } = params;
  const { getUnprocessedInfo } = useQueueAPI();
  return useQuery<GetUnprocessedInfoResponse>(
    [ReactQueryKeyEnum.UnprocessedInfo, activeOrganizationId],
    ({ signal }) => getUnprocessedInfo({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
