import { type AxiosInstance } from 'axios';
import PubSub from 'pubsub-js';
import TrustedPortalPubSubEventEnum from '../enums/PubSubEventEnum';
import { buildPubSubTopic } from '../../../helpers/PubSub';
import type { ITrustedPortalActionPayload } from '../interfaces';
import Axios from '../axios';
import Helpers from '../helpers';
import { TrustedPortalEventProvider } from '../EventProvider';
import { TrustedPortalEventQueue } from '../EventQueue';
import { TrustedPortalEventExecutor } from '../EventExecutor';

interface ITokens extends Pick<ITrustedPortalActionPayload, 'token' | 'refreshToken'> {}

export interface ITrustedPortalWorker {
  getTokens(): ITokens;
  setTokens(tokens: ITokens): void;
  start(): void;
  isExecuting(): boolean;
  destroy(): void;
}

export class TrustedPortalWorker implements ITrustedPortalWorker {
  private readonly axiosInstance: AxiosInstance;

  private readonly eventProvider: TrustedPortalEventProvider;

  private readonly eventQueue: TrustedPortalEventQueue;

  private readonly eventExecutor: TrustedPortalEventExecutor;

  constructor(
    private readonly organizationId: string,
    private readonly organizationPrivateKey: ITrustedPortalActionPayload['organizationPrivateKey'],
    private readonly organizationPublicKey: ITrustedPortalActionPayload['organizationPublicKey'],
    private token: ITrustedPortalActionPayload['token'],
    private refreshToken: ITrustedPortalActionPayload['refreshToken'],
  ) {
    this.axiosInstance = Axios.createTrustedPortalWorkerInstance(
      () => this.getTokens(),
      (tokens) => this.setTokens(tokens),
    );

    this.eventProvider = new TrustedPortalEventProvider(this.organizationId);

    this.eventQueue = new TrustedPortalEventQueue(this.organizationId, 5, 1);

    this.eventExecutor = new TrustedPortalEventExecutor(
      this.organizationId,
      this.axiosInstance,
      this.organizationPrivateKey,
      this.organizationPublicKey,
    );
  }

  private getPubSubTopic(topic: string): string {
    return buildPubSubTopic(topic, this.organizationId);
  }

  getTokens(): ITokens {
    return {
      token: this.token,
      refreshToken: this.refreshToken,
    };
  }

  setTokens(tokens: ITokens): void {
    const { token, refreshToken } = tokens;
    this.token = token;
    this.refreshToken = refreshToken;
  }

  start(): void {
    if (this.isExecuting()) {
      // eslint-disable-next-line no-console
      console.warn('TrustedPortalModule/Worker start() called while isExecuting() is true');
      return;
    }
    Helpers.tpLogMessage({ message: '[WORKER] start, publish TRUSTED_PORTAL_PROVIDE_EVENT' });
    PubSub.publish(this.getPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT));
  }

  isExecuting() {
    return this.eventExecutor.isExecuting
      || this.eventQueue.isProviding
      || this.eventProvider.isProviding;
  }

  destroy(): void {
    this.eventProvider.destroy();
    this.eventQueue.destroy();
    this.eventExecutor.destroy();
  }
}
