import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeByApplicationOrderPropertyName,
  EmployeeApplicationInfo,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import {
  IGetEmployeeAccountsByApplicationIdParams,
  TGetEmployeeAccountsByApplicationIdMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import { IServiceEmployeesTableRow } from '../../../components/tables/ServiceEmployeesTable';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';

interface IPersistentFilters extends Pick<
  IGetEmployeeAccountsByApplicationIdParams, 'applicationId'
> {}

export interface IUseServiceEmployeesTableProps {
  persistentFilters: IPersistentFilters;
}

export interface IUseServiceEmployeesTableReturn extends
  Omit<
    IUseTableReturn<IServiceEmployeesTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseTableFetchReturn<EmployeeApplicationInfo>, 'data'>
{
  employees: IUseTableFetchReturn<EmployeeApplicationInfo>['data'];
  selectedEmployees: IUseTableReturn<IServiceEmployeesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetEmployeeAccountsByApplicationIdParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<
    IGetEmployeeAccountsByApplicationIdParams['searchQuery']>
  >;
}

interface ITablePreservationConfigs extends
  Omit<IGetEmployeeAccountsByApplicationIdParams,
    'page' | 'pageLength' | 'applicationId' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
  >,
  Pick<IUseTableReturn<IServiceEmployeesTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetEmployeeAccountsByApplicationIdParams['page'];
  perPage: IGetEmployeeAccountsByApplicationIdParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: EmployeeByApplicationOrderPropertyName },
  ),
  columnOrderDirection: true,
};

const useServiceEmployeesTable = (
  props: IUseServiceEmployeesTableProps,
): IUseServiceEmployeesTableReturn => {
  const {
    persistentFilters,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getEmployeeAccountsByApplicationId } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetEmployeeAccountsByApplicationIdParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedEmployees,
    resetTableMethods,
    ...restTableProps
  } = useTable<IServiceEmployeesTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetActivePage,
  } = resetTableMethods;

  const {
    data: employees, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetEmployeeAccountsByApplicationIdParams,
    TGetEmployeeAccountsByApplicationIdMethod,
    EmployeeApplicationInfo
  >({
    request: getEmployeeAccountsByApplicationId,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: EmployeeByApplicationOrderPropertyName[
        upperFirst(columnOrderBy) as keyof typeof EmployeeByApplicationOrderPropertyName
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    employees,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    selectedEmployees,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps,
  };
};

export default useServiceEmployeesTable;
