import { useCallback, useMemo } from 'react';
import {
  PanelContent,
  Grid,
  ActionButton,
  ApproveIcon,
  CloseIcon,
  AR2,
  AG3,
  Divider,
  Tooltip,
  SearchableTextField,
  useTranslations,
  useSnackbar,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import AccountRecoveryRequestsTable
  from '../../../../components/tables/AccountRecoveryRequestsTable';
import useAccountRecoveryRequestsTable
  from '../../../../hooks/tables/useAccountRecoveryRequestsTable';
import useMasterPasswordResetRequestsAPI from '../../../../hooks/useMasterPasswordResetRequestsAPI';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';

const AccountRecoveryRequestsTab = () => {
  const { showSuccess, showError, showWarning } = useSnackbar();
  const { t } = useTranslations();
  const {
    approveAccountRecoveryRequest,
    rejectAccountRecoveryRequest,
  } = useMasterPasswordResetRequestsAPI();

  const {
    selectedAccounts,
    setSearchQuery,
    searchQuery,
    refetch,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useAccountRecoveryRequestsTable({
    noDataMessageKey: 'table.noData.default',
  });

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const selectedAccountsIds = useMemo(
    () => Array.from(
      selectedAccounts.keys(),
    ),
    [selectedAccounts],
  );

  const handleApproveAccountRecovery = useCallback(async () => {
    try {
      const { successCount, failCount } = await approveAccountRecoveryRequest(selectedAccountsIds);
      if (successCount) {
        if (isTrustedPortalEnabled) {
          showSuccess({
            text: t('trustedPortalSuccessNotifications.masterPasswordResetApproved'),
          });
        } else {
          showWarning({
            text: t(
              // eslint-disable-next-line max-len
              'auditLogsPage.accountRecoveryRequestsTab.approveAccountRecoveryRequest.successMessage',
              { count: successCount },
            ),
          });
        }
      }
      if (failCount) {
        showError({
          text: t(
            'auditLogsPage.accountRecoveryRequestsTab.approveAccountRecoveryRequest.errorMessage',
            { count: failCount },
          ),
        });
      }
      resetSelectedRows();
      resetActivePage();
      refetch();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'AccountRecoveryRequestsTab/handleApproveAccountRecovery exception',
      });
    }
  }, [
    approveAccountRecoveryRequest,
    refetch,
    selectedAccountsIds,
    resetSelectedRows,
    resetActivePage,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleRejectAccountRecovery = useCallback(async () => {
    try {
      const { successCount, failCount } = await rejectAccountRecoveryRequest(selectedAccountsIds);
      if (successCount) {
        showSuccess({
          text: t(
            'auditLogsPage.accountRecoveryRequestsTab.rejectAccountRecoveryRequest.successMessage',
            { count: successCount },
          ),
        });
      }
      if (failCount) {
        showError({
          text: t(
            'auditLogsPage.accountRecoveryRequestsTab.rejectAccountRecoveryRequest.errorMessage',
            { count: failCount },
          ),
        });
      }
      resetSelectedRows();
      resetActivePage();
      refetch();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'AccountRecoveryRequestsTab/handleRejectAccountRecovery exception',
      });
    }
  }, [
    rejectAccountRecoveryRequest,
    refetch,
    selectedAccountsIds,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    showError,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          {!!selectedAccounts.size && (
            <>
              <Grid item alignSelf="center">
                <Tooltip title={t('auditLogsPage.accountRecoveryRequestsTab.approve')}>
                  <ActionButton
                    width={40}
                    height={40}
                    color={AG3}
                    onClick={handleApproveAccountRecovery}
                  >
                    <ApproveIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item alignSelf="center">
                <Tooltip title={t('auditLogsPage.accountRecoveryRequestsTab.reject')}>
                  <ActionButton
                    width={40}
                    height={40}
                    color={AR2}
                    onClick={handleRejectAccountRecovery}
                  >
                    <CloseIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <SearchableTextField
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={t('common.search')}
          />
        </Grid>
      </Grid>
      <Divider />
      <AccountRecoveryRequestsTable
        selectedAccounts={selectedAccounts}
        {...restTableProps}
      />
    </PanelContent>
  );
};

export default AccountRecoveryRequestsTab;
