import { memo } from 'react';
import {
  Grid,
  Typography,
  TypographyWithHelp,
  TypographyWithCountUp,
  Divider,
  S6,
} from '@uniqkey-frontend/shared-app';

interface IDashboardEmployeesInfoWidgetItemProps {
  title: string;
  titleHelperText: string;
  linkText: string;
  count: number;
  onClick: () => void;
  showDivider: boolean;
}

const TITLE_TYPOGRAPHY_PROPS = {
  variant: 'caption', noWrap: true,
};

const DashboardEmployeesInfoWidgetItem = (props: IDashboardEmployeesInfoWidgetItemProps) => {
  const {
    onClick,
    title,
    titleHelperText,
    linkText,
    count,
    showDivider,
  } = props;
  return (
    <Grid item container xs={3} flexDirection="column" alignItems="center">
      <Grid item container flexDirection="column" alignItems="center">
        <Grid item mt={2}>
          <TypographyWithHelp helperText={titleHelperText} TypographyProps={TITLE_TYPOGRAPHY_PROPS}>
            {title}
          </TypographyWithHelp>
        </Grid>
        <Grid item mt={1}>
          <TypographyWithCountUp variant="h3" value={count} color={S6} />
        </Grid>
      </Grid>
      <Grid item mt={4} mb={1}>
        <Typography
          variant="subtitle1"
          asLink
          onClick={onClick}
        >
          {linkText}
        </Typography>
      </Grid>
      {showDivider && <Divider orientation="vertical" />}
    </Grid>
  );
};

export default memo(DashboardEmployeesInfoWidgetItem);
