import {
  encryptAsymmetric,
  encryptSymmetric,
  generateAsymmetricKeys,
  generateSymmetricKey,
} from '@uniqkey-frontend/shared-app';
import {
  type ProcessNewEmployeePayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handleProcessNewEmployeeEvent';

const handleProcessNewEmployeeEvent = async (
  params: ITrustedPortalHandlerParams<ProcessNewEmployeePayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { employeeAccountId } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  const newEmployeeKeypair = await generateAsymmetricKeys();
  const symmetricKey = generateSymmetricKey();

  const encryptedPrivateKeyBackupSymmetricKey = await encryptAsymmetric({
    string: symmetricKey,
    publicKey: organizationPublicKey,
  });

  const encryptedEmployeeAccountPrivateKey = encryptSymmetric({
    string: newEmployeeKeypair.privateKey!,
    key: symmetricKey,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueProcessNewEmployeeApprovePost({
      queueMessageId,
      privateKeyBackupSymmetricKey: encryptedPrivateKeyBackupSymmetricKey,
      employeeAccountPrivateKey: encryptedEmployeeAccountPrivateKey,
      employeeAccountKeyPairPublic: newEmployeeKeypair.publicKey,
    });
};

export default handleProcessNewEmployeeEvent;
