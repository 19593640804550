import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
  S1,
  Spinner,
  Tooltip,
  TrustIcon,
  Typography,
  useSnackbar,
  useTranslations,
  WarningIcon,
} from '@uniqkey-frontend/shared-app';
import { QueueMessageResultNotification } from '@uniqkey-backend-organization-web/api-client';
import TrustedPortalToggle from '../TrustedPortalToggle';
import UnprocessedEventsModal from '../UnprocessedEventsModal';
import useQueueAPI from '../../../../../hooks/useQueueAPI';
import { getActiveOrganizationId } from '../../../../../services/organizationService';
import {
  getShouldShowUnprocessedEventsWarningByOrganizationId,
  setShouldShowUnprocessedEventsWarningByOrganizationId,
} from '../../../../../services/authService';
import { logException } from '../../../../../services/sentryService';
import { subscribeToRealtimeAPIEvent } from '../../../../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../../../../enums/RealtimeAPIEventTypeEnum';
import {
  useGetOrganizationSecuritySettings, useGetUnprocessedInfo,
} from '../../../../../hooks/reactQuery';
import { useTrustedPortalStore } from '../../../../../modules/TrustedPortalModule/store';
import QueueEventEnum from '../../../../../enums/QueueEventEnum';
import useMobileRequestOverlay from '../../../../../hooks/useMobileRequestOverlay';
import MobileRequestOverlay from '../../../../../components/MobileRequestOverlay';

const TRUSTED_PORTAL_SX = {
  marginTop: 2,
  paddingLeft: 3,
  color: S1,
};

const TrustedPortalRow = () => {
  const [isUnprocessedEventsModalOpen, setIsUnprocessedEventsModalOpen] = useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState<boolean>(false);
  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { activateTrustedPortal, restartTrustedPortalEvents } = useQueueAPI();
  const activeOrganizationId = getActiveOrganizationId();
  const { data: organizationSecuritySettings } = useGetOrganizationSecuritySettings();
  const { trustedPortalAllowed } = organizationSecuritySettings ?? {};

  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;
  const isTrustedPortalInitialized = useTrustedPortalStore.useIsInitialized();
  const isTrustedPortalProcessing = useTrustedPortalStore.useIsProcessingByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const {
    data: unprocessedInfo,
    isFetchedAfterMount: isUnprocessedInfoFetchedAfterMount,
    remove: removeUnprocessedInfoFromCache,
  } = useGetUnprocessedInfo(
    { activeOrganizationId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
      enabled: isTrustedPortalInitialized && !isTrustedPortalEnabled,
    },
  );
  const { totalCount = 0, eventTypes = [] } = unprocessedInfo ?? {};
  const hasUnprocessedEvents = !!totalCount;

  const {
    isMobileRequestOverlayOpen,
    mobileRequestOverlayQueueMessageId,
    openMobileRequestOverlay,
    closeMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
  } = useMobileRequestOverlay();

  const handleUnprocessedEventsModalClose = useCallback(() => {
    if (!activeOrganizationId) return;
    setShouldShowUnprocessedEventsWarningByOrganizationId(activeOrganizationId, false);
    setIsUnprocessedEventsModalOpen(false);
  }, [activeOrganizationId]);

  const handleDeactivateModalOpen = useCallback(() => setIsDeactivateModalOpen(true), []);
  const handleDeactivateModalClose = useCallback(() => setIsDeactivateModalOpen(false), []);

  const handleRestartTrustedPortal = useCallback(async () => {
    try {
      clearMobileRequestOverlayTimeout();
      if (isTrustedPortalEnabled) {
        handleDeactivateModalOpen();
        return;
      }
      if (isUnprocessedEventsModalOpen) {
        handleUnprocessedEventsModalClose();
      }
      const { queueMessageId: id } = await activateTrustedPortal();
      openMobileRequestOverlay(id);
    } catch (e) {
      logException(e, { message: 'TrustedPortalRow/handleRestartTrustedPortal exception' });
      showError({
        text: t('common.somethingWentWrong'),
      });
    }
  }, [
    handleDeactivateModalOpen,
    clearMobileRequestOverlayTimeout,
    openMobileRequestOverlay,
    isTrustedPortalEnabled,
    isUnprocessedEventsModalOpen,
    handleUnprocessedEventsModalClose,
    activateTrustedPortal,
    showError,
    t,
  ]);

  const isWarningIconShown = useMemo(
    () => hasUnprocessedEvents && !isTrustedPortalEnabled,
    [hasUnprocessedEvents, isTrustedPortalEnabled],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<QueueMessageResultNotification>(
      RealtimeAPIEventTypeEnum.QueueMessageResultNotification,
      async (event) => {
        try {
          if (event.queueEvent !== QueueEventEnum.trustedPortalActivated) return;
          if (event.queueMessageId !== mobileRequestOverlayQueueMessageId) {
            return;
          }
          closeMobileRequestOverlay();
          showSuccess({ text: t('mobileRequestNotifications.trustPortalActivated') });
          await restartTrustedPortalEvents();
          removeUnprocessedInfoFromCache();
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message:
              'TrustedPortalRow/RealtimeAPIEventTypeEnum.QueueMessageResultNotification exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [
    mobileRequestOverlayQueueMessageId,
    closeMobileRequestOverlay,
    restartTrustedPortalEvents,
    removeUnprocessedInfoFromCache,
    showSuccess,
    showError,
    t,
  ]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent(
      RealtimeAPIEventTypeEnum.TrustedPortalRequestRejected,
      () => {
        closeMobileRequestOverlay();
        showError({
          text: t('mobileRequestNotifications.trustPortalRejected'),
        });
      },
    );
    return () => {
      unsubscribe();
    };
  }, [showError, closeMobileRequestOverlay, t]);

  useEffect(() => {
    if (!activeOrganizationId) {
      return;
    }
    if (!isUnprocessedInfoFetchedAfterMount) {
      return;
    }
    if (!hasUnprocessedEvents) {
      setShouldShowUnprocessedEventsWarningByOrganizationId(activeOrganizationId, false);
      return;
    }
    // eslint-disable-next-line max-len
    const shouldShowUnprocessedEventsWarning = getShouldShowUnprocessedEventsWarningByOrganizationId(activeOrganizationId);
    if (!shouldShowUnprocessedEventsWarning) {
      return;
    }
    setIsUnprocessedEventsModalOpen(true);
  }, [
    activeOrganizationId,
    hasUnprocessedEvents,
    isUnprocessedInfoFetchedAfterMount,
  ]);

  if (!trustedPortalAllowed || !isTrustedPortalInitialized) {
    return null;
  }

  return (
    <Grid container columnGap={1} sx={TRUSTED_PORTAL_SX}>
      <Grid item>
        <TrustIcon />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">
          {t('navigation.trustedPortal')}
        </Typography>
      </Grid>
      <Grid item>
        <TrustedPortalToggle
          onClick={handleRestartTrustedPortal}
          isModalOpen={isDeactivateModalOpen}
          onModalClose={handleDeactivateModalClose}
        />
      </Grid>
      {isWarningIconShown && (
        <Grid item>
          <Tooltip title={t('navigation.unprocessedEvents')} cursorPointer>
            <WarningIcon height={24} width={24} />
          </Tooltip>
        </Grid>
      )}
      {isTrustedPortalProcessing && (
        <Grid item>
          <Tooltip title={t('navigation.processingEvents')} cursorPointer>
            <Spinner color={S1} />
          </Tooltip>
        </Grid>
      )}
      {isUnprocessedEventsModalOpen && (
        <UnprocessedEventsModal
          isOpen={isUnprocessedEventsModalOpen}
          onClose={handleUnprocessedEventsModalClose}
          onSubmit={handleRestartTrustedPortal}
          unprocessedEvents={eventTypes}
        />
      )}
      <MobileRequestOverlay
        queueMessageId={mobileRequestOverlayQueueMessageId!}
        isOpen={isMobileRequestOverlayOpen}
        onCancel={closeMobileRequestOverlay}
      />
    </Grid>
  );
};

export default TrustedPortalRow;
