import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, ConditionalTabWrapper,
} from '@uniqkey-frontend/shared-app';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { useGetVaultPasswordById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import OrganizationLoginDetailsTab from '../OrganizationLoginDetailsTab';
import OrganizationLoginEmployeesToEmployeesTab from '../OrganizationLoginEmployeesToEmployeesTab';
import OrganizationLoginEmployeesToGroupsTab from '../OrganizationLoginEmployeesToGroupsTab';
import OrganizationLoginGroupsTab from '../OrganizationLoginGroupsTab';

export enum OrganizationLoginModuleTabEnum {
  Details = 'details',
  Employees = 'employees',
  Groups = 'groups'
}

interface IOrganizationLoginModuleProps {
  activeTab: OrganizationLoginModuleTabEnum;
}

interface IOrganizationLoginModuleContext {
  vaultId: string;
}

const OrganizationLoginModule = (props: IOrganizationLoginModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { vaultId } = useOutletContext<IOrganizationLoginModuleContext>();
  const navigate = useNavigate();

  const { isLoading, data: vault } = useGetVaultPasswordById(
    { vaultId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: OrganizationLoginModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.AllSecuredData}/logins/${vaultId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [vaultId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === OrganizationLoginModuleTabEnum.Details;

  const { usersCount, groupsCount } = vault ?? {};
  const withUsersCount = !!usersCount;
  const withGroupsCount = !!groupsCount;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('organizationLoginPage.tabs.loginDetails')}
            value={OrganizationLoginModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {(withUsersCount || withGroupsCount) && (
            <Tab
              label={t('organizationLoginPage.tabs.employees')}
              value={OrganizationLoginModuleTabEnum.Employees}
              onClick={handleTabClick}
            />
          )}
          {withGroupsCount && (
            <Tab
              label={t('organizationLoginPage.tabs.groups')}
              value={OrganizationLoginModuleTabEnum.Groups}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      {activeTab === OrganizationLoginModuleTabEnum.Details && (
        <OrganizationLoginDetailsTab vaultId={vaultId} />
      )}
      {activeTab === OrganizationLoginModuleTabEnum.Employees && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={withUsersCount || withGroupsCount}
          fallback={<Navigate to={`${PageRouteEnum.AllSecuredData}/logins/${vaultId}`} />}
        >
          <>
            {withUsersCount && (<OrganizationLoginEmployeesToEmployeesTab vaultId={vaultId} />)}
            {withGroupsCount && (<OrganizationLoginEmployeesToGroupsTab vaultId={vaultId} />)}
          </>
        </ConditionalTabWrapper>
      )}
      {activeTab === OrganizationLoginModuleTabEnum.Groups && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={withGroupsCount}
          fallback={<Navigate to={`${PageRouteEnum.AllSecuredData}/logins/${vaultId}`} />}
        >
          <OrganizationLoginGroupsTab vaultId={vaultId} />
        </ConditionalTabWrapper>
      )}
    </Panel>
  );
};

export default memo(OrganizationLoginModule);
