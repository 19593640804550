import {
  useCallback, Dispatch, SetStateAction, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
  IUseFilterButtonReturn,
  useFilterButton,
  TUseTablePreserverDecoderCustomConfig,
  useTablePreserverDecoderCustomConfigMerger,
  TablePreserverCommonInitialTableValues,
} from '@uniqkey-frontend/shared-app';
import {
  VaultSecureNotesOrderProperty,
  GetVaultCreditCardsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  TGetVaultCreditCardsMethod,
  IGetVaultCreditCardsParams,
} from '../../useVaultsAPI/interfaces';
import useVaultsAPI from '../../useVaultsAPI';
// eslint-disable-next-line max-len
import { IOrganizationCreditCardsTabFilterSubmitResult } from '../../../pages/AllSecuredDataPage/components/OrganizationCreditCardsTab/components/OrganizationCreditCardsTabFilter';
import tablePreservationConfigs from '../../../helpers/tablePreservation/configs';

interface IPersistentFilters extends Pick<
  IGetVaultCreditCardsParams, 'employeeAccountId' | 'groupId'
> {}

export interface IUseOrganizationCreditCardsTableProps {
  persistentFilters?: IPersistentFilters;
  customPreservationConfig?: ICustomPreservationConfig;
  noDataMessageKey: string;
}

export interface IOrganizationCreditCardsTableRow extends GetVaultCreditCardsResponseModel {
  lastActivity: string | null;
  maskedCardNumber: string;
}

export interface IUseOrganizationCreditCardsTableReturn extends
  Omit<IUseTableReturn<IOrganizationCreditCardsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseTableFetchReturn<GetVaultCreditCardsResponseModel>, 'data'>,
  IUseFilterButtonReturn
{
  creditCards: IUseTableFetchReturn<GetVaultCreditCardsResponseModel>['data'];
  selectedCreditCards: IUseTableReturn<IOrganizationCreditCardsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetVaultCreditCardsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetVaultCreditCardsParams['searchQuery']>>;
  filterValues: IOrganizationCreditCardsTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationCreditCardsTabFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetVaultCreditCardsParams,
    'page' | 'pageLength' | 'groupId' | 'employeeAccountId' | 'orderPropertyName' | 'isDescending'
    | 'cancelToken'
  >,
  Pick<IUseTableReturn<IOrganizationCreditCardsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetVaultCreditCardsParams['page'];
  perPage: IGetVaultCreditCardsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: VaultSecureNotesOrderProperty },
  ),
  columnOrderDirection: true,
  ownership: tablePreservationConfigs.ownershipWithoutUnmanaged,
};

interface ICustomPreservationConfig extends TUseTablePreserverDecoderCustomConfig<
  ITablePreservationConfigs, 'ownership'
> {}

const useOrganizationCreditCardsTable = (
  props: IUseOrganizationCreditCardsTableProps,
): IUseOrganizationCreditCardsTableReturn => {
  const {
    persistentFilters,
    customPreservationConfig,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVaultCreditCards } = useVaultsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const preservationConfig = useTablePreserverDecoderCustomConfigMerger<
    ITablePreservationConfigs, ICustomPreservationConfig
  >(PRESERVATION_CONFIGS, customPreservationConfig);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
    ownership: initialOwnership,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, preservationConfig);

  const [searchQuery, setSearchQuery] = useState<
    IGetVaultCreditCardsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationCreditCardsTabFilterSubmitResult
  >({
    ownership: initialOwnership,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedCreditCards,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationCreditCardsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      ownership: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: creditCards, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetVaultCreditCardsParams,
    TGetVaultCreditCardsMethod,
    GetVaultCreditCardsResponseModel
  >({
    request: getVaultCreditCards,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      ownership: filterValues.ownership,
      orderPropertyName: VaultSecureNotesOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof VaultSecureNotesOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    ownership: filterValues.ownership,
  });

  return {
    creditCards,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedCreditCards,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useOrganizationCreditCardsTable;
