import { useMemo, useCallback, ReactNode } from 'react';
import {
  Divider,
  Grid,
  S6,
  Typography,
  TypographyWithHelp,
  useSnackbar,
  useTranslations,
  WidgetContainer,
  TFunction,
  RenderIf,
  SecurityScoreNameEnum,
  SecurityScoreWidget,
} from '@uniqkey-frontend/shared-app';
import { Ownership } from '@uniqkey-backend-organization-web/api-client';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useGetAverageSecurityScore } from '../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';

const DEFAULT_VALUES = {
  averageSecurityScore: 0,
  weakPasswordPercent: 0,
  veryWeakEmployeeSecurityScorePercent: 0,
  weakEmployeeSecurityScorePercent: 0,
  veryWeakUnmanagedPasswordPercent: 0,
  weakUnmanagedPasswordPercent: 0,
  noData: false,
};

interface IWrapperProps {
  children: ReactNode;
  isLoading: boolean;
  t: TFunction;
}

const CONTAINER_SX = { height: '100%', minHeight: 270 };

const Wrapper = (props: IWrapperProps) => {
  const { children, isLoading, t } = props;

  return (
    <WidgetContainer container withShadow isLoading={isLoading} sx={CONTAINER_SX}>
      <Grid container alignContent="space-between" justifyContent="flex-start">
        <Grid item>
          <TypographyWithHelp
            helperText={t('dashboardAverageSecurityScoreWidget.tooltip')}
            color={S6}
          >
            {t('dashboardAverageSecurityScoreWidget.title')}
          </TypographyWithHelp>
        </Grid>
        {children}
      </Grid>
    </WidgetContainer>
  );
};

const DashboardAverageSecurityScoreWidget = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { data: averageSecurityScoreData, isLoading, isError } = useGetAverageSecurityScore({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const handleEmployeesNavigation = useCallback((securityScoreName: SecurityScoreNameEnum) => {
    const search = createSearchParams({ securityScoreName }).toString();
    navigate({
      pathname: PageRouteEnum.Employees,
      search,
    });
  }, [navigate]);

  const handleLoginsNavigation = useCallback((
    securityScoreName: SecurityScoreNameEnum,
    ownership: Ownership,
  ) => {
    const search = createSearchParams({ securityScoreName, ownership }).toString();
    navigate({
      pathname: PageRouteEnum.AllSecuredData,
      search,
    });
  }, [navigate]);

  const handleVeryWeakEmployeeSecurityScoreLinkClick = useCallback(() => {
    handleEmployeesNavigation(SecurityScoreNameEnum.VeryWeak);
  }, [handleEmployeesNavigation]);

  const handleWeakEmployeeSecurityScoreLinkClick = useCallback(() => {
    handleEmployeesNavigation(SecurityScoreNameEnum.Weak);
  }, [handleEmployeesNavigation]);

  const handleVeryWeakUnmanagedPasswordLinkClick = useCallback(() => {
    handleLoginsNavigation(
      SecurityScoreNameEnum.VeryWeak,
      Ownership.Unmanaged,
    );
  }, [handleLoginsNavigation]);

  const handleWeakUnmanagedPasswordLinkClick = useCallback(() => {
    handleLoginsNavigation(
      SecurityScoreNameEnum.Weak,
      Ownership.Unmanaged,
    );
  }, [handleLoginsNavigation]);

  const {
    averageSecurityScore,
    veryWeakEmployeeSecurityScorePercent,
    weakEmployeeSecurityScorePercent,
    veryWeakUnmanagedPasswordPercent,
    weakUnmanagedPasswordPercent,
    noData,
  } = averageSecurityScoreData ?? DEFAULT_VALUES;

  const {
    showVeryWeakEmployeeDivider, showWeakEmployeeDivider, showVeryWeakUnmanagedPasswordDivider,
  } = useMemo(() => {
    let isVeryWeakEmployeeDividerShown = false;
    let isWeakEmployeeDividerShown = false;
    let isVeryWeakUnmanagedPasswordDividerShown = false;
    if (
      !!weakEmployeeSecurityScorePercent
      || !!veryWeakUnmanagedPasswordPercent
      || !!weakUnmanagedPasswordPercent
    ) {
      isVeryWeakEmployeeDividerShown = true;
    }
    if (!!veryWeakUnmanagedPasswordPercent || !!weakUnmanagedPasswordPercent) {
      isWeakEmployeeDividerShown = true;
    }
    if (weakUnmanagedPasswordPercent) {
      isVeryWeakUnmanagedPasswordDividerShown = true;
    }
    return {
      showVeryWeakEmployeeDivider: isVeryWeakEmployeeDividerShown,
      showWeakEmployeeDivider: isWeakEmployeeDividerShown,
      showVeryWeakUnmanagedPasswordDivider: isVeryWeakUnmanagedPasswordDividerShown,
    };
  }, [
    weakEmployeeSecurityScorePercent,
    veryWeakUnmanagedPasswordPercent,
    weakUnmanagedPasswordPercent,
  ]);

  if (isError) {
    return null;
  }

  if (noData) {
    return (
      <Wrapper isLoading={isLoading} t={t}>
        <Grid item container justifyContent="center">
          <Typography>
            {t('dashboardAverageSecurityScoreWidget.noData')}
          </Typography>
        </Grid>
        <Grid item container />
      </Wrapper>
    );
  }

  return (
    <Wrapper isLoading={isLoading} t={t}>
      <Grid item container justifyContent="center">
        <SecurityScoreWidget percentage={averageSecurityScore} t={t} />
      </Grid>
      <Grid item container rowGap={1}>
        <RenderIf condition={!!veryWeakEmployeeSecurityScorePercent}>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item container columnGap={1} xs wrap="nowrap">
              <Grid item>
                <Typography variant="subtitle1">
                  {/* eslint-disable-next-line max-len */}
                  {t('dashboardAverageSecurityScoreWidget.veryWeakEmployeeSecurityScore.boldLeftSubtitle')}
                </Typography>
              </Grid>
              <Grid item>
                <TypographyWithHelp
                  helperText={t(
                    'dashboardAverageSecurityScoreWidget.veryWeakEmployeeSecurityScore.tooltip',
                  )}
                >
                  {t(
                    // eslint-disable-next-line max-len
                    'dashboardAverageSecurityScoreWidget.veryWeakEmployeeSecurityScore.normalRightSubtitle',
                    { percentage: veryWeakEmployeeSecurityScorePercent },
                  )}
                </TypographyWithHelp>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                asLink
                variant="subtitle1"
                onClick={handleVeryWeakEmployeeSecurityScoreLinkClick}
              >
                {t('dashboardAverageSecurityScoreWidget.viewUsers')}
              </Typography>
            </Grid>
          </Grid>
          <RenderIf condition={showVeryWeakEmployeeDivider}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </RenderIf>
        </RenderIf>
        <RenderIf condition={!!weakEmployeeSecurityScorePercent}>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item container columnGap={1} xs wrap="nowrap">
              <Grid item>
                <Typography variant="subtitle1">
                  {/* eslint-disable-next-line max-len */}
                  {t('dashboardAverageSecurityScoreWidget.weakEmployeeSecurityScore.boldLeftSubtitle')}
                </Typography>
              </Grid>
              <Grid item>
                <TypographyWithHelp
                  helperText={t(
                    'dashboardAverageSecurityScoreWidget.weakEmployeeSecurityScore.tooltip',
                  )}
                >
                  {t(
                    // eslint-disable-next-line max-len
                    'dashboardAverageSecurityScoreWidget.weakEmployeeSecurityScore.normalRightSubtitle',
                    { percentage: weakEmployeeSecurityScorePercent },
                  )}
                </TypographyWithHelp>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                asLink
                variant="subtitle1"
                onClick={handleWeakEmployeeSecurityScoreLinkClick}
              >
                {t('dashboardAverageSecurityScoreWidget.viewUsers')}
              </Typography>
            </Grid>
          </Grid>
          <RenderIf condition={showWeakEmployeeDivider}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </RenderIf>
        </RenderIf>
        <RenderIf condition={!!veryWeakUnmanagedPasswordPercent}>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item container columnGap={1} xs wrap="nowrap">
              <Grid item>
                <Typography variant="subtitle1">
                  {/* eslint-disable-next-line max-len */}
                  {t('dashboardAverageSecurityScoreWidget.veryWeakUnmanagedPassword.boldLeftSubtitle')}
                </Typography>
              </Grid>
              <Grid item>
                <TypographyWithHelp
                  helperText={t(
                    'dashboardAverageSecurityScoreWidget.veryWeakUnmanagedPassword.tooltip',
                  )}
                >
                  {t(
                    // eslint-disable-next-line max-len
                    'dashboardAverageSecurityScoreWidget.veryWeakUnmanagedPassword.normalRightSubtitle',
                    { percentage: veryWeakUnmanagedPasswordPercent },
                  )}
                </TypographyWithHelp>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                asLink
                variant="subtitle1"
                onClick={handleVeryWeakUnmanagedPasswordLinkClick}
              >
                {t('dashboardAverageSecurityScoreWidget.viewLogins')}
              </Typography>
            </Grid>
          </Grid>
          <RenderIf condition={showVeryWeakUnmanagedPasswordDivider}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </RenderIf>
        </RenderIf>
        <RenderIf condition={!!weakUnmanagedPasswordPercent}>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item container columnGap={1} xs wrap="nowrap">
              <Grid item>
                <Typography variant="subtitle1">
                  {t('dashboardAverageSecurityScoreWidget.weakUnmanagedPassword.boldLeftSubtitle')}
                </Typography>
              </Grid>
              <Grid item>
                <TypographyWithHelp
                  helperText={t(
                    'dashboardAverageSecurityScoreWidget.weakUnmanagedPassword.tooltip',
                  )}
                >
                  {t(
                    'dashboardAverageSecurityScoreWidget.weakUnmanagedPassword.normalRightSubtitle',
                    { percentage: weakUnmanagedPasswordPercent },
                  )}
                </TypographyWithHelp>
              </Grid>
            </Grid>
            <Grid item>
              <Typography asLink variant="subtitle1" onClick={handleWeakUnmanagedPasswordLinkClick}>
                {t('dashboardAverageSecurityScoreWidget.viewLogins')}
              </Typography>
            </Grid>
          </Grid>
        </RenderIf>
      </Grid>
    </Wrapper>
  );
};

export default DashboardAverageSecurityScoreWidget;
