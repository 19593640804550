import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
  MutableRefObject,
  SyntheticEvent,
} from 'react';
import {
  Box,
  DragAndDropContainer,
  Grid,
  S5,
  TypographyWithHelp,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { read } from '../../helpers';
import { IReadEmployeesResult } from '../../interfaces';
import { logException } from '../../../../../../services/sentryService';

interface IInviteEmployeesFormProps {
  onSubmit: (results: IReadEmployeesResult[]) => void;
}

const InviteEmployeesForm = (props: IInviteEmployeesFormProps) => {
  const { onSubmit } = props;
  const [files, setFiles] = useState<File[] | null>(null);
  const fileInputRef: MutableRefObject<
    HTMLInputElement | null
  > = useRef<HTMLInputElement>(null);
  const { showError } = useSnackbar();
  const { t } = useTranslations();

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item: { files: File[] }) { setFiles(item.files); },
    collect: (monitor: DropTargetMonitor) => ({ canDrop: monitor.canDrop() }),
  }), []);

  const handleChooseFileClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileInputChange = useCallback((event: SyntheticEvent) => {
    const { files: newFiles } = event.target as HTMLInputElement;
    setFiles(newFiles as unknown as File[]);
  }, []);

  const handleClick = useCallback((event: SyntheticEvent) => {
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLTextAreaElement).value = '';
  }, []);

  const dragAndDropLocalization = useMemo(() => ({
    chooseFile: t('inviteEmployeesModal.chooseFile'),
    orDragAndDrop: t('inviteEmployeesModal.orDragAndDrop'),
    dropFileHere: t('inviteEmployeesModal.dropFileHere'),
  }), [t]);

  const readFiles = useCallback(async (filesToRead: File[]) => {
    try {
      const results: IReadEmployeesResult[] = await read({ files: filesToRead });
      setFiles(null);
      onSubmit(results);
    } catch (e) {
      setFiles(null);
      showError({
        text: t(e instanceof Error ? e.message : 'common.somethingWentWrong'),
      });
      logException(e, {
        message: 'InviteEmployeesForm/readFiles exception',
      });
    }
  }, [onSubmit, showError, t]);

  useEffect(() => {
    if (!files) {
      return;
    }
    readFiles(files);
  }, [files, readFiles]);

  return (
    <Grid>
      <TypographyWithHelp
        color={S5}
        helperText={t('inviteEmployeesModal.uploadFromCSVFileTooltip')}
      >
        {t('inviteEmployeesModal.uploadFromCSVFile')}
      </TypographyWithHelp>
      <Box mt={2} />
      <DragAndDropContainer
        ref={drop}
        height={72}
        localization={dragAndDropLocalization}
        canDrop={canDrop}
        fileInputRef={fileInputRef}
        onChooseFileClick={handleChooseFileClick}
        onFileInputChange={handleFileInputChange}
        onClick={handleClick}
      />
    </Grid>
  );
};

export default memo(InviteEmployeesForm);
