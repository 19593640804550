import { Suspense } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../helpers/ChartJSLib/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './Router';
import UserProviderContext from './contexts/UserContext/provider';
import APIClientsProviderContext from './contexts/APIClientsContext/provider';
import InitializationContainer from './components/InitializationContainer';
import { initWebSockets } from '../services/webSocketsManager';
import CompanionApplicationProviderContext from './contexts/CompanionApplicationContext/provider';

initWebSockets();

const ReactQueryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={ReactQueryClient}>
    <UserProviderContext>
      <APIClientsProviderContext>
        <CompanionApplicationProviderContext>
          <InitializationContainer>
            <Suspense fallback={null}>
              <Router />
            </Suspense>
          </InitializationContainer>
        </CompanionApplicationProviderContext>
        <ReactQueryDevtools />
      </APIClientsProviderContext>
    </UserProviderContext>
  </QueryClientProvider>
);

export default App;
