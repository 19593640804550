import { useCallback, memo } from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  useTranslations,
  SearchableTextField,
  usePubSub,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import useServiceLoginsTable from '../../../../hooks/tables/useServiceLoginsTable';
import ServiceLoginsTable from '../../../../components/tables/ServiceLoginsTable';

interface IServiceLoginsTabProps {
  applicationName: string;
}

const ServiceLoginsTab = (props: IServiceLoginsTabProps) => {
  const { applicationName } = props;
  const { t } = useTranslations();

  const {
    refetch,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps
  } = useServiceLoginsTable({
    persistentFilters: { applicationName },
  });

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT, handleRefetch);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" />
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <ServiceLoginsTable {...restTableProps} />
    </PanelContent>
  );
};

export default memo(ServiceLoginsTab);
