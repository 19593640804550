import { createContext, useContext } from 'react';
import { MigrationApi } from '@uniqkey-backend-organization-web/api-client';
import { AxiosInstance } from 'axios';
import config from '../../../config';
import axiosInstance from '../../axios';

export interface IAPIClientsContext {
  axiosInstance: AxiosInstance;
  migrationAPIClient: MigrationApi;
  migrationLoginAPIClient: MigrationApi;
}

const WEB_API_BASE_URL = config.getWebApiUrl();

export const APIClientDefaultContext: IAPIClientsContext = {
  axiosInstance,
  migrationLoginAPIClient: new MigrationApi(
    undefined,
    WEB_API_BASE_URL,
  ),
  migrationAPIClient: new MigrationApi(
    undefined,
    WEB_API_BASE_URL,
    axiosInstance,
  ),
};

const ApiClientsContext = createContext<IAPIClientsContext>(APIClientDefaultContext);

ApiClientsContext.displayName = 'APIClientsContext';

export default ApiClientsContext;

export const useApiClients = (): IAPIClientsContext => useContext<
  IAPIClientsContext
>(ApiClientsContext);
