import { type AxiosInstance } from 'axios';
import { type QueueMessagesInfo } from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalActionPayload } from '../interfaces';
import Helpers from '../helpers';
import Handlers from './handlers';

const handleEvent = async (
  event: QueueMessagesInfo,
  axiosInstance: AxiosInstance,
  organizationPrivateKey: ITrustedPortalActionPayload['organizationPrivateKey'],
  organizationPublicKey: ITrustedPortalActionPayload['organizationPublicKey'],
) => {
  const startTime = performance.now();

  const handler = Handlers.getHandler(event.queueEvent);

  if (!handler) {
    throw new Error(
      `TrustedPortalModule/handleEvent - not handled event type: ${event.queueEvent}`,
    );
  }

  const parsedPayload = JSON.parse(event.payload);
  const parsedEvent = { ...event, parsedPayload };

  await handler({
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  });

  const endTime = performance.now();

  Helpers.tpLogMessage({
    messages: [
      '[HANDLER] handling finished',
      `\n\tevent name : ${event.queueEvent}`,
      `\n\tevent id   : ${event.queueMessageId}`,
      `\n\ttime spent : ${endTime - startTime} ms`,
    ],
  });
};

export default {
  handleEvent,
};
