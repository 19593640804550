import { Outlet } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';

const SettingsPage = () => (
  <Grid container flexDirection="column" className="height-100-percent">
    <Grid item xs>
      <Outlet />
    </Grid>
  </Grid>
);

export default SettingsPage;
