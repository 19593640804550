import { AxiosResponse } from 'axios';
import {
  GetGroupRestrictionsResponseModel,
  IpGroupRestrictionModel,
  TimeOfDayGroupRestrictionModel,
  GeolocationGroupRestrictionModel,
  GetAuditLogsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { PatchOperationEnum } from '@uniqkey-frontend/shared-app';
import { TRestriction } from '../hooks/useGroupRestrictionsAPI/interfaces';
import { IAuditLog } from '../hooks/useAuditLogsAPI/interfaces';
import { logException } from '../services/sentryService';

export interface IReplacePatchOperation<Value = unknown> {
  op: PatchOperationEnum;
  path: string;
  value: Value;
}

export const createReplacePatchOperation = <Value = unknown>(
  path: string, value: Value,
): IReplacePatchOperation<Value> => {
  const processedPath = path.startsWith('/') ? path : `/${path}`;
  return { op: PatchOperationEnum.Replace, path: processedPath, value };
};

export const parseReplacePatchOperation = <Value = unknown>(
  operation: IReplacePatchOperation<Value>,
) => {
  const { op, path, value } = operation;
  if (op !== PatchOperationEnum.Replace) {
    throw new Error(`Method "${
      parseReplacePatchOperation.name
    }" parses only "${PatchOperationEnum.Replace}" operations.`);
  }
  const processedPath = path.startsWith('/') ? path.substring(1) : path;
  return { [processedPath]: value };
};

export const dataExtractor = <T>() => (response: AxiosResponse<T>): T => response.data;

type TParsedRestrictionContent = IpGroupRestrictionModel
  | TimeOfDayGroupRestrictionModel
  | GeolocationGroupRestrictionModel;
export const restrictionContentExtractor = (
  restriction: GetGroupRestrictionsResponseModel,
): TRestriction => {
  let parsedRestrictionContent;
  try {
    parsedRestrictionContent = JSON.parse(restriction.data);
  } catch (e) {
    logException(e, {
      message: 'restrictionContentExtractor/canNotParse/restriction.data exception',
      data: { payload: restriction?.data },
    });
    parsedRestrictionContent = {};
  }
  return { ...restriction, ...parsedRestrictionContent as TParsedRestrictionContent };
};

export const auditLogPayloadExtractor = (auditLog: GetAuditLogsResponseModel): IAuditLog => {
  let parsedPayload;
  let parsedEnrichedPayload;
  try {
    parsedPayload = JSON.parse(auditLog.payload);
  } catch (e) {
    logException(e, {
      message: 'auditLogPayloadExtractor/canNotParse/auditLog.payload exception',
      data: {
        payload: auditLog?.payload,
      },
    });
    parsedPayload = {};
  }
  try {
    parsedEnrichedPayload = JSON.parse(auditLog.enrichedPayload);
  } catch (e) {
    logException(e, {
      message: 'auditLogPayloadExtractor/canNotParse/auditLog.enrichedPayload exception',
      data: {
        payload: auditLog?.enrichedPayload,
      },
    });
    parsedEnrichedPayload = {};
  }
  return { ...auditLog, parsedPayload, parsedEnrichedPayload };
};
