import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  type RefreshSessionTokenResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import { type ITrustedPortalWorker } from '../Worker';
import config from '../../../config';
import { employeeAxiosInstance } from '../../../axios';
import { dataExtractor } from '../../../helpers/apiClients';
import { getCompanionApplicationId } from '../../../services/companionApplicationService';
import Constants from '../constants';
import Helpers from '../helpers';
import APIClientsProvider from '../apiClientsProvider';

const MOBILE_API_BASE_URL = config.getMobileApiUrl();

const axiosInstanceWithoutAuthRefresh = axios.create({
  baseURL: MOBILE_API_BASE_URL,
  timeout: config.requestTimeout,
});

const trustedPortalAPIClientWithoutAuthRefresh = APIClientsProvider
  .Mobile.getTrustedPortalAPIClient(axiosInstanceWithoutAuthRefresh);

const createTrustedPortalWorkerInstance = (
  getTokens: ITrustedPortalWorker['getTokens'],
  setTokens: ITrustedPortalWorker['setTokens'],
): AxiosInstance => {
  const instance = axios.create({
    baseURL: MOBILE_API_BASE_URL,
    timeout: config.requestTimeout,
  });

  instance.interceptors.request.use(async (axiosRequestConfig) => {
    const tokens = getTokens();
    if (axiosRequestConfig.headers && tokens.token) {
      // eslint-disable-next-line no-param-reassign
      axiosRequestConfig.headers.Authorization = `Bearer ${tokens.token}`;
      const companionApplicationId = getCompanionApplicationId();
      if (companionApplicationId) {
        // eslint-disable-next-line no-param-reassign
        axiosRequestConfig.headers[
          Constants.COMPANION_APPLICATION_ID_HEADER
        ] = companionApplicationId;
      }
    }
    return axiosRequestConfig;
  });

  createAuthRefreshInterceptor(instance, async (failedRequest: any) => {
    const prevTokens = getTokens();
    const tokens = await trustedPortalAPIClientWithoutAuthRefresh
      .apiV1TrustedPortalRefreshTokenPost(prevTokens)
      .then(dataExtractor<RefreshSessionTokenResponse>());
      // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${tokens.token}`;
    setTokens(tokens);
  });

  return instance;
};

const attachTrustedPortalAxiosInterceptor = () => {
  employeeAxiosInstance.interceptors.request.use(async (axiosRequestConfig: AxiosRequestConfig) => {
    Helpers.setTrustedPortalSessionAxiosHeader(axiosRequestConfig);
    return axiosRequestConfig;
  });
};

export default {
  createTrustedPortalWorkerInstance,
  attachTrustedPortalAxiosInterceptor,
};
