import {
  useCallback, useState, memo, useMemo,
} from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  SearchableTextField,
  useTranslations,
  usePubSub,
  Box,
  Button,
  PlusIcon,
  useSnackbar,
  Tooltip,
  ActionButton,
  ShareIcon,
  encryptSymmetric,
  ErrorIcon,
  useMapKeyValueExtractor,
  PromptModal,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import useEmployeeGroupSecureNotesTable, {
  IEmployeeGroupSecureNotesTableRow,
} from '../../../../hooks/tables/useEmployeeGroupSecureNotesTable';
import GroupSecureNotesTable from '../../../../components/tables/GroupSecureNotesTable';
import CreateSecureNoteModal, {
  ICreateSecureNoteReturnValue,
} from '../../../../components/CreateSecureNoteModal';
import useVaultsToGroupsAPI from '../../../../hooks/useVaultsToGroupsAPI';
import { useDeleteVaultsToGroups, useGetGroupById } from '../../../../hooks/reactQuery';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { generateTooltipTitle } from '../../../../helpers/tooltips';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';

interface IGroupSecureNotesTabProps {
  groupId: string;
}

const GroupSecureNotesTab = (props: IGroupSecureNotesTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const [isCreateSecureNoteModalOpen, setIsCreateSecureNoteModalOpen] = useState<boolean>(false);
  const [isCreateSecureNoteLoading, setIsCreateSecureNoteLoading] = useState<boolean>(false);
  const [isDeleteSecureNoteModalOpen, setIsDeleteSecureNoteModalOpen] = useState<boolean>(false);
  const [isDeleteSecureNoteLoading, setIsDeleteSecureNoteLoading] = useState<boolean>(false);
  const [isShareSecureNotesModalOpen, setIsShareSecureNotesModalOpen] = useState<boolean>(false);

  const handleCreateSecureNoteModalOpen = useCallback(
    () => setIsCreateSecureNoteModalOpen(true),
    [],
  );
  const handleCreateSecureNoteModalClose = useCallback(
    () => setIsCreateSecureNoteModalOpen(false),
    [],
  );
  const handleDeleteSecureNoteModalOpen = useCallback(
    () => setIsDeleteSecureNoteModalOpen(true),
    [],
  );
  const handleDeleteSecureNoteModalClose = useCallback(
    () => setIsDeleteSecureNoteModalOpen(false),
    [],
  );
  const handleShareSecureNotesModalOpen = useCallback(
    () => setIsShareSecureNotesModalOpen(true),
    [],
  );
  const handleShareSecureNotesModalClose = useCallback(
    () => setIsShareSecureNotesModalOpen(false),
    [],
  );

  const { showError, showWarning, showSuccess } = useSnackbar();
  const { createVaultsSecureNoteToGroups } = useVaultsToGroupsAPI();
  const { mutate: mutateDeleteSecureNote } = useDeleteVaultsToGroups();
  const { symmetricKey } = useCompanionApplicationContext();

  const {
    selectedSecureNotes,
    refetch,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useEmployeeGroupSecureNotesTable({
    persistentFilters: { groupId },
    customPreservationConfig: { ownership: false },
    noDataMessageKey: 'secureNotesTab.table.noData',
  });
  const { data: group } = useGetGroupById(
    { groupId },
  );
  const {
    values: selectedSecureNotesAsObjects, keys: selectedSecureNotesIds,
  } = useMapKeyValueExtractor<IEmployeeGroupSecureNotesTableRow>(selectedSecureNotes);

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleCreateSecureNote = useCallback(async (params: ICreateSecureNoteReturnValue) => {
    const { name, note } = params;
    setIsCreateSecureNoteLoading(true);
    try {
      await createVaultsSecureNoteToGroups({
        groupId,
        name,
        note: encryptSymmetric({
          key: symmetricKey,
          string: note,
          fallbackValue: null,
        }),
      });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.secureNote.created'),
        });
      } else {
        showWarning({
          text: t('createSecureNoteModal.approvalOnMobileToast'),
        });
      }
      handleCreateSecureNoteModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupSecureNotesTab/handleCreateSecureNote exception',
      });
    } finally {
      setIsCreateSecureNoteLoading(false);
    }
  }, [
    createVaultsSecureNoteToGroups,
    handleCreateSecureNoteModalClose,
    groupId,
    symmetricKey,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleDeleteSecureNote = useCallback(async () => {
    setIsDeleteSecureNoteLoading(true);
    mutateDeleteSecureNote({
      vaultIds: selectedSecureNotesIds, groupId,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteSecureNoteFromGroupModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteSecureNoteFromGroupModal.errorMessage', { count: failCount }),
          });
        }
        handleDeleteSecureNoteModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSettled: () => setIsDeleteSecureNoteLoading(false),
    });
  }, [
    groupId,
    selectedSecureNotesIds,
    mutateDeleteSecureNote,
    handleDeleteSecureNoteModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_VAULT, handleRefetch);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const { shareTooltipTitle, removeTooltipTitle } = useMemo(() => {
    const shareTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotes.size,
      t,
      isDisabled: false,
      key: 'secureNotesTab.share',
    });
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotes.size,
      t,
      isDisabled: false,
      key: 'secureNotesTab.remove',
    });

    return {
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
    };
  }, [selectedSecureNotes.size, t]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareSecureNotesModalOpen}
                disabled={!selectedSecureNotes.size}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteSecureNoteModalOpen}
                disabled={!selectedSecureNotes.size}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          <Box mr={3} />
          <Grid item>
            <Button
              icon={<PlusIcon />}
              onClick={handleCreateSecureNoteModalOpen}
            >
              {t('secureNotesTab.createSecureNoteButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <GroupSecureNotesTable
        selectedSecureNotes={selectedSecureNotes}
        {...restTableProps}
      />
      {isCreateSecureNoteModalOpen && (
        <CreateSecureNoteModal
          isOpen={isCreateSecureNoteModalOpen}
          onClose={handleCreateSecureNoteModalClose}
          onSubmit={handleCreateSecureNote}
          isLoading={isCreateSecureNoteLoading}
        />
      )}
      {isDeleteSecureNoteModalOpen && (
        <PromptModal
          open={isDeleteSecureNoteModalOpen}
          onClose={handleDeleteSecureNoteModalClose}
          onSubmit={handleDeleteSecureNote}
          title={t('deleteSecureNoteFromGroupModal.title', {
            count: selectedSecureNotes.size,
            name: group?.name,
          })}
          description={t('deleteSecureNoteFromGroupModal.description')}
          list={selectedSecureNotesAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteSecureNoteLoading}
        />
      )}
      {isShareSecureNotesModalOpen && (
        <ShareVaultsToGroupModal
          isOpen={isShareSecureNotesModalOpen}
          onClose={handleShareSecureNotesModalClose}
          vaults={selectedSecureNotesAsObjects}
          type={VaultTypeEnum.SecureNote}
          onSubmit={resetSelectedRows}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupSecureNotesTab);
