/* eslint-disable */
const MigrationImage = () => (
  <svg width="195" height="169" viewBox="0 0 195 169" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="62.6035" y="94.835" width="28.434" height="10.2524" transform="rotate(-180 62.6035 94.835)" fill="#1084EF"/>
    <rect x="160.831" y="94.835" width="98.2264" height="10.2524" transform="rotate(-180 160.831 94.835)" fill="#D8E6FC"/>
    <rect x="29" width="137" height="37.165" rx="5.16505" fill="#3A9DF9"/>
    <ellipse cx="47.7407" cy="18.5825" rx="13.5708" ry="13.4563" fill="white"/>
    <path d="M66.4805 14.0971C66.4805 11.9738 68.2018 10.2524 70.3251 10.2524H98.8245C100.948 10.2524 102.669 11.9738 102.669 14.0971C102.669 16.2204 100.948 17.9418 98.8245 17.9418H70.3251C68.2018 17.9418 66.4805 16.2204 66.4805 14.0971Z" fill="white"/>
    <rect x="66.4805" y="21.7864" width="52.9906" height="5.12621" rx="2.56311" fill="white"/>
    <path d="M151.593 14.4868C151.593 13.3854 152.919 12.7995 153.761 13.5288L158.488 17.6245C159.076 18.1333 159.076 19.0315 158.488 19.5403L153.761 23.6359C152.919 24.3652 151.593 23.7793 151.593 22.678L151.593 18.5824L151.593 14.4868Z" fill="white"/>
    <rect x="62.6035" y="47.4175" width="28.434" height="10.2524" transform="rotate(-180 62.6035 47.4175)" fill="#1084EF"/>
    <rect x="160.831" y="47.4175" width="98.2264" height="10.2524" transform="rotate(-180 160.831 47.4175)" fill="#D8E6FC"/>
    <rect x="29" y="47.4174" width="137" height="37.165" rx="5.16505" fill="#3A9DF9"/>
    <ellipse cx="47.7407" cy="65.9999" rx="13.5708" ry="13.4563" fill="white"/>
    <path d="M66.4805 61.5145C66.4805 59.3911 68.2018 57.6698 70.3251 57.6698H98.8245C100.948 57.6698 102.669 59.3911 102.669 61.5145C102.669 63.6378 100.948 65.3591 98.8245 65.3591H70.3251C68.2018 65.3591 66.4805 63.6378 66.4805 61.5145Z" fill="white"/>
    <rect x="66.4805" y="69.2037" width="52.9906" height="5.12621" rx="2.56311" fill="white"/>
    <path d="M151.568 61.5307C151.568 60.4294 152.894 59.8435 153.736 60.5728L158.894 65.0419C159.482 65.5508 159.482 66.449 158.894 66.9578L153.736 71.4269C152.894 72.1562 151.568 71.5703 151.568 70.469L151.568 65.9999L151.568 61.5307Z" fill="white"/>
    <ellipse opacity="0.1" cx="97.5" cy="132.5" rx="97.5" ry="36.5" fill="#3A9DF9"/>
    <rect x="29" y="94.835" width="137" height="37.165" rx="5.16505" fill="#3A9DF9"/>
    <ellipse cx="47.7407" cy="113.417" rx="13.5708" ry="13.4563" fill="white"/>
    <path d="M66.4805 108.932C66.4805 106.809 68.2018 105.087 70.3251 105.087H98.8245C100.948 105.087 102.669 106.809 102.669 108.932C102.669 111.055 100.948 112.777 98.8245 112.777H70.3251C68.2018 112.777 66.4805 111.055 66.4805 108.932Z" fill="white"/>
    <rect x="66.4805" y="116.621" width="52.9906" height="5.12621" rx="2.56311" fill="white"/>
    <path d="M151.593 109.322C151.593 108.221 152.919 107.635 153.761 108.364L158.488 112.46C159.076 112.968 159.076 113.867 158.488 114.376L153.761 118.471C152.919 119.201 151.593 118.615 151.593 117.513L151.593 113.418L151.593 109.322Z" fill="white"/>
    <path d="M98 96C114.569 96 128 82.5685 128 66C128 49.4315 114.569 36 98 36C81.4315 36 68 49.4315 68 66C68 82.5685 81.4315 96 98 96Z" fill="#7FB434" stroke="white" strokeWidth="4"/>
    <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M86.0009 40.6934C89.6374 38.9664 93.7052 38 97.9986 38C113.463 38 125.999 50.536 125.999 66C125.999 70.2942 125.032 74.3626 123.304 77.9995C119.668 79.7266 115.6 80.693 111.307 80.693C95.8427 80.693 83.3066 68.1569 83.3066 52.693C83.3066 48.3988 84.2733 44.3303 86.0009 40.6934Z" fill="white"/>
    <path d="M109.93 59.4259L93.6892 74.5216L85.5825 66.9737" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default MigrationImage;