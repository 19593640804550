enum ReactQueryKeyEnum {
  CurrentUser = 'currentUser',
  CurrentEmployee = 'currentEmployee',
  Employee = 'employee',
  Group = 'group',
  Organization = 'organization',
  OrganizationSecuritySettings = 'organizationSecuritySettings',
  OrganizationScimSettings = 'organizationScimSettings',
  Login = 'login',
  Service = 'service',
  EmployeeSecurityScore = 'employeeSecurityScore',
  DashboardEmployeeAccountsOnBoard = 'dashboardEmployeeAccountsOnBoard',
  DashboardEmployeeActivationRate = 'dashboardEmployeeActivationRate',
  DashboardActiveEmployeesRate = 'dashboardActiveEmployeesRate',
  DashboardAverageSecurityScore = 'dashboardAverageSecurityScore',
  DashboardSecurityScoreRate = 'dashboardSecurityScoreRate',
  DesktopApplicationNames = 'desktopApplicationNames',
  ServicesByApplicationId = 'servicesByApplicationId',
  Services = 'services',
  DashboardUnusedServices = 'dashboardUnusedServices',
  UnprocessedInfo = 'unprocessedInfo',
  UnprocessedSCIMUsersCount = 'unprocessedSCIMUsersCount',
}

export default ReactQueryKeyEnum;
