import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  getTimeFromNow,
  Grid,
  LoginAvatar,
  SecurityScoreIcon,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import { GetVaultPasswordsResponseModel } from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { OWNERSHIP_COLORS, OWNERSHIP_TRANSLATION_KEYS } from '../../../constants';

export interface IOrganizationLoginsTableRow extends GetVaultPasswordsResponseModel {
  lastActivity: string | null;
}

interface IOrganizationLoginsTableProps extends
  Omit<IUseTableReturn<IOrganizationLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetVaultPasswordsResponseModel>, 'data' | 'refetch'>
{
  logins: IUseTableFetchReturn<GetVaultPasswordsResponseModel>['data'];
  selectedLogins: IUseTableReturn<IOrganizationLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationLoginsTableRow> = {
  selection: true,
};

const SHARED_WITH_COLUMN_TOOLTIP_PROPS = { cursorPointer: true };

const TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationLoginsTable = (props: IOrganizationLoginsTableProps) => {
  const {
    logins: preLogins,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedLogins,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const logins = useMemo(() => preLogins.map((login) => ({
    ...login,
    lastActivity: getTimeFromNow({
      date: login.activityAt,
      locale: currentLanguage,
    }),
  })), [preLogins, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationLoginsPage.service'),
      field: 'applicationName',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <LoginAvatar src={rowData.iconUrl} />
          <TypographyWithTooltip variant="body2">
            {rowData.applicationName}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '22%',
    },
    {
      title: t('organizationLoginsPage.loginName'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={SHARED_WITH_COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationLoginsPage.sharedWith.columnTooltip')}
        >
          {t('organizationLoginsPage.sharedWith.columnTitle')}
        </TypographyWithTooltip>
      ),
      field: 'usersCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.usersCount}
        </TypographyWithTooltip>
      ),
      width: '12%',
    },
    {
      title: t('organizationLoginsPage.securityScore'),
      field: 'strength',
      render: (rowData) => (
        <SecurityScoreIcon percentage={rowData.strength} />
      ),
    },
    {
      title: t('organizationLoginsPage.managedBy'),
      field: 'ownership',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2" color={OWNERSHIP_COLORS[rowData.ownership]}>
          {t(OWNERSHIP_TRANSLATION_KEYS[rowData.ownership])}
        </TypographyWithTooltip>
      ),
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationLoginsPage.lastActivity.columnTooltip')}
        >
          {t('organizationLoginsPage.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
    },
  ]), [createColumns, t]);

  const handleLoginRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationLoginsTableRow>['onRowClick']>
  >((event, login) => {
    navigate(`${PageRouteEnum.AllSecuredData}/logins/${login!.vaultId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={logins}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedLogins}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleLoginRowClick}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationLoginsTable);
