import { derivePrivateKey, deriveVerifier, generateSalt } from 'secure-remote-password/client';
import {
  decryptAsymmetric,
  decryptSymmetric,
  deriveKey,
  encryptSymmetric,
  generateRandomString,
  md5Hash,
} from '@uniqkey-frontend/shared-app';
import {
  type ProcessInviteEmployeePayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handleProcessInviteEmployeeEvent';

const handleProcessInviteEmployeeEvent = async (
  params: ITrustedPortalHandlerParams<ProcessInviteEmployeePayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    employeeAccountId,
    employeeAccountPrivateKey,
    privateKeyBackupSymmetricKey,
  } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!employeeAccountPrivateKey) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountPrivateKey in event`);
  }

  if (!privateKeyBackupSymmetricKey) {
    throw new Error(`${ERROR_PREFIX} no privateKeyBackupSymmetricKey in event`);
  }

  const salt = generateSalt();
  const activationCode = generateRandomString();
  const privateKey = derivePrivateKey(salt, employeeAccountId, activationCode);
  const verifier = deriveVerifier(privateKey);

  const decryptedPrivateKeyBackupSymmetricKey = await decryptAsymmetric({
    cipher: privateKeyBackupSymmetricKey,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const decryptedEmployeeAccountPrivateKey = decryptSymmetric({
    cipher: employeeAccountPrivateKey,
    key: decryptedPrivateKeyBackupSymmetricKey,
  });

  if (!decryptedEmployeeAccountPrivateKey) {
    throw new Error(`${ERROR_PREFIX} decryptedEmployeeAccountPrivateKey is empty`);
  }

  const hashedEmployeeAccountId = md5Hash(employeeAccountId);
  const encryptionKey = await deriveKey(activationCode, hashedEmployeeAccountId);

  const encryptedEmployeeAccountPrivateKey = encryptSymmetric({
    string: decryptedEmployeeAccountPrivateKey,
    key: encryptionKey,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueProcessInviteEmployeeApprovePost({
      queueMessageId,
      activationCode,
      salt,
      verifier,
      employeeAccountPrivateKey: encryptedEmployeeAccountPrivateKey,
    });
};

export default handleProcessInviteEmployeeEvent;
