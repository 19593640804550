import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  ClosedEyeIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  EyeIcon,
  Grid,
  Box,
  IconButton,
  useTranslations,
  SecurityScoreText,
  PasswordGenerator,
  Collapse,
  TextFieldWithButton,
  usePasswordGenerator,
  emptyStringValidator,
  Typography,
  ReactHookFormRadioGroup,
  FormControlLabel,
  Radio,
  S5,
  useSnackbar,
  IAutocompleteDefaultOption,
  ServiceSelector,
  ReactHookFormTextField,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  AuthenticationType,
  SearchByDesktopAppNameResponseModel,
  SearchByUrlResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import HostTypeEnum from '../../enums/HostTypeEnum';
import { logException } from '../../services/sentryService';
import useDomainRulesAPI from '../../hooks/useDomainRulesAPI';
import LinkedServices from '../LinkedServices';
import useDesktopApplicationsAPI from '../../hooks/useDesktopApplicationsAPI';

export interface ICreateLoginReturnValue {
  name: string;
  username: string;
  password: string;
  additionalInfo: string;
  applicationId: string | null;
  hostType: HostTypeEnum;
  website?: IAutocompleteDefaultOption | string | null;
  desktopApp?: IAutocompleteDefaultOption | null;
}

interface ICreateLoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: ICreateLoginReturnValue) => Promise<void>;
  isLoading: boolean;
}

const desktopApplicationParser = (
  desktopApplications: SearchByDesktopAppNameResponseModel[],
) => desktopApplications
  .map((appName: SearchByDesktopAppNameResponseModel) => ({
    id: appName.applicationId,
    value: appName.desktopApplicationName,
    label: appName.authenticationType === AuthenticationType.Basic
      ? `${appName.desktopApplicationName} ${appName.authenticationType}`
      : appName.desktopApplicationName,
  }));

const websiteOptionsParser = (websitesToParse: SearchByUrlResponseModel[]) => websitesToParse
  .map((website) => ({
    id: website.applicationId!,
    label: website.domainRule!,
  }));

const CreateLoginModal = (props: ICreateLoginModalProps) => {
  const {
    isOpen, onClose, onSubmit, isLoading,
  } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordGeneratorOpen, setIsPasswordGeneratorOpen] = useState(false);
  const [areLinkedServicesOpen, setAreLinkedServicesOpen] = useState(false);

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getServicesByUrl } = useDomainRulesAPI();
  const { getServicesByDesktopAppName } = useDesktopApplicationsAPI();
  const { password, passwordGeneratorProps, regenerate } = usePasswordGenerator({
    generateOnInit: false,
  });
  const {
    register, handleSubmit, formState: { errors, dirtyFields }, setValue, watch, control,
  } = useForm<ICreateLoginReturnValue>({
    mode: 'all',
    defaultValues: {
      name: '',
      username: '',
      password: '',
      additionalInfo: '',
      hostType: HostTypeEnum.Website,
      applicationId: null,
      website: null,
      desktopApp: null,
    },
  });

  const togglePasswordVisibility = useCallback(
    () => setIsPasswordVisible(!isPasswordVisible),
    [isPasswordVisible],
  );

  const getSecurityScoreTranslationLabel = useCallback(
    (translationKey: string) => `${t(translationKey)} ${t('common.password')}`,
    [t],
  );
  const handleGenerateButtonClick = useCallback(() => {
    setIsPasswordGeneratorOpen(true);
    regenerate();
  }, [regenerate]);

  const websitesAutocompleteQuery = useCallback(async (url: string) => {
    try {
      const trimmedUrl = url.trim();
      if (!trimmedUrl) return [];
      const { domainRules } = await getServicesByUrl(trimmedUrl);
      return domainRules!;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'CreateLoginModal/websitesAutocompleteRequest exception',
      });
      return [];
    }
  }, [getServicesByUrl, showError, t]);

  const desktopAppsAutocompleteQuery = useCallback(async (desktopAppName?: string) => {
    try {
      const { desktopApplications } = await getServicesByDesktopAppName(desktopAppName);
      return desktopApplications!;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, { message: 'CreateLoginModal/desktopAppsAutocompleteRequest exception' });
      return [];
    }
  }, [getServicesByDesktopAppName, t, showError]);

  const handleHostChange = useCallback(() => {
    setAreLinkedServicesOpen(false);
    setValue('applicationId', null);
    setValue('desktopApp', null);
    setValue('website', null);
  }, [setValue]);

  const handleLinkedServicesToggle = useCallback(() => {
    setAreLinkedServicesOpen((open) => !open);
  }, []);

  const [
    passwordValue,
    hostTypeValue,
    applicationIdValue,
  ] = watch(['password', 'hostType', 'applicationId']);

  useEffect(() => {
    if (!password) {
      return;
    }
    setValue('password', password, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  }, [password, setValue]);

  const passwordInputLabelProps = useMemo(
    () => ({ shrink: passwordValue ? true : undefined }),
    [passwordValue],
  );

  const passwordGeneratorLocalization = useMemo(() => ({
    numberOfCharactersText: t('passwordGenerator.numberOfCharactersText'),
    lettersCheckboxLabel: t('passwordGenerator.lettersCheckboxLabel'),
    numbersCheckboxLabel: t('passwordGenerator.numbersCheckboxLabel'),
    specialCharactersCheckboxLabel: t('passwordGenerator.specialCharactersCheckboxLabel'),
  }), [t]);

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="lg"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createLoginModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <Grid container direction="column" rowSpacing={4}>
                <Grid item xs={12}>
                  <ReactHookFormTextField
                    name="name"
                    control={control}
                    autoFocus
                    fullWidth
                    error={!!errors.name}
                    label={`${t('createLoginModal.name.label')}*`}
                    placeholder={t('createLoginModal.name.placeholder')}
                    helperText={errors.name?.message}
                    rules={{
                      required: t('validation.required'),
                      validate: (value) => (
                        emptyStringValidator(value)
                          ? buildInvalidFieldTranslation('createLoginModal.name.label') : true
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormTextField
                    name="username"
                    control={control}
                    fullWidth
                    error={!!errors.username}
                    label={t('createLoginModal.login.label')}
                    placeholder={t('createLoginModal.login.placeholder')}
                    helperText={errors.username?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWithButton
                    type={isPasswordVisible ? 'text' : 'password'}
                    fullWidth
                    error={!!errors.password}
                    label={t('createLoginModal.password.label')}
                    placeholder={t('createLoginModal.password.placeholder')}
                    helperText={errors.password?.message}
                    InputLabelProps={passwordInputLabelProps}
                    buttonProps={{
                      text: t('passwordGenerator.generateUppercased'),
                      onClick: handleGenerateButtonClick,
                    }}
                    InputProps={{
                      ...register('password'),
                      endAdornment: (
                        <IconButton onClick={togglePasswordVisibility}>
                          {isPasswordVisible ? <ClosedEyeIcon /> : <EyeIcon />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Collapse
                  in={!!passwordValue}
                >
                  <Box mt={3} />
                  {!!passwordValue && (
                    <SecurityScoreText
                      password={passwordValue}
                      getTranslation={getSecurityScoreTranslationLabel}
                    />
                  )}
                </Collapse>
                <Collapse
                  in={isPasswordGeneratorOpen}
                >
                  <Box mt={3} />
                  <PasswordGenerator
                    localization={passwordGeneratorLocalization}
                    {...passwordGeneratorProps}
                  />
                </Collapse>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" rowSpacing={4}>
                <Typography variant="caption" color={S5} pt={3}>{t('common.select')}</Typography>
                <ReactHookFormRadioGroup
                  control={control}
                  onChange={handleHostChange}
                  name="hostType"
                >
                  <Grid container columnSpacing={3}>
                    <Grid item>
                      <FormControlLabel
                        value={HostTypeEnum.Website}
                        control={<Radio />}
                        label={t('createLoginModal.website.label')}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value={HostTypeEnum.DesktopApp}
                        control={<Radio />}
                        label={t('createLoginModal.desktopApp.label')}
                      />
                    </Grid>
                  </Grid>
                </ReactHookFormRadioGroup>
                <Grid item xs={12}>
                  <ServiceSelector
                    t={t}
                    websitesOptionsParser={websiteOptionsParser}
                    desktopAppsOptionsParser={desktopApplicationParser}
                    websitesDatasourceRequest={websitesAutocompleteQuery}
                    desktopAppsDatasourceRequest={desktopAppsAutocompleteQuery}
                    control={control}
                    setValue={setValue}
                    vaultApplicationType={hostTypeValue}
                    desktopAppPlaceholder={t('createLoginModal.desktopApp.placeholder')}
                    desktopAppLabel={t('createLoginModal.desktopApp.label')}
                    websiteLabel={t('createLoginModal.website.label')}
                    websitePlaceholder={t('createLoginModal.website.placeholder')}
                    noSpacesValidationErrorMessage={t('validation.withoutSpaces')}
                  />
                </Grid>
                <LinkedServices
                  applicationId={applicationIdValue}
                  open={areLinkedServicesOpen}
                  onToggle={handleLinkedServicesToggle}
                />
                <Grid item xs={12}>
                  <ReactHookFormTextField
                    name="additionalInfo"
                    control={control}
                    fullWidth
                    multiline
                    error={!!errors.additionalInfo}
                    label={t('createLoginModal.additionalInfo.label')}
                    placeholder={t('createLoginModal.additionalInfo.placeholder')}
                    helperText={errors.additionalInfo?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={4}>
            <Grid item xs={6} />
            <Grid item xs={6} container spacing={3}>
              <Grid item xs={6}>
                <Button
                  isLoading={isLoading}
                  disabled={!dirtyFields.name}
                  fullWidth
                  type="submit"
                >
                  {t('common.save')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  fullWidth
                  onClick={onClose}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreateLoginModal);
