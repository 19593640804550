import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { SearchByDesktopAppNameResponse } from '@uniqkey-backend-organization-web/api-client';
import { dataExtractor } from '../../helpers/apiClients';
import { useApiClients } from '../../contexts/APIClientsContext';

const useDesktopApplicationsAPI = () => {
  const { desktopApplicationsAPIClient } = useApiClients();

  const getServicesByDesktopAppName = useCallback(
    (desktopAppName?: string, options?: AxiosRequestConfig)
      : Promise<SearchByDesktopAppNameResponse> => desktopApplicationsAPIClient
      .apiV1DesktopApplicationsSearchByDesktopAppNameGet(desktopAppName, options)
      .then(dataExtractor<SearchByDesktopAppNameResponse>()),
    [desktopApplicationsAPIClient],
  );

  return ({ getServicesByDesktopAppName });
};

export default useDesktopApplicationsAPI;
