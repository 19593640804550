import {
  Ownership,
  EmployeeAccountStatus,
  EmployeeAccountType,
  ActivityFilter,
} from '@uniqkey-backend-organization-web/api-client';
import {
  AG2, AG3, AR1, S4, S5, AO4,
} from '@uniqkey-frontend/shared-app';

export const MFA_TRANSLATION_KEYS = {
  true: 'mfa.2FA',
  false: 'mfa.notConfigured',
};

export const OWNERSHIP_TRANSLATION_KEYS = {
  [Ownership.Employees]: 'ownership.employees',
  [Ownership.Groups]: 'ownership.groups',
  [Ownership.Unmanaged]: 'ownership.unmanaged',
};

export const ACTIVITY_FILTER_TRANSLATION_KEYS = {
  [ActivityFilter.Active]: 'activityFilter.active',
  [ActivityFilter.NotActive]: 'activityFilter.notActive',
};

export const EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS = {
  [EmployeeAccountStatus.Active]: 'employeeStatuses.active',
  [EmployeeAccountStatus.Invited]: 'employeeStatuses.invited',
  [EmployeeAccountStatus.Archived]: 'employeeStatuses.archived',
  [EmployeeAccountStatus.Unprocessed]: 'employeeStatuses.pendingApproval',
  [EmployeeAccountStatus.Staged]: 'employeeStatuses.staged',
  [EmployeeAccountStatus.Migrated]: 'employeeStatuses.migrated',
  [EmployeeAccountStatus.MigrationInvited]: 'employeeStatuses.migrationInvited',
  [EmployeeAccountStatus.ExistingUnprocessed]: 'employeeStatuses.existingUnprocessed',
};

export const EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS = {
  [EmployeeAccountType.Admin]: 'adminRights.admin',
  [EmployeeAccountType.KeylessAdmin]: 'adminRights.promotedToAdmin',
  [EmployeeAccountType.Employee]: 'adminRights.notAdmin',
  [EmployeeAccountType.Supporter]: 'adminRights.notAdmin',
};

export const SCIM_TRANSLATION_KEYS = {
  true: 'scim.scim',
  false: 'scim.no',
};

export const EMPLOYEE_ACCOUNT_STATUS_COLORS = {
  [EmployeeAccountStatus.Active]: AG2,
  [EmployeeAccountStatus.Invited]: S5,
  [EmployeeAccountStatus.Archived]: AR1,
  [EmployeeAccountStatus.Unprocessed]: S4,
  [EmployeeAccountStatus.Staged]: S5,
  [EmployeeAccountStatus.MigrationInvited]: S5,
  [EmployeeAccountStatus.Migrated]: S5,
  [EmployeeAccountStatus.ExistingUnprocessed]: S4,
};

export const OWNERSHIP_COLORS = {
  [Ownership.Employees]: AG3,
  [Ownership.Groups]: AG3,
  [Ownership.Unmanaged]: S4,
};

export const SCIM_COLORS = {
  true: AO4,
  false: S5,
};
